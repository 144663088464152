import dayjs from "dayjs";
import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
const clearanceApi = factory.get("clearance");
const initialState = {
  searchBody: {},
  loading: false,
  clearanceResultLoading: false,
  clearance: {
    dialog: false,
    form: {
      user_id: null,
      start_date: dayjs().format("YYYY-MM-DD"),
      end_date: dayjs().format("YYYY-MM-DD"),
      date: dayjs().format("YYYY-MM-DD"),
      total: 0,
      amount: 0,
      percentage: 0,
      note: "",
      payments_ids: [],
      expenses_ids: [],
    },
  },
  getById: {
    dialog: false,
    loading: false,
    data: [],
  },
  clearances: {
    data: [],
    total: 0,
  },
  reportData: [],
};

export const clearanceSlice = createSlice({
  name: "clearance",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.clearance.dialog = !state.clearance.dialog;
    },
    setGetByIdDialog: (state, action) => {
      state.getById.dialog = !state.getById.dialog;
    },
    setDataTable: (state, { payload }) => {
      state.clearances.data = payload.data.data;
      state.clearances.total = payload.data.total;
      state.clearances.total_amount = payload.data.total_amount;
    },
    setReportDataTable: (state, { payload }) => {
      state.reportData = payload;
    },
    setById: (state, { payload }) => {
      state.getById.data = payload;
    },
    setLoading: (state, action) => {
      state.loading = !state.loading;
    },
    setGetByIdLoading: (state, action) => {
      state.getById.loading = !state.getById.loading;
    },
    setClearanceResultLoading: (state, action) => {
      state.clearanceResultLoading = !state.clearanceResultLoading;
    },
    resetForm: (state, action) => {
      state.clearance.form = initialState.clearance.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
  },
});

export const {
  setLoading,
  setGetByIdLoading,
  setClearanceResultLoading,
  setDialog,
  setGetByIdDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setReportDataTable,
} = clearanceSlice.actions;

export default clearanceSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("clearance"));
    const res = await clearanceApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("clearance"));
  } catch (err) {
    dispatch(setLoading("clearance"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getReportData = (params) => async (dispatch) => {
  try {
    dispatch(setClearanceResultLoading("clearance"));
    const res = await clearanceApi.getReportData(params);
    dispatch(setReportDataTable(res.data.data));
    dispatch(setClearanceResultLoading("clearance"));
  } catch (err) {
    dispatch(setClearanceResultLoading("clearance"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await clearanceApi.delete(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setGetByIdLoading("clearance"));
    dispatch(setLoading("clearance"));
    const res = await clearanceApi.getById(id);
    dispatch(setById(res.data.data[0]));
    dispatch(setGetByIdDialog());
    dispatch(setGetByIdLoading("clearance"));
    dispatch(setLoading("clearance"));
  } catch (err) {
    dispatch(setGetByIdLoading("clearance"));
    dispatch(setLoading("clearance"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data) => async (dispatch) => {
  try {
    await clearanceApi.create(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة  بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(setReportDataTable([]));
    dispatch(getAll());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await clearanceApi.update(id, data);
    dispatch(
      showNotification({
        message: "تم التعديل  بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const Clearance = {
  getAll,
  getReportData,
  deleteById,
  getById,
  create,
  update,
};
