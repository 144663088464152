import React from "react";
import { FilePond } from "./filepond";

const FilePondInput = ({ field, required = false, allowMultiple = false }) => {
  const { name, onChange, onBlur } = field;

  return (
    <FilePond
      required={required}
      name={name}
      allowMultiple={allowMultiple}
      file={field.value}
      // files={field.value}
      credits={false}
      labelIdle='قم بالسحب والإفلات للملفات  او <span class="filepond--label-action">اختيار ملف</span>'
      onupdatefiles={(fileItems) => {
        const files = fileItems.map((fileItem) => fileItem.file);
        onChange(files);
      }}
      onBlur={onBlur}
    />
  );
};

export default FilePondInput;
