import { forwardRef, useEffect } from "react";
import { Button, Dialog, DialogContent, Grid, Typography } from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { SelectPatient } from "../../../components/Selectors/SelectPatient";
import {
  setDialog,
  waitingList,
  resetForm,
} from "../../../app/slices/waitingListSlice";
import dayjs from "dayjs";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const WaitingListForm = (props) => {
  const _WaitingList = useSelector((state) => state.waitingList.waitingList);
  const _Patient = useSelector((state) => state.patient.patients.data);
  const _SearchBody = useSelector((state) => state.waitingList.searchBody);

  const isAdd = _WaitingList.form.id ? false : true;
  const { control, reset, handleSubmit, setValue } = useForm({
    defaultValues: _WaitingList.form,
  });

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(
        waitingList.create(
          {
            current_status: "waiting_treatment",
            patient_id: props.patientId ? props.patientId : data.patient_id,
            date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
          },
          _SearchBody,
          false,
          false
        )
      );
    } else {
      dispatch(
        waitingList.update(
          data.id,
          {
            id: data.id,
            current_status: data.current_status,
          },
          _SearchBody,
          true
        )
      );
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    reset({ ..._WaitingList.form });
  }, [reset, _WaitingList.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, [dispatch]);

  const handleDialog = () => {
    dispatch(setDialog());
  };

  return (
    <Dialog
      open={_WaitingList.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : "تعديل "}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            {/* <Grid item xs={12} md={12}>
              <Controller
                name="current_status"
                control={control}
                render={({ field }) => (
                  <SelectWaitingListTypes
                    {...field}
                    onChange={(e, newValue) => {
                      setValue(
                        "current_status",
                        newValue ? newValue.key : null
                      );
                    }}
                    value={
                      _WaitingListTypes?.filter(
                        (x) => x.key === field.value
                      )[0] ?? null
                    }
                  />
                )}
              />
            </Grid> */}
            {props.patientId ? null : (
              <Grid item xs={12} md={12}>
                <Controller
                  name="patient_id"
                  control={control}
                  render={({ field }) => (
                    <SelectPatient
                      {...field}
                      onChange={(e, newValue) => {
                        setValue("patient_id", newValue ? newValue.id : null);
                      }}
                      value={
                        _Patient?.filter((x) => x.id === field.value)[0] ?? null
                      }
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default WaitingListForm;
