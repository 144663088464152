import { createRoot } from "react-dom/client";

// third party
import { BrowserRouter } from "react-router-dom";
// import { Provider } from 'react-redux';
import { Provider } from "react-redux";

// project imports
// import * as serviceWorker from 'serviceWorker';
import App from "./App";
import { store } from '../src/app/store';

// style + assets
// import 'assets/scss/style.scss';
// import config from './config';

// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);
