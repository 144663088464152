import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
const offerApi = factory.get("offers");
const initialState = {
  loading: false,
  searchBody: {},
  offer: {
    dialog: false,
    form: {
      id: null,
      attachment: "",
      name: "",
    },
  },
  offers: {
    data: [],
    total: 0,
  },
};

export const offerSlice = createSlice({
  name: "offer",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.offer.dialog = !state.offer.dialog;
    },
    setDataTable: (state, { payload }) => {
      state.offers.data = payload.data.data;
      state.offers.total = payload.data.total;
    },
    setById: (state, { payload }) => {
      state.offer.form = payload;
    },
    setLoading: (state, action) => {
      state.loading = !state.loading;
    },
    resetForm: (state, action) => {
      state.offer.form = initialState.offer.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
  },
});

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
} = offerSlice.actions;

export default offerSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("offer"));
    const res = await offerApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("offer"));
  } catch (err) {
    dispatch(setLoading("offer"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await offerApi.delete(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("offer"));
    const res = await offerApi.getById(id);
    dispatch(setById(res.data.data[0]));

    dispatch(setDialog());
    dispatch(setLoading("offer"));
  } catch (err) {
    dispatch(setLoading("offer"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data, _SearchBody) => async (dispatch) => {
  try {
    await offerApi.create(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة  بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const toggleStatus = (id, _SearchBody) => async (dispatch) => {
  try {
    await offerApi.toggleStatus(id);
    dispatch(
      showNotification({
        message: "تمت العملية بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const OffersActions = {
  getAll,
  deleteById,
  getById,
  create,
  toggleStatus,
};
