import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
const reportsApi = factory.get("reports");
const initialState = {
  searchBody: {},
  loading: false,
  payments: {
    data: [],
    total: 0,
  },
  spending: {
    data: [],
    total: 0,
  },
  operation: {
    data: [],
    total: 0,
  },
  xray: {
    data: [],
    total: 0,
  },
  deleted: {
    operation: {
      data: [],
      total: 0,
    },
    patient: {
      data: [],
      total: 0,
    },
    payment: {
      data: [],
      total: 0,
    },
  },
  reportTypes: [
    { type: "الوارد اليومي", name: "payments", isSelected: false },
    { type: "الخدمات", name: "operation", isSelected: false },
    { type: "المصاريف", name: "spending", isSelected: false },
    { type: "الاشعة", name: "xray", isSelected: false },
    {
      type: "العمليات والمدفوعات المحذوفة",
      name: "deleted",
      isSelected: false,
    },
  ],
};

export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setPaymentsTable: (state, { payload }) => {
      state.payments.data = payload.data.data;
      state.payments.total = payload.data.total;
    },
    setSpendingsTable: (state, { payload }) => {
      state.spending.data = payload.data.data;
      state.spending.total = payload.data.total;
    },
    setOperationsTable: (state, { payload }) => {
      state.operation.data = payload.data.data;
      state.operation.total = payload.data.total;
    },
    setXrayTable: (state, { payload }) => {
      state.xray.data = payload.data.data;
      state.xray.total = payload.data.total;
    },
    setDeleteTable: (state, { payload }) => {
      state.deleted.operation.data = payload.data.data.operation;
      state.deleted.operation.total = payload.data.data.operation_count;
      state.deleted.patient.data = payload.data.data.patient;
      state.deleted.patient.total = payload.data.data.patient_count;
      state.deleted.payment.data = payload.data.data.payment;
      state.deleted.payment.total = payload.data.data.patient_count;
    },
    setDeletedPaymentTable: (state, { payload }) => {
      state.deletedPayment.data = payload.data.data;
      state.deletedPayment.total = payload.data.total;
    },
    setDeletedPatientTable: (state, { payload }) => {
      state.deletedPatient.data = payload.data.data;
      state.deletedPatient.total = payload.data.total;
    },
    setLoading: (state, action) => {
      state.loading = !state.loading;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
    setSelectedReport: (state, { payload }) => {
      state.reportTypes = state.reportTypes.map((type) =>
        type.name === payload
          ? { ...type, isSelected: true }
          : { ...type, isSelected: false }
      );
    },
  },
});

export const {
  setLoading,
  setPaymentsTable,
  setSpendingsTable,
  setOperationsTable,
  setSearchBody,
  setXrayTable,
  setDeleteTable,
  setDeletedPaymentTable,
  setDeletedPatientTable,
  setSelectedReport,
} = reportsSlice.actions;

export default reportsSlice.reducer;

//axios
const payments = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("reports"));
    const res = await reportsApi.payments(params);
    dispatch(setPaymentsTable(res));
    dispatch(setLoading("reports"));
  } catch (err) {
    dispatch(setLoading("reports"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const spending = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("reports"));
    const res = await reportsApi.spending(params);
    dispatch(setSpendingsTable(res));
    dispatch(setLoading("reports"));
  } catch (err) {
    dispatch(setLoading("reports"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const operation = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("reports"));
    const res = await reportsApi.operation(params);
    dispatch(setOperationsTable(res));
    dispatch(setLoading("reports"));
  } catch (err) {
    dispatch(setLoading("reports"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const xray = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("reports"));
    const res = await reportsApi.xray(params);
    dispatch(setXrayTable(res));
    dispatch(setLoading("reports"));
  } catch (err) {
    dispatch(setLoading("reports"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleted = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("reports"));
    const res = await reportsApi.deleted(params);
    dispatch(setDeleteTable(res));
    dispatch(setLoading("reports"));
  } catch (err) {
    dispatch(setLoading("reports"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const Reports = {
  payments,
  spending,
  operation,
  xray,
  deleted,
};
