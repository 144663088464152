import { Card, CardHeader, Typography, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { setDialog } from "../../../../../app/slices/clearance";
import ClearanceForm from "../../form";
import { FaCashRegister } from "react-icons/fa";

const ClearanceHeader = () => {
  const clearanceDialog = useSelector(
    (state) => state.clearance.clearance.dialog
  );
  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            حسابات الاطباء
          </Typography>
        }
        avatar={<FaCashRegister size={20} />}
        action={
          <Button
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<IoIosAddCircleOutline />}
            onClick={() => dispatch(setDialog())}
          >
            إضافة حساب للطبيب
          </Button>
        }
      />
      {clearanceDialog && <ClearanceForm />}
    </Card>
  );
};

export default ClearanceHeader;
