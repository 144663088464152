import {
  Grid,
  IconButton,
  LinearProgress,
  Card,
  CardContent,
  Tooltip,
  Typography,
  useMediaQuery,
  Dialog,
  DialogContent,
  Divider,
  Slide,
  Chip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiTrash } from "react-icons/bi";
import { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationDialog from "../../../../helpers/ConfirmationDialog";
import {
  OffersActions,
  setSearchBody,
} from "../../../../app/slices/offersSlice";
import { useTheme } from "@emotion/react";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OfferTable = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("lg"));

  const _Offer = useSelector((state) => state.offer.offers);
  const _SearchBody = useSelector((state) => state.offer.searchBody);
  const loading = useSelector((state) => state.offer.loading);

  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");
  const [openActive, setOpenActive] = useState(false);
  const [dialogActiveData, setDialogActiveData] = useState({});

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(OffersActions.deleteById(dialogData.id, _SearchBody));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [imageDialogData, setImageDialogData] = useState("");

  const handleCellClick = (params) => {
    if (params.field === "attachment") {
      if (params.row.attachment) {
        setImageDialogData(params.row.attachment?.path);
        setOpenImageDialog(true);
      }
    }
  };

  const handleActiveClick = (params) => {
    setOpenActive(true);
    setDialogActiveData(params);
  };

  const handleConfirmActive = () => {
    dispatch(OffersActions.toggleStatus(dialogActiveData.id)).then((x) => {
      // setReload(!reload);
    });
    setOpenActive(false);
  };
  const handleCancelActive = () => {
    setOpenActive(false);
  };

  var columns = [
    {
      field: "name",
      headerName: "الاسم",
      flex: 1,
    },
    {
      field: "attachment",
      headerName: "الصورة",
      flex: 1,
      renderCell: (params) => (
        <Box
          component="img"
          src={params.row.attachment?.path}
          sx={{ width: 150, objectFit: "contain" }}
        />
      ),
    },
    {
      field: "is_active",
      headerName: "حالة العرض",
      flex: 1,
      renderCell: (params) => {
        return (
          <Chip
            label={params.row.is_active ? "فعال" : "غير فعال"}
            color={params.row.is_active ? "success" : "error"}
            onClick={() => {
              handleActiveClick(params.row);
            }}
          ></Chip>
        );
      },
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: matchUpMd ? 1 : 0.7,
      renderCell: (params) => {
        return (
          <Tooltip title="حذف">
            <IconButton
              color="error"
              onClick={() => handleDeleteClick(params.row)}
            >
              <BiTrash />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(OffersActions.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [dispatch, _SearchBody]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        skip: pageSize * currentPage,
        take: pageSize,
      })
    );
  }, [dispatch, pageSize, currentPage]);

  return (
    <Card>
      <Dialog
        open={openImageDialog}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        dir="rtl"
        onClose={() => {
          setOpenImageDialog(false);
        }}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
      >
        <DialogContent>
          <Typography
            mb={3}
            variant="h4"
            color={"purple"}
            gutterBottom
            component="div"
          >
            الصورة
          </Typography>
          <Divider />
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box
              component="img"
              src={imageDialogData}
              sx={{ width: "100%", padding: 2 }}
            />
          </Box>
        </DialogContent>
      </Dialog>
      <CardContent>
        <Box>
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من الحذف: "
            name={dialogData.name}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
          <ConfirmationDialog
            open={openActive}
            title="تاكيد"
            message={
              dialogActiveData.wallet?.is_active
                ? "هل انت متاكد من الغاء تفعيل هذا العرض"
                : "هل انت متاكد من تفعيل هذا العرض"
            }
            name={dialogActiveData.name}
            confirmText="تاكيد"
            cancelText="الغاء"
            onConfirm={() => handleConfirmActive()}
            onCancel={handleCancelActive}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              <DataGrid
                sx={{
                  ".MuiDataGrid-cell:focus": {
                    outline: "none",
                  },
                  "& .MuiDataGrid-cell[data-field='attachment']:hover": {
                    cursor: "pointer",
                  },
                }}
                rows={_Offer.data}
                columns={columns}
                pageSize={pageSize}
                page={currentPage}
                rowCount={_Offer.total}
                loading={loading}
                rowHeight={200}
                pagination
                paginationMode="server"
                selectionModel={[]}
                onPageChange={(newPage) => setCurrentPage(newPage)}
                rowsPerPageOptions={[15, 25, 50, 100]}
                onPageSizeChange={(newPageSize) => {
                  setPageSize(newPageSize);
                }}
                components={{
                  LoadingOverlay: LinearProgress,
                }}
                onCellClick={(params) => {
                  handleCellClick(params);
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default OfferTable;
