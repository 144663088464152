import { Skeleton } from "@mui/material";
import { useSelector } from "react-redux";

const Logo = ({ vertical = false, style = {} }) => {
  const userInfo = useSelector((state) => state.user.userInfo);

  return userInfo.tenant?.logo_sm ? (
    <img alt="Logo" src={userInfo.tenant?.logo_sm} style={style} />
  ) : (
    <Skeleton variant="circular" animation="wave" height={100} width={100} />
  );
};

export default Logo;
