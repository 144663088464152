import { Grid } from "@mui/material";
import React from "react";
import ExpenseHeader from "./home/header";
import ExpenseTabel from "./home/table";

const Expense = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ExpenseHeader />
      </Grid>
      <Grid item xs={12}>
        <ExpenseTabel />
      </Grid>
    </Grid>
  );
};

export default Expense;
