import { Grid } from "@mui/material";
import React from "react";
import OperationTable from "./home/table";

const Operation = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <OperationTable />
      </Grid>
    </Grid>
  );
};

export default Operation;
