import { Grid } from "@mui/material";
import React from "react";
import DoctorsExpensesTable from "./home/table";
import DoctorsExpensesHeader from "./home/header";

const DoctorsExpenses = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DoctorsExpensesHeader />
      </Grid>
      <Grid item xs={12}>
        <DoctorsExpensesTable />
      </Grid>
    </Grid>
  );
};

export default DoctorsExpenses;
