import { forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  setDialog,
  resetForm,
  RestTimeActions,
} from "../../../app/slices/restTimeSlice";
import { Controller, useForm } from "react-hook-form";
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { SelectDoctor } from "../../../components/Selectors/SelectDoctor";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RestTimeForm = () => {
  const dispatch = useDispatch();

  const _RestTime = useSelector((state) => state.restTime.restTime);
  const _SearchBody = useSelector((state) => state.restTime.searchBody);
  const _User = useSelector((state) => state.user.doctorList);

  const isAdd = _RestTime.form.id ? false : true;
  const { control, reset, handleSubmit, setValue } = useForm({
    defaultValues: _RestTime.form,
  });

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(RestTimeActions.create(data));
    } else {
      dispatch(RestTimeActions.update(data.id, data, _SearchBody));
    }
  };
  useEffect(() => {
    reset({ ..._RestTime.form });
  }, [reset, _RestTime.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, [dispatch]);

  const handleDialog = () => {
    dispatch(setDialog());
  };

  return (
    <Dialog
      open={_RestTime.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : "تعديل "}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="user_id"
                control={control}
                render={({ field }) => (
                  <SelectDoctor
                    {...field}
                    required
                    onChange={(e, newValue) => {
                      setValue("user_id", newValue ? newValue.id : null);
                    }}
                    value={
                      _User?.filter((x) => x.id === field.value)[0] ?? null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="start_date"
                control={control}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDateTimePicker
                      inputFormat="YYYY-MM-DD HH:mm"
                      label="من تاريخ"
                      value={field.value}
                      onChange={(e, newValue) => {
                        setValue(
                          "start_date",
                          dayjs(e).format("YYYY-MM-DD HH:mm")
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          fullWidth
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "يوم/شهر/سنة",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="end_date"
                control={control}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDateTimePicker
                      inputFormat="YYYY-MM-DD HH:mm"
                      label="الى تاريخ"
                      value={field.value}
                      onChange={(e, newValue) => {
                        setValue(
                          "end_date",
                          dayjs(e).format("YYYY-MM-DD HH:mm")
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          fullWidth
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "يوم/شهر/سنة",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <TextField
                    multiline
                    rows={3}
                    size="small"
                    label="الملاحظة"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default RestTimeForm;
