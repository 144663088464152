import { Card, CardHeader, Typography, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { setOperationDialog } from "../../../../app/slices/operationTypeSlice";
import { MdAirlineSeatFlatAngled } from "react-icons/md";
import OperationTypeForm from "../../form";

const OperationTypeHeader = () => {
  const _OperationType = useSelector(
    (state) => state.operationType.operationType.dialog
  );
  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            انواع الخدمات
          </Typography>
        }
        avatar={<MdAirlineSeatFlatAngled size={20} />}
        action={
          <Button
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<IoIosAddCircleOutline />}
            onClick={() => dispatch(setOperationDialog())}
          >
            إضافة
          </Button>
        }
      />
      {_OperationType && <OperationTypeForm />}
    </Card>
  );
};

export default OperationTypeHeader;
