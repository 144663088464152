import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
import dayjs from "dayjs";
const xrayApi = factory.get("xray");
const initialState = {
  loading: false,
  searchBody: {},
  xray: {
    dialog: false,
    form: {
      id: null,
      patient_id: null,
      user_id: null,
      xray_type_id: null,
      date: dayjs().format("YYYY-MM-DD"),
      price: 0,
      attachment: "",
      note: "",
    },
  },
  xrayData: {
    data: [],
    total: 0,
  },
  xrayTypeData: [],
};

export const xraySlice = createSlice({
  name: "xray",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.xray.dialog = !state.xray.dialog;
    },
    setDataTable: (state, { payload }) => {
      state.xrayData.data = payload.data.data;
      state.xrayData.total = payload.data.total;
    },
    setXrayTypeDataTable: (state, { payload }) => {
      state.xrayTypeData = payload.data.data;
    },
    setById: (state, { payload }) => {
      state.xray.form = payload
    },
    setLoading: (state, action) => {
      state.loading = !state.loading;
    },
    resetForm: (state, action) => {
      state.xray.form = initialState.xray.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
  },
});

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setXrayTypeDataTable,
} = xraySlice.actions;

export default xraySlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("xray"));
    const res = await xrayApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("xray"));
  } catch (err) {
    dispatch(setLoading("xray"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getXrayType = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("xray"));
    const res = await xrayApi.getXrayType(params);
    dispatch(setXrayTypeDataTable(res));
    dispatch(setLoading("xray"));
  } catch (err) {
    dispatch(setLoading("xray"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await xrayApi.delete(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("xray"));
    const res = await xrayApi.getById(id);
    dispatch(setById(res.data.data[0]));

    dispatch(setDialog());
    dispatch(setLoading("xray"));
  } catch (err) {
    dispatch(setLoading("xray"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data, _SearchBody) => async (dispatch) => {
  try {
    await xrayApi.create(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة  بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await xrayApi.update(id, data);
    dispatch(
      showNotification({
        message: "تم التعديل  بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const Xray = {
  getAll,
  deleteById,
  getById,
  create,
  update,
  getXrayType,
};
