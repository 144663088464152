import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack, Typography } from '@mui/material';


function ConfirmationDialog(props) {
  const { open, title, message, confirmText, cancelText, onConfirm, onCancel, name, type } = props;

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      maxWidth='xs'
      fullWidth
      sx={{
        direction: 'ltr'
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <Divider />
      <DialogContent>
        <Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
          <Typography>
            {message}
          </Typography>
          <Typography color='error'>
            {name}
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="secondary" variant='contained'>{cancelText}</Button>
        <Button onClick={onConfirm} color={type ? "primary" : "error"} autoFocus variant='contained'>{confirmText}</Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  // name: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ConfirmationDialog;
