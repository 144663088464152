import {
  Grid,
  IconButton,
  LinearProgress,
  Card,
  CardContent,
  Chip,
  useMediaQuery,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiTrash, BiEdit } from "react-icons/bi";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OperationType } from "../../../../app/slices/operationTypeSlice";
import ConfirmationDialog from "../../../../helpers/ConfirmationDialog";
import { useTheme } from "@emotion/react";
import { HiMenu } from "react-icons/hi";

const OperationTypeTable = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("lg"));

  const _OperationType = useSelector(
    (state) => state.operationType.operationTypes
  );
  const _SearchBody = useSelector((state) => state.operationType.searchBody);
  const loading = useSelector((state) => state.operationType.loading);
  const _Role = useSelector((state) => state.user.userInfo.role);

  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");
  const [activeItem, setActiveItem] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);
  const handleMenuClick = (event, item) => {
    setOpenMenu(event.currentTarget);
    setActiveItem(item);
  };

  const handleClose = () => {
    setActiveItem(null);
    setOpenMenu(null);
  };

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(OperationType.deleteById(dialogData.id, _SearchBody));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  var columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.3,
    },
    {
      field: "name",
      headerName: "الاسم",
      flex: 1,
    },
    {
      field: "price",
      headerName: "السعر IQD",
      flex: 1,
      renderCell: (params) => <>{params.row.price?.toLocaleString()}</>,
    },
    {
      field: "discount",
      headerName: "الخصم IQD",
      flex: 1,
      renderCell: (params) => <>{params.row.discount?.toLocaleString()}</>,
    },
    {
      field: "deduction",
      headerName: "تكاليف الخدمة IQD",
      flex: 1,
      renderCell: (params) => <>{params.row.deduction?.toLocaleString()}</>,
    },
    {
      field: "is_treatment_plan",
      headerName: "خطة علاج !",
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.row.is_treatment_plan ? "نعم" : "كلا"}
          color={params.row.is_treatment_plan ? "info" : "default"}
        />
      ),
    },
    {
      field: "is_online_booking",
      headerName: "حجز الكتروني؟",
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.row.is_online_booking ? "نعم" : "كلا"}
          color={params.row.is_online_booking ? "info" : "default"}
        />
      ),
    },
    {
      field: "is_all_teeth",
      headerName: "كل الاسنان !",
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.row.is_all_teeth ? "نعم" : "كلا"}
          color={params.row.is_all_teeth ? "info" : "default"}
        />
      ),
    },
    {
      field: "note",
      headerName: "الملاحظة",
      flex: 1,
    },
  ];

  if (_Role !== 'secretary') {
    columns.push({
      field: "operations",
      headerName: "العمليات",
      flex: 1,
      renderCell: (params) => {
        return matchUpMd ? (
          <>
            <IconButton
              color="primary"
              onClick={() => dispatch(OperationType.getById(params.row.id))}
            >
              <BiEdit />
            </IconButton>

            <IconButton
              color="error"
              onClick={() => handleDeleteClick(params.row)}
            >
              <BiTrash />
            </IconButton>
          </>
        ) : (
          <IconButton
            onClick={(e) => handleMenuClick(e, params)}
            aria-controls={openMenu ? params.row.id : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? "true" : undefined}
          >
            <HiMenu />
          </IconButton>
        );
      },
    },);
  }



  const operationMenu = (params) => (
    <Menu
      sx={{ direction: "ltr" }}
      anchorEl={openMenu}
      keepMountede
      id="account-menu"
      open={activeItem === params}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            left: "50%",
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem
        onClick={() => {
          dispatch(OperationType.getById(params.row.id));
        }}
      >
        <ListItemIcon>
          <BiEdit size={20} color="#0288D1" />
        </ListItemIcon>
        تعديل
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleDeleteClick(params.row);
        }}
      >
        <ListItemIcon>
          <BiTrash size={20} color="#D32F2F" />
        </ListItemIcon>
        حذف
      </MenuItem>
    </Menu>
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(OperationType.getAll());
    }, 500);
    return () => clearTimeout(timeout);
  }, [dispatch, _SearchBody]);

  return (
    <Card>
      <CardContent>
        <Box>
          {activeItem && operationMenu(activeItem)}
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف"
            name={dialogData.name}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12}>
            <Box sx={{ height: "75vh", width: "100%" }}>
              <DataGrid
                rows={_OperationType.data?.map((operationType, index) => {
                  return { ...operationType, index: index + 1 };
                })}
                columns={columns}
                rowCount={_OperationType.total}
                loading={loading}
                rowHeight={50}
                selectionModel={[]}
                components={{
                  LoadingOverlay: LinearProgress,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default OperationTypeTable;
