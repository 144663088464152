import React, { forwardRef, useRef } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import { useReactToPrint } from "react-to-print";
import { Box } from "@mui/system";
import { useEffect } from "react";
import Logo from "../../../assets/Logo";
import { useDispatch, useSelector } from "react-redux";
import { setMultiOpertaionReceiptDialog } from "../../../app/slices/operationSlice";
import dayjs from "dayjs";
import { DataGrid, GridFooterContainer } from "@mui/x-data-grid";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function GenerateMultiOperationReceipt({ data = {}, print }) {
  const _Operation = useSelector(
    (state) => state.operation.multiOpertaionReceipt
  );
  const userInfo = useSelector((state) => state.user.userInfo);
  const _Patient = useSelector((state) => state.patient.patient.form);

  const printRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    copyStyles: true,
    pageStyle: `
    body {
      direction: rtl; 
      }`,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    // handlePrint();
  }, []);

  const handleDialog = () => {
    dispatch(setMultiOpertaionReceiptDialog());
  };

  const columns = [
    {
      field: "operation_type_name",
      headerName: "نوع الجلسة",
      flex: 1.5,
    },
    {
      field: "amount",
      headerName: "المبلغ الكلي",
      flex: 1,
      renderCell: (params) => (
        <Typography fontSize={10}>
          {parseInt(params.row.amount)?.toLocaleString()} IQD
        </Typography>
      ),
    },
    {
      field: "paid",
      headerName: "المبلغ المدفوع",
      flex: 1,
      renderCell: (params) => (
        <Typography fontSize={10}>
          {parseInt(params.row.paid)?.toLocaleString()} IQD
        </Typography>
      ),
    },
    {
      field: "debt",
      headerName: "الديون",
      flex: 1,
      renderCell: (params) => (
        <Typography fontSize={10}>
          {parseInt(params.row.debt)?.toLocaleString()} IQD
        </Typography>
      ),
    },
    {
      field: "discount",
      headerName: "الخصم",
      flex: 0.5,
      renderCell: (params) => (
        <Typography fontSize={10}>
          {parseInt(params.row.discount)?.toLocaleString()} IQD
        </Typography>
      ),
    },
    {
      field: "date",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) => (
        <Typography fontSize={10}>
          {dayjs(params.row.date).format("YYYY-MM-DD")}
        </Typography>
      ),
    },
  ];

  const customToolbar = () => (
    <GridFooterContainer>
      <Stack direction={"row"} spacing={2} padding={2}>
        <Typography fontSize={11}>
          المجموع الكلي:
          {_Operation.data
            .reduce((total, currentObject) => {
              return total + currentObject.amount;
            }, 0)
            ?.toLocaleString()}
          IQD
        </Typography>
        <Typography fontSize={11}>
          مجموع المدفوعات:
          {_Operation.data
            .reduce((total, currentObject) => {
              return total + currentObject.paid;
            }, 0)
            ?.toLocaleString()}
          IQD
        </Typography>
        <Typography fontSize={11}>
          مجموع الديون:
          {_Operation.data
            .reduce((total, currentObject) => {
              return total + currentObject.debt;
            }, 0)
            ?.toLocaleString()}
          IQD
        </Typography>
      </Stack>
    </GridFooterContainer>
  );

  return (
    <Dialog
      open={_Operation.dialog}
      TransitionComponent={Transition}
      keepMounted
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {"وصل استلام"}
        </Typography>
        <div>
          <Button
            onClick={() => {
              handlePrint();
            }}
            color="primary"
            variant="contained"
            fullWidth
          >
            طباعة
          </Button>
          <div ref={printRef} style={{ padding: "15px" }}>
            <Box
              sx={{
                position: "relative",
                height: "180mm",
                width: "130mm ",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={6} display={"flex"} alignItems={"center"}>
                  <Typography variant="h4">وصل استلام</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Logo vertical style={{ width: "150px", float: "left" }} />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={7.5}>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <Typography fontSize={11}>اسم المراجع:</Typography>
                    <Typography fontSize={12} sx={{ fontWeight: "bold" }}>
                      {_Patient?.full_name}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={4.5}>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <Typography fontSize={11}>العمر:</Typography>
                    <Typography fontSize={12} sx={{ fontWeight: "bold" }}>
                      {_Patient?.age} سنة
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={7.5}>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <Typography fontSize={11}>رقم الهاتف:</Typography>
                    <Stack direction="row" spacing={1}>
                      <Typography fontSize={12} sx={{ fontWeight: "bold" }}>
                        {`${_Patient?.mobile_1}`}
                      </Typography>
                      {_Patient?.mobile_2 && (
                        <Typography fontSize={12} sx={{ fontWeight: "bold" }}>
                          - {_Patient?.mobile_2 ? _Patient?.mobile_2 : ""}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={4.5}>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <Typography fontSize={11}>الجنس:</Typography>
                    <Typography fontSize={12} sx={{ fontWeight: "bold" }}>
                      {_Patient?.gender}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <DataGrid
                  sx={{
                    fontSize: 10,
                  }}
                  rows={_Operation?.data}
                  columns={columns}
                  rowCount={_Operation.total}
                  rowHeight={30}
                  pagination
                  paginationMode="client"
                  disableSelectionOnClick
                  autoHeight
                  components={{
                    Footer: customToolbar,
                  }}
                />
                <Grid item xs={12} sx={{ marginBottom: 3 }}>
                  <Divider />
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 6 }}></Grid>
                <Grid item xs={12} display={"flex"} justifyContent={"end"}>
                  <Stack spacing={2}>
                    <Stack direction={"row"} spacing={1} alignItems={"start"}>
                      <Typography fontSize={11}>العنوان:</Typography>
                      <Typography fontSize={12} sx={{ fontWeight: "bold" }}>
                        {userInfo.tenant.address}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <Typography fontSize={11}>هاتف:</Typography>
                      <Typography fontSize={12} sx={{ fontWeight: "bold" }}>
                        {userInfo.tenant.phone}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
