import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Typography,
  Stack,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiTrash, BiEdit } from "react-icons/bi";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  RestTimeActions,
  setSearchBody,
} from "../../../../app/slices/restTimeSlice";
import ConfirmationDialog from "../../../../helpers/ConfirmationDialog";
import dayjs from "dayjs";

const RestTimeTable = () => {
  const dispatch = useDispatch();
  const _RestTime = useSelector((state) => state.restTime.restTimes);
  const _SearchBody = useSelector((state) => state.restTime.searchBody);
  const loading = useSelector((state) => state.restTime.loading);

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(RestTimeActions.deleteById(dialogData.id, _SearchBody));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const columns = [
    {
      field: "dentist_name",
      headerName: "اسم الطبيب",
      flex: 1,
    },
    {
      field: "created_by",
      headerName: "إنشاء بواسطة",
      flex: 1,
    },
    {
      field: "start_date",
      headerName: "من",
      flex: 1,
      renderCell: (params) => (
        <Stack>
          <Typography variant="caption">
            {dayjs(params.row.start_date).format("YYYY-MM-DD")}
          </Typography>
          <Typography variant="body" fontWeight="bold">
            {dayjs(params.row.start_date).format("hh:mm a")}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "end_date",
      headerName: "الى",
      flex: 1,
      renderCell: (params) => (
        <Stack>
          <Typography variant="caption">
            {dayjs(params.row.end_date).format("YYYY-MM-DD")}
          </Typography>
          <Typography variant="body" fontWeight="bold">
            {dayjs(params.row.end_date).format("hh:mm a")}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "note",
      headerName: "ملاحظات",
      flex: 1,
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              color="primary"
              onClick={() => dispatch(RestTimeActions.getById(params.row.id))}
            >
              <BiEdit />
            </IconButton>

            <IconButton
              color="error"
              onClick={() => handleDeleteClick(params.row)}
            >
              <BiTrash />
            </IconButton>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(RestTimeActions.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [dispatch, _SearchBody]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        search: search,
        skip: page * currentPage,
        take: page,
      })
    );
  }, [dispatch, search, page, currentPage]);
  return (
    <Card>
      <CardContent>
        <Box>
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف"
            name={dialogData.name}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={{}}>
          {/* <Grid item xs={2}>
            <TextField
              size="small"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              label="بحث عن الاسم "
              fullWidth
            />
          </Grid> */}
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              {_RestTime.data ? (
                <DataGrid
                  rows={_RestTime.data}
                  columns={columns}
                  pageSize={page}
                  rowCount={_RestTime.total}
                  loading={loading}
                  rowHeight={100}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) => setCurrentPage(newPage)}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPage(newPageSize);
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RestTimeTable;
