import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiTrash, BiEdit } from "react-icons/bi";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Stock, setSearchBody,
} from "../../../../../app/slices/stockSlice";
import ConfirmationDialog from "../../../../../helpers/ConfirmationDialog";
import { MdOutlineClear } from "react-icons/md";
import { BiTransfer } from "react-icons/bi";
import { setTableDialog } from "../../../../../app/slices/stockMovementSlice";
import StockMovement from "../../../stockMovments";

const StockTable = () => {
  const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState('');

  const dispatch = useDispatch();

  const _Stock = useSelector((state) => state.stock.stocks);
  const _StockMovements = useSelector((state) => state.stockMovement.stockMovements);
  const _SearchBody = useSelector((state) => state.stock.searchBody);
  const loading = useSelector((state) => state.stock.loading);


  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params)
  };

  const handleConfirmDelete = () => {
    dispatch(Stock.deleteById(dialogData.id, _SearchBody))
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const columns = [
    {
      field: "name",
      headerName: "الاسم",
      flex: 1,
    },
    {
      field: "count",
      headerName: "العدد",
      flex: 0.5,
    },
    {
      field: "description",
      headerName: "الوصف",
      flex: 1,
    },
    {
      field: "note",
      headerName: "الملاحظة",
      flex: 1,
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 0.7,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="تعديل">
              <IconButton
                color="primary"
                onClick={() => dispatch(Stock.getByIdForUpdate(params.row.id))}
              >
                <BiEdit />
              </IconButton>
            </Tooltip>

            <Tooltip title="الحركات">
              <IconButton
                color="succuss"
                onClick={() => {
                  dispatch(setTableDialog(params.row))
                }}
              >
                <BiTransfer />
              </IconButton>
            </Tooltip>
            <Tooltip title="حذف">
              <IconButton
                color="error"
                onClick={() => handleDeleteClick(params.row)}
              >
                <BiTrash />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(Stock.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [dispatch, _SearchBody]);

  useEffect(() => {
    dispatch(setSearchBody({
      search: search,
      skip: pageSize * currentPage,
      take: pageSize
    }))
  }, [dispatch, search, pageSize, currentPage]);
  return (
    <Card>
      <CardContent>
        <Box>
          {_StockMovements.dialog && <StockMovement />}
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف"
            name={dialogData.name}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={{}}>
          <Grid item xs={12} md={4}>
            <TextField
              size="small"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              label="بحث عن الاسم "
              fullWidth
              value={search}
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="الغاء الفلتر">
              <IconButton
                onClick={() => {
                  setSearch("");
                  setPageSize(15)
                  setCurrentPage(0)
                }}
              >
                <MdOutlineClear />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              {_Stock.data ? (
                <DataGrid
                  rows={_Stock.data}
                  columns={columns}
                  pageSize={pageSize}
                  rowCount={_Stock.total}
                  loading={loading}
                  rowHeight={40}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) =>
                    setCurrentPage(newPage)
                  }
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize)
                  }
                  }
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              ) : <LinearProgress />}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default StockTable;
