import { forwardRef, useEffect, useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  InputAdornment,
  Divider,
  Box,
  LinearProgress,
  Stack,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  setDialog,
  resetForm,
  Clearance,
  setReportDataTable,
} from "../../../../app/slices/clearance";
import { Controller, useForm } from "react-hook-form";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import DateRangepicker from "../../../../helpers/dateRangePicker";
import { SelectDoctor } from "../../../../components/Selectors/SelectDoctor";
import { DataGrid } from "@mui/x-data-grid";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ClearanceForm = () => {
  const _Clearance = useSelector((state) => state.clearance.clearance);
  const _ClearanceReportData = useSelector(
    (state) => state.clearance.reportData
  );
  const loading = useSelector(
    (state) => state.clearance.clearanceResultLoading
  );
  const _SearchBody = useSelector((state) => state.clearance.searchBody);
  const _User = useSelector((state) => state.user.doctorList);

  const [formData, setFormData] = useState([]);
  const [payDoctorPayment, setPayDoctorPayment] = useState(false);

  const isAdd = _Clearance.form.id ? false : true;
  const { control, reset, handleSubmit, setValue, watch } = useForm({
    defaultValues: _Clearance.form,
  });

  const onSubmit = (data) => {
    setFormData(data);
    if (payDoctorPayment) {
      dispatch(
        Clearance.create(
          {
            user_id: formData.user_id,
            total: _ClearanceReportData.net_amount,
            amount: _ClearanceReportData.user_percentage_amount,
            percentage: formData.percentage,
            start_date: formData.start_date,
            end_date: formData.end_date,
            date: data.date,
            note: data.note,
            payments_ids: _ClearanceReportData.payments_ids,
            expenses_ids: _ClearanceReportData.expenses_ids,
          },
          _SearchBody
        )
      );
    } else {
      dispatch(
        Clearance.getReportData({
          user_id: data.user_id,
          percentage: data.percentage,
          start_date: data.start_date,
          end_date: data.end_date,
        })
      );
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    reset({ ..._Clearance.form });
  }, [reset, _Clearance.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, [dispatch]);

  const handleDialog = () => {
    dispatch(setDialog());
    dispatch(setReportDataTable([]));
  };

  const paymentsColumns = [
    {
      field: "patient_name",
      headerName: "اسم المراجع",
      flex: 2,
    },
    {
      field: "operation_id",
      headerName: "رقم الجلسة",
      flex: 1,
    },
    {
      field: "operation_operation_type_name",
      headerName: "اسم الجلسة",
      flex: 1.5,
    },
    {
      field: "amount",
      headerName: "المبلغ",
      flex: 1,
      renderCell: (params) => <>{params.row.amount?.toLocaleString()}</>,
    },
    {
      field: "date",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) => (
        <>{dayjs(params.row.date).format("YYYY-MM-DD")}</>
      ),
    },
  ];

  const expensesColumns = [
    {
      field: "patient_name",
      headerName: "اسم المراجع",
      flex: 2,
    },
    {
      field: "operation_id",
      headerName: "رقم الجلسة",
      flex: 1,
    },
    {
      field: "operation_operation_type_name",
      headerName: "اسم الجلسة",
      flex: 1.5,
    },
    {
      field: "amount",
      headerName: "المبلغ",
      flex: 1,
      renderCell: (params) => <>{params.row.amount?.toLocaleString()}</>,
    },
    {
      field: "date",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) => (
        <>{dayjs(params.row.date).format("YYYY-MM-DD")}</>
      ),
    },
  ];

  return (
    <Dialog
      open={_Clearance.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth={"md"}
    >
      <DialogContent
        sx={{
          minHeight: "65vh",
        }}
      >
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? " إضافة حساب للطبيب " : "تعديل "}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="date_range"
                control={control}
                render={({ field }) => (
                  <DateRangepicker
                    startDate={watch().start_date}
                    endDate={watch().end_date}
                    handleOnChange={(searchDate) => {
                      const updatedFilter = {
                        from: dayjs(searchDate[0].startDate).format(
                          "YYYY-MM-DD"
                        ),
                        to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                      };
                      if (
                        updatedFilter.from !== searchDate.startDate ||
                        updatedFilter.to !== searchDate.endDate
                      ) {
                        setValue(
                          "start_date",
                          dayjs(searchDate[0].startDate).format("YYYY-MM-DD")
                        );
                        setValue(
                          "end_date",
                          dayjs(searchDate[0].endDate).format("YYYY-MM-DD")
                        );
                      }
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="user_id"
                control={control}
                render={({ field }) => (
                  <SelectDoctor
                    {...field}
                    required
                    onChange={(e, newValue) => {
                      setValue("user_id", newValue ? newValue.id : null);
                    }}
                    value={_User?.filter((x) => x.id == field.value)[0] ?? null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="percentage"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="النسبة"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => setPayDoctorPayment(false)}
              >
                النتيجة
              </Button>
            </Grid>
            <Grid item xs={12} md={12}>
              <Divider />
              {loading && <LinearProgress />}
            </Grid>
            {_ClearanceReportData.total_payments ? (
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} md={4}>
                  <Stack direction="row" spacing={1}>
                    <Typography>المدفوعات الكلية:</Typography>
                    <Typography variant="h4">
                      {_ClearanceReportData.total_payments} IQD
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Stack direction="row" spacing={1}>
                    <Typography>المصروفات الكلية:</Typography>
                    <Typography variant="h4">
                      {_ClearanceReportData.total_expenses} IQD
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Stack direction="row" spacing={1}>
                    <Typography>الصافي:</Typography>
                    <Typography variant="h4">
                      {_ClearanceReportData.net_amount} IQD
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Stack direction="row" spacing={1}>
                    <Typography>نسبة الطبيب:</Typography>
                    <Typography variant="h4">
                      {_ClearanceReportData.percentage} %
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Stack direction="row" spacing={1}>
                    <Typography>المبلغ:</Typography>
                    <Typography variant="h4">
                      {_ClearanceReportData.user_percentage_amount} IQD
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="date"
                    control={control}
                    render={({ field }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          inputFormat="YYYY-MM-DD"
                          label="التاريخ"
                          value={field.value}
                          onChange={(e, newValue) => {
                            setValue("date", dayjs(e).format("YYYY-MM-DD"));
                          }}
                          renderInput={(params) => (
                            <TextField
                              required
                              size="small"
                              fullWidth
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "يوم/شهر/سنة",
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="note"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        multiline
                        size="small"
                        label="الملاحظة"
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => setPayDoctorPayment(true)}
                  >
                    دفع حساب الطبيب
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ height: "40vh", width: "100%" }}>
                    <Typography color={"primary"} textAlign={"center"} m={2}>
                      جدول المدفوعات
                    </Typography>
                    {_ClearanceReportData ? (
                      <DataGrid
                        rows={_ClearanceReportData.payments ?? []}
                        columns={paymentsColumns}
                        rowCount={_Clearance.total}
                        loading={loading}
                        rowHeight={35}
                        pagination
                        paginationMode="client"
                        selectionModel={[]}
                        components={{
                          LoadingOverlay: LinearProgress,
                        }}
                      />
                    ) : null}
                  </Box>
                </Grid>
                {_ClearanceReportData.expenses.length ? (
                  <Grid item xs={12}>
                    <Box sx={{ height: "40vh", width: "100%" }}>
                      <Typography
                        color={"primary"}
                        textAlign={"center"}
                        p={2}
                        mt={2}
                      >
                        جدول المصروفات
                      </Typography>
                      {_ClearanceReportData ? (
                        <DataGrid
                          rows={_ClearanceReportData.expenses ?? []}
                          columns={expensesColumns}
                          rowCount={_Clearance.total}
                          loading={loading}
                          rowHeight={35}
                          pagination
                          paginationMode="client"
                          selectionModel={[]}
                          components={{
                            LoadingOverlay: LinearProgress,
                          }}
                        />
                      ) : null}
                    </Box>
                  </Grid>
                ) : null}
              </Grid>
            ) : (
              <Box
                sx={{
                  height: "30vh",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography>لا توجد بيانات</Typography>
              </Box>
            )}
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ClearanceForm;
