import { forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Box,
  Stack,
  CircularProgress,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  setDialog,
  resetForm,
  OffersActions,
} from "../../../app/slices/offersSlice";
import { Controller, useForm } from "react-hook-form";
import FilePondInput from "../../../helpers/FilePondInput";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OfferForm = () => {
  const _Offer = useSelector((state) => state.offer.offer);
  const _SearchBody = useSelector((state) => state.offer.searchBody);
  const loading = useSelector((state) => state.offer.loading);

  const isAdd = _Offer.form.id ? false : true;
  const { control, reset, handleSubmit } = useForm({
    defaultValues: _Offer.form,
  });
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    const formData = new FormData();
    if (!isAdd) formData.append("_method", "PUT");
    formData.append("name", data.name ?? "");
    if (data.attachment && data.attachment[0].name)
      formData.append("attachment", data.attachment[0]);
    if (isAdd) {
      dispatch(OffersActions.create(formData, _SearchBody));
    } else {
      dispatch(OffersActions.update(data.id, formData, _SearchBody));
    }
  };
  useEffect(() => {
    reset({ ..._Offer.form });
  }, [reset, _Offer.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, [dispatch]);

  const handleDialog = () => {
    dispatch(setDialog());
  };

  return (
    <Dialog
      open={_Offer.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : "تعديل "}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    required
                    label="الاسم"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="attachment"
                control={control}
                render={({ field }) => (
                  <Box>
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ justifyContent: "center", paddingBottom: 2 }}
                    >
                      {!isAdd && (
                        <Box
                          component="img"
                          src={field.value}
                          sx={{ width: 300 }}
                        />
                      )}
                    </Stack>
                    <FilePondInput required field={field} />
                  </Box>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ width: 150 }}
              >
                {loading ? <CircularProgress /> : "حفظ"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default OfferForm;
