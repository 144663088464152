import { forwardRef, useRef } from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Divider,
  Box,
  LinearProgress,
  Stack,
  Button,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { setGetByIdDialog } from "../../../../../app/slices/clearance";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useReactToPrint } from "react-to-print";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ClearanceDetails = () => {
  const dispatch = useDispatch();
  const _HandleOperationType = useSelector(
    (state) => state.treatmentPlan.handleOperationTypes
  );
  const _Clearance = useSelector((state) => state.clearance.getById);
  const loading = useSelector((state) => state.clearance.getById.loading);

  const handleDialog = () => {
    dispatch(setGetByIdDialog());
  };

  const printRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    copyStyles: true,
    pageStyle: `
    body {
      direction: rtl; 
      }`,
  });

  const paymentsColumns = [
    {
      field: "patient_name",
      headerName: "اسم المراجع",
      flex: 2,
    },
    {
      field: "operation_id",
      headerName: "رقم الجلسة",
      flex: 1,
    },
    {
      field: "operation_type_name",
      headerName: "اسم الجلسة",
      flex: 1.5,
    },
    {
      field: "amount",
      headerName: "المبلغ",
      flex: 1,
      renderCell: (params) => parseInt(params.row.amount)?.toLocaleString(),
    },
    {
      field: "date",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) => dayjs(params.row.date).format("YYYY-MM-DD"),
    },
  ];

  const expensesColumns = [
    {
      field: "patient_name",
      headerName: "اسم المراجع",
      flex: 2,
    },
    {
      field: "operation_id",
      headerName: "رقم الجلسة",
      flex: 1,
      renderCell: (params) => params.row.operation?.id,
    },
    {
      field: "operation_type_name",
      headerName: "اسم الجلسة",
      flex: 1.5,
    },
    {
      field: "amount",
      headerName: "المبلغ",
      flex: 1,
      renderCell: (params) => parseInt(params.row.amount)?.toLocaleString(),
    },
    {
      field: "date",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) => dayjs(params.row.date).format("YYYY-MM-DD"),
    },
  ];

  return (
    <Dialog
      open={_Clearance.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {"معلومات حسابات الطبيب"}
        </Typography>
        <Button
          onClick={() => {
            handlePrint();
          }}
          color="primary"
          variant="contained"
          fullWidth
          sx={{ mb: 3 }}
        >
          طباعة
        </Button>
        <Divider sx={{ mb: 3 }} />
        {_HandleOperationType ? (
          <Grid container spacing={2} ref={printRef}>
            <Grid item xs={12} md={4}>
              <Stack direction="row" spacing={1}>
                <Typography>اسم الطبيب:</Typography>
                <Typography variant="h4">
                  {_Clearance.data?.user_name}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack direction="row" spacing={1}>
                <Typography>التاريخ:</Typography>
                <Typography variant="h4">{_Clearance.data?.date}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack direction="row" spacing={1}>
                <Typography>الملاحظات:</Typography>
                <Typography variant="h4">{_Clearance.data?.note}</Typography>
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack direction="row" spacing={1}>
                <Typography>المدفوعات الكلية:</Typography>
                <Typography variant="h4">
                  {_Clearance.data.payments_sum?.toLocaleString()} IQD
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack direction="row" spacing={1}>
                <Typography>المصروفات الكلية:</Typography>
                <Typography variant="h4">
                  {_Clearance.data.expenses_sum?.toLocaleString()} IQD
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack direction="row" spacing={1}>
                <Typography>الصافي:</Typography>
                <Typography variant="h4">
                  {_Clearance.data.total?.toLocaleString()} IQD
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack direction="row" spacing={1}>
                <Typography>نسبة الطبيب:</Typography>
                <Typography variant="h4">
                  {_Clearance.data?.percentage} %
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack direction="row" spacing={1}>
                <Typography>المبلغ:</Typography>
                <Typography variant="h4">
                  {_Clearance.data.amount?.toLocaleString()} IQD
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Typography color={"primary"} textAlign={"center"} m={2}>
                  جدول المدفوعات
                </Typography>
                {_Clearance.data.payments ? (
                  <DataGrid
                    autoHeight
                    rows={_Clearance.data.payments ?? []}
                    columns={paymentsColumns}
                    rowCount={_Clearance.total}
                    loading={loading}
                    rowHeight={35}
                    pagination
                    paginationMode="client"
                    selectionModel={[]}
                    components={{
                      LoadingOverlay: LinearProgress,
                    }}
                  />
                ) : null}
              </Box>
            </Grid>
            {_Clearance.data.expenses?.length ? (
              <Grid item xs={12}>
                <Box>
                  <Typography
                    color={"primary"}
                    textAlign={"center"}
                    p={2}
                    mt={2}
                  >
                    جدول المصروفات
                  </Typography>
                  {_Clearance.data.expenses ? (
                    <DataGrid
                      autoHeight
                      rows={_Clearance.data.expenses ?? []}
                      columns={expensesColumns}
                      rowCount={_Clearance.total}
                      loading={loading}
                      rowHeight={35}
                      pagination
                      paginationMode="client"
                      selectionModel={[]}
                      components={{
                        LoadingOverlay: LinearProgress,
                      }}
                    />
                  ) : null}
                </Box>
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

export default ClearanceDetails;
