import { useState, useEffect, forwardRef } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import { hideNotification } from "../app/slices/notificationSlice";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function NotificationSnackbar() {
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.notification.data);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(notification.show);
  }, [notification.show]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(hideNotification(notification));
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
    >
      <Alert
        onClose={handleClose}
        severity={notification.type}
        sx={{ width: "100%" }}
      >
        {notification.message}
      </Alert>
    </Snackbar>
  );
}
