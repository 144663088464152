import Typography from "@mui/material/Typography";
import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Stack,
  Tooltip,
  useMediaQuery,
  MenuItem,
  Menu,
  ListItemIcon,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiTrash, BiEdit } from "react-icons/bi";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Patient,
  setLoading,
  setSearchBody,
} from "../../../../app/slices/patientSlice";
import ConfirmationDialog from "../../../../helpers/ConfirmationDialog";
import dayjs from "dayjs";
import { SelectCity } from "../../../../components/Selectors/SelectCity";
import { SelectProvince } from "../../../../components/Selectors/SelectProvince";
import { MdOutlineClear } from "react-icons/md";
import { FaTeethOpen } from "react-icons/fa";
import OperationForm from "../../../operation/form";
import { useNavigate } from "react-router";
import { useTheme } from "@emotion/react";
import { HiMenu } from "react-icons/hi";
import { BsListCheck } from "react-icons/bs";
import {
  setDialog,
  waitingList,
} from "../../../../app/slices/waitingListSlice";
import WaitingListForm from "../../../waitingList/form";

const PatientTable = () => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("lg"));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const _Role = useSelector((state) => state.user.userInfo.role);
  const _Patient = useSelector((state) => state.patient.patients);
  const _SearchBody = useSelector((state) => state.patient.searchBody);
  const loading = useSelector((state) => state.patient.loading);
  const _Operation = useSelector((state) => state.operation.operation);
  const _Cities = useSelector((state) => state.provinces.cities);
  const _provinces = useSelector((state) => state.provinces.provinces);
  const _WaitingList = useSelector(
    (state) => state.waitingList.waitingList.dialog
  );

  const [search, setSearch] = useState({
    search: "",
    city_id: null,
    has_debt: false,
  });
  const [provinceId, setProvinceId] = useState(null);
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);

  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");
  const [patientId, setPatientId] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);
  const [isDept, setIsDept] = useState("all");

  const handleChange = (event, newType) => {
    setIsDept(newType);
  };

  const handleMenuClick = (event, item) => {
    setOpenMenu(event.currentTarget);
    setActiveItem(item);
  };

  const handleClose = () => {
    setActiveItem(null);
    setOpenMenu(null);
  };

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(Patient.deleteById(dialogData.id, _SearchBody));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const columns = [
    {
      field: "full_name",
      headerName: "الاسم الكامل",
      flex: 1,
    },
    {
      field: "gender",
      headerName: "الجنس",
      flex: 0.5,
    },
    {
      field: "mobile",
      headerName: "رقم الهاتف",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction="column" spacing={0.5}>
            <Typography>{params.row.mobile_1}</Typography>
            <Typography>{params.row.mobile_2}</Typography>
          </Stack>
        );
      },
    },
    {
      field: "city",
      headerName: "المدينة",
      flex: 1,
    },
    {
      field: "birth_date",
      headerName: "تاريخ الميلاد",
      flex: 1,
      renderCell: (params) => {
        return <>{dayjs(params.row.birth_date).format("YYYY-MM-DD")}</>;
      },
    },
    {
      field: "operation_count",
      headerName: "عدد الجلسات",
      flex: 0.8,
      renderCell: (params) => params.row.operation_count?.toLocaleString(),
    },
    {
      field: "debt",
      headerName: "الديون (IQD)",
      flex: 0.8,
      renderCell: (params) => params.row.debt?.toLocaleString(),
    },
    {
      field: "how_know_us",
      headerName: "كيف سمعت عنا",
      flex: 1,
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: matchUpMd ? 1 : 0.7,
      renderCell: (params) => {
        return matchUpMd ? (
          <>
            <Tooltip title="الملف الطبي">
              <IconButton
                onClick={() => {
                  navigate("/app/medical_profile/" + params.row.id);
                }}
              >
                <FaTeethOpen />
              </IconButton>
            </Tooltip>
            <Tooltip title="اضافة الى قائمة الانتظار">
              <IconButton
                onClick={() => {
                  dispatch(setLoading());
                  dispatch(
                    waitingList.create(
                      {
                        current_status: "waiting_treatment",
                        patient_id: params.row.id,
                        date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                      },
                      _SearchBody,
                      true,
                      true
                    )
                  ).then(() => dispatch(setLoading()));
                }}
              >
                <BsListCheck />
              </IconButton>
            </Tooltip>
            {_Role === "dentist" ? null : (
              <IconButton
                color="primary"
                onClick={() => dispatch(Patient.getById(params.row.id))}
              >
                <BiEdit />
              </IconButton>
            )}
            {_Role === "dentist" ? null : (
              <IconButton
                color="error"
                onClick={() => handleDeleteClick(params.row)}
              >
                <BiTrash />
              </IconButton>
            )}
          </>
        ) : (
          <IconButton
            onClick={(e) => handleMenuClick(e, params)}
            aria-controls={openMenu ? params.row.id : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? "true" : undefined}
          >
            <HiMenu />
          </IconButton>
        );
      },
    },
  ];

  const operationMenu = (params) => (
    <Menu
      sx={{ direction: "ltr" }}
      anchorEl={openMenu}
      keepMountede
      id="account-menu"
      open={activeItem === params}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            left: "50%",
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem
        onClick={() => {
          navigate("/app/medical_profile/" + params.row.id);
        }}
        color="success"
      >
        <ListItemIcon>
          <FaTeethOpen size={20} color="green" />
        </ListItemIcon>
        الملف الطبي
      </MenuItem>
      <MenuItem
        onClick={() => {
          // dispatch(setDialog());
          // setPatientId(params.row.id);
          dispatch(
            waitingList.create(
              {
                current_status: "waiting_treatment",
                patient_id: params.row.id,
                date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
              },
              _SearchBody,
              true,
              true
            )
          );
        }}
        color="success"
      >
        <ListItemIcon>
          <BsListCheck size={20} />
        </ListItemIcon>
        اضافة الى قائمة الانتظار
      </MenuItem>
      {_Role === "dentist" ? null : (
        <MenuItem
          onClick={() => {
            dispatch(Patient.getById(params.row.id));
          }}
        >
          <ListItemIcon>
            <BiEdit size={20} color="#0288D1" />
          </ListItemIcon>
          تعديل
        </MenuItem>
      )}
      {_Role === "dentist" ? null : (
        <MenuItem
          onClick={() => {
            handleDeleteClick(params.row);
          }}
        >
          <ListItemIcon>
            <BiTrash size={20} color="#D32F2F" />
          </ListItemIcon>
          حذف
        </MenuItem>
      )}
    </Menu>
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(Patient.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [dispatch, _SearchBody]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        search: search.search,
        city_id: search.city_id ?? "",
        has_debt: search.has_debt ? 1 : 0,
        skip: pageSize * currentPage,
        take: pageSize,
      })
    );
  }, [dispatch, search, pageSize, currentPage]);

  return (
    <Card>
      <CardContent>
        <Box>
          {_Operation.dialog && <OperationForm patientId={patientId} />}
          {activeItem && operationMenu(activeItem)}
          {_WaitingList && <WaitingListForm patientId={patientId} />}
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف"
            name={dialogData.full_name}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} md={2}>
            <ToggleButtonGroup
              color="primary"
              size="small"
              fullWidth
              value={isDept}
              exclusive
              onChange={handleChange}
            >
              <ToggleButton
                value="all"
                onClick={() =>
                  setSearch({
                    ...search,
                    has_debt: false,
                  })
                }
              >
                الكل
              </ToggleButton>
              <ToggleButton
                value="dept"
                onClick={() =>
                  setSearch({
                    ...search,
                    has_debt: true,
                  })
                }
              >
                الديون
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              size="small"
              onChange={(e) => {
                setSearch({
                  ...search,
                  search: e.target.value,
                });
              }}
              value={search.search}
              label="بحث عن الاسم او رقم هاتف"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SelectProvince
              povinceid={provinceId}
              onChange={(e, newValue) => {
                setProvinceId(newValue ? newValue.id : null);
              }}
              value={
                _provinces.data?.filter((x) => x.id === provinceId)[0]
                  ? _provinces.data?.filter((x) => x.id === provinceId)[0]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SelectCity
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  city_id: newValue ? newValue.id : null,
                });
              }}
              value={
                _Cities.data?.filter((x) => x.id === search.city_id)[0]
                  ? _Cities.data?.filter((x) => x.id === search.city_id)[0]
                  : null
              }
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="الغاء الفلتر">
              <IconButton
                onClick={() => {
                  setSearch({
                    search: "",
                    city_id: null,
                  });
                  setProvinceId(null);
                  setPageSize(15);
                  setCurrentPage(0);
                }}
              >
                <MdOutlineClear />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              {_Patient.data ? (
                <DataGrid
                  rows={_Patient.data}
                  columns={columns}
                  pageSize={pageSize}
                  page={currentPage}
                  rowCount={_Patient.total}
                  loading={loading}
                  rowHeight={50}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) => setCurrentPage(newPage)}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize);
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PatientTable;
