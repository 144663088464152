import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
import dayjs from "dayjs";
const doctorExpenseApi = factory.get("doctorExpense");
const initialState = {
  loading: false,
  searchBody: {},
  doctorExpense: {
    dialog: false,
    form: {
      user_id: null,
      operation_id: null,
      amount: 0,
      type: "",
      date: dayjs().format("YYYY-MM-DD"),
      note: "",
    },
  },
  doctorExpenses: {
    data: [],
    total: 0,
  },
};

export const doctorExpenseSlice = createSlice({
  name: "doctorExpense",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.doctorExpense.dialog = !state.doctorExpense.dialog;
    },
    setDataTable: (state, { payload }) => {
      state.doctorExpenses.data = payload.data.data;
      state.doctorExpenses.total = payload.data.total;
    },
    setDatalist: (state, { payload }) => {
      state.sectionsList.data = payload.data.data;
      state.sectionsList.total = payload.data.total;
    },
    setById: (state, { payload }) => {
      state.doctorExpense.form = payload;
    },
    setLoading: (state, action) => {
      state.loading = !state.loading;
    },
    resetForm: (state, action) => {
      state.doctorExpense.form = initialState.doctorExpense.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
  },
});

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setDatalist,
} = doctorExpenseSlice.actions;

export default doctorExpenseSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("doctorExpense"));
    const res = await doctorExpenseApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("doctorExpense"));
  } catch (err) {
    dispatch(setLoading("doctorExpense"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await doctorExpenseApi.delete(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("doctorExpense"));
    const res = await doctorExpenseApi.getById(id);
    dispatch(setById(res.data.data[0]));

    dispatch(setDialog());
    dispatch(setLoading("doctorExpense"));
  } catch (err) {
    dispatch(setLoading("doctorExpense"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data, _SearchBody) => async (dispatch) => {
  try {
    await doctorExpenseApi.create(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة  بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await doctorExpenseApi.update(id, data);
    dispatch(
      showNotification({
        message: "تم التعديل  بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const DoctorExpenses = {
  getAll,
  deleteById,
  getById,
  create,
  update,
};
