import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
import { Operation } from "./operationSlice";
const dentalImplantApi = factory.get("dentalImplant");
const initialState = {
  searchBody: {},
  loading: false,
  operationId: null,
  dentalImplant: {
    dialog: false,
    form: {
      id: null,
      operation_id: null,
      di_system: "",
      di_no: "",
      treatment_protocol: "",
      loading_protocol: "",
      bone_density: "",
      di_covered_by: "",
      membrane: "",
      note: "",
      follow_up: "",
      shade: "",
      stage2: null,
      stage3: null,
      stage4: null,
    },
  },
};

export const dentalImplantSlice = createSlice({
  name: "dentalImplant",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.dentalImplant.dialog = !state.dentalImplant.dialog;
    },
    setById: (state, { payload }) => {
      state.dentalImplant.form = payload;
    },
    setOperationId: (state, { payload }) => {
      state.operationId = payload;
    },
    setLoading: (state, action) => {
      state.loading = !state.loading;
    },
    resetForm: (state, action) => {
      state.dentalImplant.form = initialState.dentalImplant.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
  },
});

export const {
  setLoading,
  setDialog,
  setById,
  resetForm,
  setSearchBody,
  setOperationId,
} = dentalImplantSlice.actions;

export default dentalImplantSlice.reducer;

//axios
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await dentalImplantApi.delete(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("dentalImplant"));
    const res = await dentalImplantApi.getById(id);
    dispatch(setById(res.data.data[0]));
    dispatch(setDialog());
    dispatch(setLoading("dentalImplant"));
  } catch (err) {
    dispatch(setLoading("dentalImplant"));
    dispatch(setDialog());
    dispatch(
      showNotification({
        message: err.response.data.message,
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("dentalImplant"));
    await dentalImplantApi.create(data);
    dispatch(setLoading("dentalImplant"));
    dispatch(
      showNotification({
        message: "تمت العملية بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    if (!data.id) {
      dispatch(Operation.getByIdForPrescription(data.operation_id));
    }
  } catch (err) {
    dispatch(setLoading("dentalImplant"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await dentalImplantApi.update(id, data);
    dispatch(
      showNotification({
        message: "تم التعديل  بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const DentalImplant = {
  deleteById,
  getById,
  create,
  update,
};
