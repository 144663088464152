import {
  Grid,
  LinearProgress,
  Card,
  CardContent,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Payment
} from "../../../../../../../../app/slices/paymentsSlice";
import dayjs from "dayjs";

const OperationPaymentsTable = () => {
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);

  const dispatch = useDispatch();

  const _Payment = useSelector((state) => state.payment.payments);
  const loading = useSelector((state) => state.payment.loading);
  const _OperationPayment = useSelector((state) => state.payment.operationPayment);

  const columns = [
    {
      field: "id",
      headerName: "رقم الحركة",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "المبلغ",
      flex: 1,
      renderCell: (params) => (
        <>{parseInt(params.row.amount)?.toLocaleString()}</>
      )
    },
    {
      field: "patient_name",
      headerName: "اسم المراجع",
      flex: 1,
    },
    {
      field: "operation_case_type_name",
      headerName: "اسم الجلسة",
      flex: 1,
    },
    {
      field: "created_by_name",
      headerName: "بواسطة",
      flex: 1,
    },
    {
      field: "description",
      headerName: "الوصف",
      flex: 1,
    },
    {
      field: "date",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) => dayjs(params.row.date).format("YYYY-MM-DD")
    },
  ];

  useEffect(() => {
    if (_OperationPayment.operation_id) {
      const timeout = setTimeout(() => {
        dispatch(Payment.getAll({
          operation_id: _OperationPayment.operation_id,
          skip: currentPage * pageSize,
          take: pageSize,
        }));
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [dispatch, currentPage, pageSize, _OperationPayment.operation_id]);

  return (
    <Card>
      <CardContent>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12}>
            <Box sx={{ maxHeight: "70vh", width: "100%" }}>
              {_Payment.data ? (
                <DataGrid
                  rows={_Payment.data}
                  columns={columns}
                  pageSize={pageSize}
                  page={currentPage}
                  rowCount={_Payment.total}
                  loading={loading}
                  rowHeight={50}
                  pagination
                  autoHeight
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) => {
                    setCurrentPage(newPage)
                  }}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize)
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              ) : <LinearProgress />}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default OperationPaymentsTable;
