import { Grid } from "@mui/material";
import React from "react";
import ReportsHeader from "./home/header";
import OperationTable from "./home/table/operation";
import PaymentsTable from "./home/table/payments";
import SpendingTable from "./home/table/spendings";
import { useSelector } from "react-redux";
import XrayTable from "./home/table/xray";
import DeletedTable from "./home/table/deleted";

const Reports = () => {
  const _ReportType = useSelector((state) => state.reports.reportTypes);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ReportsHeader />
      </Grid>
      {_ReportType[0]?.isSelected && (
        <Grid item xs={12}>
          <PaymentsTable />
        </Grid>
      )}
      {_ReportType[1]?.isSelected && (
        <Grid item xs={12}>
          <OperationTable />
        </Grid>
      )}
      {_ReportType[2]?.isSelected && (
        <Grid item xs={12}>
          <SpendingTable />
        </Grid>
      )}
      {_ReportType[3]?.isSelected && (
        <Grid item xs={12}>
          <XrayTable />
        </Grid>
      )}
      {_ReportType[4]?.isSelected && (
        <Grid item xs={12}>
          <DeletedTable />
        </Grid>
      )}
    </Grid>
  );
};

export default Reports;
