import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
import { setStockMovementDialog } from "./stockMovementSlice";
import dayjs from "dayjs";
const treatmentApi = factory.get("treatmentPlan");
const operationTypeApi = factory.get("operationType");
const initialState = {
  loading: false,
  searchBody: {},
  treatmentPlan: {
    dialog: false,
    patient_id: null,
    form: {
      id: null,
      is_need_cbc: false,
      is_need_opg: false,
      is_need_bitewing: false,
      is_need_pa: false,
      patient_id: null,
      patient_name: "",
      note: "",
      date: dayjs().format("YYYY-MM-DD"),
      treatment_plan_operation: [],
    },
  },
  treatmentPlans: {
    data: [],
    total: 0,
  },
  operationTypes: [],
  handleOperationTypes: [],
};

export const treatmentSlice = createSlice({
  name: "treatmentPlan",
  initialState,
  reducers: {
    setDialog: (state, { payload }) => {
      state.treatmentPlan.dialog = !state.treatmentPlan.dialog;
      state.treatmentPlan.patient_id = payload;
    },
    setDataTable: (state, { payload }) => {
      state.treatmentPlan.form = payload.data.data[0];
      if (payload.data.data[0].id) {
        state.handleOperationTypes.forEach((existingItem) => {
          const matchingItem =
            payload.data.data[0]?.treatment_plan_operation?.find(
              (newItem) =>
                newItem.operation_type_id === existingItem.operation_type_id
            );
          if (matchingItem) {
            existingItem.operation_type_id = matchingItem.operation_type_id;
            existingItem.teeth = matchingItem.teeth;
            existingItem.done_teeth = matchingItem.done_teeth;
          } else {
            existingItem.teeth = [];
            existingItem.done_teeth = [];
          }
        });
      }
    },
    setGetAllDataTable: (state, { payload }) => {
      state.treatmentPlans.data = payload.data.data;
      state.treatmentPlans.total = payload.data.total;
    },

    setTreatmentPlan: (state, { payload }) => {
      state.operationTypes = payload.data.data;
      const newInstances = state.operationTypes.map((operationType, index) => ({
        operation_type_id: operationType.id,
        is_all_teeth: operationType.is_all_teeth,
        is_treatment_plan: operationType.is_treatment_plan,
        operation_type_name: operationType.name,
        id: operationType.id,
        teeth: [],
        done_teeth: []
      }));
      state.handleOperationTypes = newInstances;
    },
    handleToothToggle: (state, { payload }) => {
      if (!payload.isAllTeeth) {
        const toggleToothArray = (array, toothId) => {
          if (array.includes(toothId)) {
            return array.filter((id) => id !== toothId);
          } else {
            return [...array, toothId];
          }
        };
        const newInstances = state.handleOperationTypes.map((instance) => {
          return instance.id === payload.instanceId
            ? {
              ...instance,
              operation_type_id: instance.id,
              teeth: toggleToothArray(instance.teeth, payload.toothId),
            }
            : instance;
        });
        state.handleOperationTypes = newInstances;
      } else {
        const newInstances = state.handleOperationTypes.map((instance) => {
          return instance.id === payload.instanceId
            ? {
              ...instance,
              operation_type_id: instance.id,
              teeth: payload.isAllTeethChecked ? payload.allTeeth : [],
              done_teeth: [],
            }
            : instance;
        });
        state.handleOperationTypes = newInstances;
      }
    },
    setById: (state, { payload }) => {
      // state.treatmentPlan.form.id = payload.id;
      // state.treatmentPlan.form.count = payload.count;
    },
    setByIdForUpdate: (state, { payload }) => {
      // state.treatmentPlan.form.id = payload.id;
      // state.treatmentPlan.form.patient_id = payload.patient_id;
      // state.treatmentPlan.form.date = payload.date;
      // state.treatmentPlan.form.note = payload.note;
      // state.treatmentPlan.form.is_need_opg = payload.is_need_opg;
      // state.treatmentPlan.form.is_need_cbc = payload.is_need_cbc;
      // state.treatmentPlan.form.operations = payload.operations;
    },
    setLoading: (state, action) => {
      state.loading = !state.loading;
    },
    resetTeethGrid: (state, action) => {
      const newInstances = state.handleOperationTypes.map((instance) => {
        return {
          ...instance,
          operation_type_id: instance.id,
          teeth: [],
        };
      });
      state.handleOperationTypes = newInstances;
    },
    resetForm: (state, action) => {
      state.treatmentPlan.form = initialState.treatmentPlan.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
  },
});

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setDatalist,
  setByIdForUpdate,
  setTreatmentPlan,
  handleToothToggle,
  setGetAllDataTable,
  resetTeethGrid,
} = treatmentSlice.actions;

export default treatmentSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("treatmentPlan"));
    const res = await treatmentApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("treatmentPlan"));
  } catch (err) {
    dispatch(setLoading("treatmentPlan"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getAllData = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("treatmentPlan"));
    const res = await treatmentApi.getAll(params);
    dispatch(setGetAllDataTable(res));
    dispatch(setLoading("treatmentPlan"));
  } catch (err) {
    dispatch(setLoading("treatmentPlan"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getAllOperationTypes = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("operationType"));
    const res = await operationTypeApi.getAll(params);
    dispatch(setTreatmentPlan(res));
    dispatch(setLoading("operationType"));
  } catch (err) {
    dispatch(setLoading("operationType"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await treatmentApi.delete(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getByIdForUpdate = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("treatmentPlan"));
    const res = await treatmentApi.getById(id);
    dispatch(setByIdForUpdate(res.data.data[0]));

    dispatch(setDialog());
    dispatch(setLoading("treatmentPlan"));
  } catch (err) {
    dispatch(setLoading("treatmentPlan"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("treatmentPlan"));
    const res = await treatmentApi.getById(id);
    dispatch(setById(res.data.data[0]));
    dispatch(setStockMovementDialog());
    dispatch(setLoading("treatmentPlan"));
  } catch (err) {
    dispatch(setLoading("treatmentPlan"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data) => async (dispatch) => {
  try {
    await treatmentApi.create(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة  بنجاح",
        type: "success",
      })
    );
    // dispatch(setDialog());
    // dispatch(getAll());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await treatmentApi.update(id, data);
    dispatch(
      showNotification({
        message: "تم التعديل  بنجاح",
        type: "success",
      })
    );
    // dispatch(setDialog());
    // dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const TreatmentPlan = {
  getAll,
  deleteById,
  getById,
  create,
  update,
  getAllOperationTypes,
  getByIdForUpdate,
  getAllData,
};
