import Typography from "@mui/material/Typography";
import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import {
  Provinces,
  setCityDialog,
  setCityTable,
  setSelectedProvince,
} from "../../../../../../app/slices/provincesSlice";
import { useDispatch, useSelector } from "react-redux";
import { FaCity } from "react-icons/fa";
import City from "../../../cities";

const AddressTabel = () => {
  const [searchProvince, setSearchProvince] = useState("");

  const dispatch = useDispatch();

  const _Provinces = useSelector((state) => state.provinces.provinces);
  const _CityDialog = useSelector((state) => state.provinces.openCityDialog);
  const _SearchBody = useSelector((state) => state.provinces.searchBody);
  const loading = useSelector((state) => state.provinces.loading);
  const _SelectedProvince = useSelector(
    (state) => state.provinces.selectedProvince
  );

  const [provinceData, setProvinceData] = useState(_Provinces.data);

  useEffect(() => {
    const selectedCities = _Provinces.data?.filter((x) =>
      x.id === _SelectedProvince.id ? x.city : null
    )[0];
    dispatch(setCityTable(selectedCities?.city));
  }, [dispatch, _Provinces.data, _SelectedProvince.id]);

  const columns = [
    {
      field: "name",
      headerName: "الاسم",
      flex: 1,
    },
    {
      field: "cities",
      headerName: "المدن",
      flex: 1,
      renderCell: (params) => {
        return <Typography>{params.row.city?.length}</Typography>;
      },
    },
    {
      field: "operations",
      headerName: "ادارة المدن",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <IconButton
              variant="contained"
              onClick={() => {
                dispatch(setCityDialog());
                dispatch(
                  setSelectedProvince({
                    id: params.row.id,
                    name: params.row.name,
                  })
                );
              }}
            >
              <FaCity />
            </IconButton>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(Provinces.getAll());
    }, 500);
    return () => clearTimeout(timeout);
  }, [dispatch, _SearchBody]);

  useEffect(() => {
    if (searchProvince === "") {
      setProvinceData(_Provinces.data);
    } else {
      setProvinceData(
        _Provinces.data?.filter(
          (province) =>
            province.name
              .toLowerCase()
              .startsWith(searchProvince.toLowerCase()) ||
            province.name.startsWith(searchProvince)
        )
      );
    }
  }, [searchProvince, _Provinces]);

  return (
    <Card>
      <CardContent>
        {_CityDialog && <City />}
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} md={3}>
            <TextField
              size="small"
              onChange={(e) => {
                setSearchProvince(e.target.value);
              }}
              value={searchProvince}
              label="بحث عن محافظة"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              <DataGrid
                rows={provinceData}
                columns={columns}
                loading={loading}
                rowHeight={50}
                selectionModel={[]}
                components={{
                  LoadingOverlay: LinearProgress,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AddressTabel;
