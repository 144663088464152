import { Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const TeethChart = ({ _DoneToothIds, _ToothIds, onToothToggle, disabled }) => {
  const _Tooth = useSelector((state) => state.tooth.teethGrid);

  const handleButtonClick = (toothId) => {
    onToothToggle(toothId);
  };

  return (
    <Grid container spacing={0}>
      <Grid
        item
        xs={5.9}
        sx={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Stack direction="column" justifyContent="center">
          <Stack
            sx={{ padding: 1 }}
            spacing={1}
            direction="row"
            alignItems="center"
          >
            {_Tooth
              ?.slice(8, 16)
              .reverse()
              ?.map((tooth, index) => (
                <Button
                  disabled={disabled ? true : false}
                  key={index}
                  size="small"
                  fullWidth
                  sx={{
                    minWidth: 0,
                  }}
                  variant={
                    _ToothIds.includes(tooth.id) ? "contained" : "outlined"
                  }
                  color={
                    _DoneToothIds?.includes(tooth.id)
                      ? "success"
                      : _ToothIds.includes(tooth.id)
                        ? "error"
                        : "primary"
                  }
                  onClick={(e) => {
                    handleButtonClick(tooth.id);
                  }}
                >
                  {tooth.name}
                </Button>
              ))}
          </Stack>
          <Divider
            sx={{
              background: "black",
            }}
          />
          <Stack
            sx={{ padding: 1 }}
            spacing={1}
            direction="row"
            alignItems="center"
          >
            {_Tooth?.slice(16, 24)?.map((tooth, index) => (
              <Button
                disabled={disabled ? true : false}
                size="small"
                fullWidth
                sx={{
                  minWidth: 0,
                }}
                variant={
                  _ToothIds.includes(tooth.id) ? "contained" : "outlined"
                }
                color={
                  _DoneToothIds?.includes(tooth.id)
                    ? "success"
                    : _ToothIds.includes(tooth.id)
                      ? "error"
                      : "primary"
                }
                key={index}
                onClick={() => {
                  handleButtonClick(tooth.id);
                }}
              >
                {tooth.name}
              </Button>
            ))}
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={0.001}>
        <Divider orientation="vertical" sx={{ background: "black" }} />
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          display: "flex",
          justifyContent: "start",
        }}
      >
        <Stack direction="column">
          <Stack
            sx={{ padding: 1 }}
            spacing={1}
            direction="row"
            alignItems="center"
          >
            {_Tooth
              ?.slice(0, 8)
              .reverse()
              ?.map((tooth, index) => (
                <Button
                  disabled={disabled ? true : false}
                  size="small"
                  fullWidth
                  sx={{
                    minWidth: 0,
                  }}
                  variant={
                    _ToothIds.includes(tooth.id) ? "contained" : "outlined"
                  }
                  color={
                    _DoneToothIds?.includes(tooth.id)
                      ? "success"
                      : _ToothIds.includes(tooth.id)
                        ? "error"
                        : "primary"
                  }
                  key={index}
                  onClick={() => {
                    handleButtonClick(tooth.id);
                  }}
                >
                  {tooth.name}
                </Button>
              ))}
            <Typography variant="h4" sx={{ marginLeft: 10 }}>
              U
            </Typography>
          </Stack>
          <Divider sx={{ background: "black" }} />
          <Stack
            sx={{ padding: 1 }}
            spacing={1}
            direction="row"
            alignItems="center"
          >
            {_Tooth?.slice(24, 32)?.map((tooth, index) => (
              <Button
                disabled={disabled ? true : false}
                size="small"
                fullWidth
                sx={{
                  minWidth: 0,
                }}
                variant={
                  _ToothIds.includes(tooth.id) ? "contained" : "outlined"
                }
                color={
                  _DoneToothIds?.includes(tooth.id)
                    ? "success"
                    : _ToothIds.includes(tooth.id)
                      ? "error"
                      : "primary"
                }
                key={index}
                onClick={() => {
                  handleButtonClick(tooth.id);
                }}
              >
                {tooth.name}
              </Button>
            ))}
            <Typography variant="h4" sx={{ marginLeft: 10 }}>
              L
            </Typography>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default TeethChart;
