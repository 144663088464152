import { lazy } from "react";

// project imports
import MainLayout from "../layout/MainLayout";
// import Users from '../views/users';
import Loadable from "./../components/Loadable";
import Provinces from "../views/management/provinces/province";
import Section from "../views/sections";
import Patient from "../views/patient";
import CaseType from "../views/caseType";
import Appointment from "../views/appointment";
import Operation from "../views/operation";
import Stock from "../views/stock/stockDetails";
import Reports from "../views/reports";
import Profile from "../views/users/profile";
import TreatmentPlan from "../views/tooth";
import TreatmentPlanTabs from "../views/tooth/tabs";
import Expense from "../views/finance/expense";
import Payments from "../views/finance/payments";
import DoctorsExpenses from "../views/finance/doctorExpenses";
import Clearance from "../views/finance/clearance";
import Xray from "../views/xray";
import WaitingList from "../views/waitingList";
import Offer from "../views/offers";
import RestTime from "../views/restTime";

// dashboard routing
const Home = Loadable(lazy(() => import("../views/home")));
const Users = Loadable(lazy(() => import("../views/users")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/app",
  element: <MainLayout />,
  children: [
    {
      path: "/app",
      element: <Home />,
    },
    {
      path: "/app/users",
      element: <Users />,
    },
    {
      path: "/app/treatment_plans",
      element: <TreatmentPlan />,
    },
    {
      path: "/app/medical_profile/:patient_id",
      element: <TreatmentPlanTabs />,
    },
    {
      path: "/app/user/Profile",
      element: <Profile />,
    },
    {
      path: "/app/patients",
      element: <Patient />,
    },
    {
      path: "/app/waiting_list",
      element: <WaitingList />,
    },
    {
      path: "/app/sections",
      element: <Section />,
    },
    {
      path: "/app/case_type",
      element: <CaseType />,
    },
    {
      path: "/app/operation",
      element: <Operation />,
    },
    // {
    //   path: "/app/all_appointments",
    //   element: <Appointment />,
    // },
    {
      path: "/app/xray",
      element: <Xray />,
    },
    {
      path: "/app/stock",
      element: <Stock />,
    },
    {
      path: "/app/reports",
      element: <Reports />,
    },
    {
      path: "/app/finance",
      children: [
        {
          path: "/app/finance/payments",
          element: <Payments />,
        },
        {
          path: "/app/finance/clearance",
          element: <Clearance />,
        },
        {
          path: "/app/finance/doctors_expenses",
          element: <DoctorsExpenses />,
        },
        {
          path: "/app/finance/expense",
          element: <Expense />,
        },
      ],
    },
    {
      path: "/app/management",
      children: [
        {
          path: "/app/management/provinces",
          element: <Provinces />,
        },
      ],
    },
    {
      path: "/app/offers",
      element: <Offer />,
    },
    {
      path: "/app/rest_time",
      element: <RestTime />,
    },
  ],
};

export default MainRoutes;
