import dayjs from "dayjs";
import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
import { setDialog, setOperationId } from "./dentalImplantSlice";
const operationApi = factory.get("operation");
const initialState = {
  loading: false,
  createLoading: false,
  patientId: null,
  searchBody: {},
  operation: {
    dialog: false,
    form: {
      id: null,
      patient_id: null,
      operation_type_id: null,
      user_id: null,
      amount: 0,
      discount: 0,
      paid: 0,
      description: "",
      dental_implement: null,
      prescription: "",
      date: dayjs().format("YYYY-MM-DD"),
      teeth: [],
      attachments: [],
    },
  },
  operations: {
    data: [],
    total: 0,
  },
  receipt: {
    dialog: false,
    data: [],
    operation_id: null,
  },
  multiOpertaionReceipt: {
    dialog: false,
    data: [],
    operation_id: null,
  },
  prescription: {
    dialog: false,
    data: [],
    operation_id: null,
  },
};

export const operationSlice = createSlice({
  name: "operation",
  initialState,
  reducers: {
    setPatientId: (state, { payload }) => {
      state.patientId = payload;
    },
    setOperationDialog: (state, action) => {
      state.operation.dialog = !state.operation.dialog;
    },
    setReceiptDialog: (state, { payload }) => {
      state.receipt.dialog = !state.receipt.dialog;
      state.receipt.operation_id = payload;
    },
    setMultiOpertaionReceiptDialog: (state, { payload }) => {
      state.multiOpertaionReceipt.dialog = !state.multiOpertaionReceipt.dialog;
    },
    setReceiptData: (state, { payload }) => {
      state.receipt.data = payload;
    },
    setPrescriptionDialog: (state, { payload }) => {
      state.prescription.dialog = !state.prescription.dialog;
      state.prescription.operation_id = payload;
    },
    setPrescriptionData: (state, { payload }) => {
      state.prescription.data = payload;
    },
    setMultiOpertaionReceiptData: (state, { payload }) => {
      state.multiOpertaionReceipt.data = payload;
    },
    setDataTable: (state, { payload }) => {
      state.operations.data = payload.data.data;
      state.operations.total = payload.data.total;
    },
    setDatalist: (state, { payload }) => {
      state.sectionsList.data = payload.data.data;
      state.sectionsList.total = payload.data.total;
    },
    setById: (state, { payload }) => {
      state.operation.form.id = payload.id;
      state.operation.form.patient_id = payload.patient_id;
      state.operation.form.patient_name = payload.patient_name;
      state.operation.form.dental_implement = payload.dental_implement;
      state.operation.form.operation_type_id = payload.operation_type_id;
      state.operation.form.user_id = payload.user_id;
      state.operation.form.amount = payload.amount;
      state.operation.form.discount = payload.discount;
      state.operation.form.paid = payload.paid;
      state.operation.form.description = payload.description ?? "";
      state.operation.form.prescription = payload.prescription ?? "";
      state.operation.form.date = payload.date;
      state.operation.form.teeth = payload.teeth?.map((x) => x.id);
      state.operation.form.attachments = payload.attachment;
    },
    setLoading: (state, action) => {
      state.loading = !state.loading;
    },
    setLoadingCreate: (state, action) => {
      state.createLoading = !state.createLoading;
    },
    resetForm: (state, action) => {
      state.operation.form = initialState.operation.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
  },
});

export const {
  setLoading,
  setLoadingCreate,
  setOperationDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setDatalist,
  setReceiptDialog,
  setReceiptData,
  setPatientId,
  setPrescriptionDialog,
  setPrescriptionData,
  setMultiOpertaionReceiptData,
  setMultiOpertaionReceiptDialog,
} = operationSlice.actions;

export default operationSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("operation"));
    const res = await operationApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("operation"));
  } catch (err) {
    dispatch(setLoading("operation"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getAllList = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("operation"));
    const res = await operationApi.getAllList(params);
    dispatch(setDatalist(res));
    dispatch(setLoading("operation"));
  } catch (err) {
    dispatch(setLoading("operation"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await operationApi.delete(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteAttachmentById = (id, operationId) => async (dispatch) => {
  try {
    await operationApi.deleteAttachment(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getById(operationId, true));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (id, isUpdate) => async (dispatch) => {
  try {
    dispatch(setLoading("operation"));
    const res = await operationApi.getById(id);
    dispatch(setById(res.data.data[0]));

    if (!isUpdate) {
      dispatch(setOperationDialog());
    }
    dispatch(setLoading("operation"));
  } catch (err) {
    dispatch(setLoading("operation"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getByIdForReceipt = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("operation"));
    const res = await operationApi.getById(id);
    dispatch(setReceiptData(res.data.data[0]));
    dispatch(setReceiptDialog(null));
    dispatch(setLoading("operation"));
  } catch (err) {
    dispatch(setLoading("operation"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getByIdForPrescription = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("operation"));
    const res = await operationApi.getById(id);
    dispatch(setPrescriptionData(res.data.data[0]));
    dispatch(setPrescriptionDialog(null));
    dispatch(setLoading("operation"));
  } catch (err) {
    dispatch(setLoading("operation"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data, _SearchBody) => async (dispatch) => {
  try {
    dispatch(setLoadingCreate("operation"));
    const res = await operationApi.create(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة بنجاح",
        type: "success",
      })
    );
    dispatch(setLoadingCreate("operation"));
    dispatch(resetForm());
    dispatch(setOperationId(res.data.data[0]?.id));
    if (res.data.data[0]?.operation_type_id === 32) {
      dispatch(setDialog());
    }
    if (res.data.data[0]?.operation_type_id !== 32) {
      dispatch(getByIdForReceipt(res.data.data[0]?.id));
    }
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(setLoadingCreate("operation"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const uploadAttachment = (id, data) => async (dispatch) => {
  try {
    dispatch(setLoadingCreate("operation"));
    await operationApi.addAttachment(id, data);
    dispatch(
      showNotification({
        message: "تمت الاضافة بنجاح",
        type: "success",
      })
    );
    dispatch(setLoadingCreate("operation"));
  } catch (err) {
    dispatch(setLoadingCreate("operation"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody, openDialog) => async (dispatch) => {
  try {
    dispatch(setLoadingCreate("operation"));
    await operationApi.update(id, data);
    dispatch(
      showNotification({
        message: "تم التعديل  بنجاح",
        type: "success",
      })
    );
    dispatch(setLoadingCreate("operation"));
    dispatch(resetForm());
    if (openDialog) {
      dispatch(setOperationDialog());
    }
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(setLoadingCreate("operation"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const Operation = {
  getAll,
  deleteById,
  getById,
  create,
  update,
  getAllList,
  getByIdForReceipt,
  getByIdForPrescription,
  deleteAttachmentById,
  uploadAttachment,
};
