import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
const teethApi = factory.get("teeth");
const initialState = {
  loading: false,
  searchBody: {},
  treatmentPlan: {
    form: {
      id: null,
      patient_id: null,
      CBC: false,
      OPG: false,
      note: "",
    },
  },
  teethGrid: [],
  selectedTooth: [
    { id: 1, tooth: 8, selected: false, case: [] },
    { id: 2, tooth: 7, selected: false, case: [] },
    { id: 3, tooth: 6, selected: false, case: [] },
    { id: 4, tooth: 5, selected: false, case: [] },
    { id: 5, tooth: 4, selected: false, case: [] },
    { id: 6, tooth: 3, selected: false, case: [] },
    { id: 7, tooth: 2, selected: false, case: [] },
    { id: 8, tooth: 1, selected: false, case: [] },
    { id: 9, tooth: 1, selected: false, case: [] },
    { id: 10, tooth: 2, selected: false, case: [] },
    { id: 11, tooth: 3, selected: false, case: [] },
    { id: 12, tooth: 4, selected: false, case: [] },
    { id: 13, tooth: 5, selected: false, case: [] },
    { id: 14, tooth: 6, selected: false, case: [] },
    { id: 15, tooth: 7, selected: false, case: [] },
    { id: 16, tooth: 8, selected: false, case: [] },
    { id: 17, tooth: 8, selected: false, case: [] },
    { id: 18, tooth: 7, selected: false, case: [] },
    { id: 19, tooth: 6, selected: false, case: [] },
    { id: 20, tooth: 5, selected: false, case: [] },
    { id: 21, tooth: 4, selected: false, case: [] },
    { id: 22, tooth: 3, selected: false, case: [] },
    { id: 23, tooth: 2, selected: false, case: [] },
    { id: 24, tooth: 1, selected: false, case: [] },
    { id: 25, tooth: 1, selected: false, case: [] },
    { id: 26, tooth: 2, selected: false, case: [] },
    { id: 27, tooth: 3, selected: false, case: [] },
    { id: 28, tooth: 4, selected: false, case: [] },
    { id: 29, tooth: 5, selected: false, case: [] },
    { id: 30, tooth: 6, selected: false, case: [] },
    { id: 31, tooth: 7, selected: false, case: [] },
    { id: 32, tooth: 8, selected: false, case: [] },
  ],
  cases: [
    { id: 0, name: "Filling", teeth: [] },
    { id: 1, name: "Compound filling", teeth: [] },
    { id: 2, name: "R C T", teeth: [] },
    { id: 3, name: "Extraction", teeth: [] },
    { id: 4, name: "Dental implant", teeth: [] },
    { id: 5, name: "Fixed prosthesis", teeth: [] },
    { id: 6, name: "DO NOT KNOW", teeth: [] },
  ],
  selectedCaseTeeth: [],
  selectedToothIds: [],
};

export const toothsSlice = createSlice({
  name: "tooth",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = !state.loading;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
    setTeethGrid: (state, { payload }) => {
      state.teethGrid = payload;
    },
  },
});

export const { setLoading, setTeethGrid } = toothsSlice.actions;

export default toothsSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("stock"));
    const res = await teethApi.getAll(params);
    dispatch(setTeethGrid(res.data.data));
    dispatch(setLoading("stock"));
  } catch (err) {
    dispatch(setLoading("stock"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const Tooth = { getAll };
