import { Card, CardHeader, Typography, Button, Stack } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { GiExpense } from "react-icons/gi";
import { useSelector } from "react-redux";
import { setTypesDialog } from "../../../../../../app/slices/expenseTypesSlice";
import { IoIosAddCircleOutline } from "react-icons/io";
import ExpenseTpesForm from "../../from";

const ExpenseHeader = () => {
  const _ExpenseTypes = useSelector((state) => state.expenseTypes.expenseTypes);
  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            أنواع المصاريف
          </Typography>
        }
        avatar={<GiExpense size={20} />}
        action={
          <Stack direction='row' spacing={2} justifyContent='center' alignItems='center'>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<IoIosAddCircleOutline />}
              onClick={() => dispatch(setTypesDialog())}
            >
              إضافة نوع المصروف
            </Button>
          </Stack>
        }
      />
      {_ExpenseTypes.dialog ? <ExpenseTpesForm /> : null}
    </Card>
  );
};

export default ExpenseHeader;
