import { Card, CardHeader, Typography, Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AiFillDollarCircle } from "react-icons/ai";
import PaymentsForm from "../../from";
import { setDialog } from "../../../../../app/slices/paymentsSlice";
import { IoIosAddCircleOutline } from "react-icons/io";

const PaymentHeader = () => {
  const _Payment = useSelector((state) => state.payment.payment);
  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            حركات الدفع
          </Typography>
        }
        avatar={<AiFillDollarCircle size={20} />}
        action={
          <Button
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<IoIosAddCircleOutline />}
            onClick={() => dispatch(setDialog())}
          >
            إضافة
          </Button>
        }
      />
      {_Payment.dialog ? <PaymentsForm /> : null}
    </Card>
  );
};

export default PaymentHeader;
