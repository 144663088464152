import { forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  InputAdornment,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  setDialog,
  resetForm,
  DoctorExpenses,
} from "../../../../app/slices/doctorExpensesSlice";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { SelectOperationType } from "../../../../components/Selectors/SelectOperationType";
import { SelectDoctor } from "../../../../components/Selectors/SelectDoctor";
import { SelectOperation } from "../../../../components/Selectors/SelectOperation";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DoctorsExpensesForm = () => {
  const _DoctorsExpenses = useSelector(
    (state) => state.doctorExpense.doctorExpense
  );
  const _SearchBody = useSelector((state) => state.doctorExpense.searchBody);
  const _OperationType = useSelector(
    (state) => state.operation.operations.data
  );
  const _User = useSelector((state) => state.user.doctorList);

  const isAdd = _DoctorsExpenses.form.id ? false : true;
  const { control, reset, handleSubmit, setValue } = useForm({
    defaultValues: _DoctorsExpenses.form,
  });

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(
        DoctorExpenses.create(
          {
            operation_id: data.operation_id ?? "",
            user_id: data.user_id,
            note: data.note,
            amount: data.amount,
            type: data.type,
            date: data.date,
          },
          _SearchBody
        )
      );
    } else {
      dispatch(
        DoctorExpenses.update(
          data.id,
          {
            id: data.id,
            operation_id: data.operation_id ?? "",
            user_id: data.user_id,
            note: data.note,
            amount: data.amount,
            type: data.type,
            date: data.date,
          },
          _SearchBody
        )
      );
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    reset({ ..._DoctorsExpenses.form });
  }, [reset, _DoctorsExpenses.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, [dispatch]);

  const handleDialog = () => {
    dispatch(setDialog());
  };

  const NumericFormatCustom = forwardRef(function NumericFormatCustom(
    props,
    ref
  ) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
  });

  return (
    <Dialog
      open={_DoctorsExpenses.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : "تعديل "}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="operation_id"
                control={control}
                render={({ field }) => (
                  <SelectOperation
                    {...field}
                    onChange={(e, newValue) => {
                      setValue("operation_id", newValue ? newValue.id : null);
                    }}
                    value={
                      _OperationType?.filter((x) => x.id == field.value)[0] ??
                      null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="user_id"
                control={control}
                render={({ field }) => (
                  <SelectDoctor
                    {...field}
                    required
                    onChange={(e, newValue) => {
                      setValue("user_id", newValue ? newValue.id : null);
                    }}
                    value={_User?.filter((x) => x.id == field.value)[0] ?? null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="amount"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="المبلغ"
                    fullWidth
                    {...field}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      startAdornment: (
                        <InputAdornment position="start">IQD</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <TextField size="small" label="النوع" fullWidth {...field} />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <TextField
                    multiline
                    rows={3}
                    size="small"
                    label="الملاحظة"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default DoctorsExpensesForm;
