import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Column from "./Column";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  LinearProgress,
  SpeedDial,
  SpeedDialAction,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  setSearchBody,
  waitingList,
} from "../../../../app/slices/waitingListSlice";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import TimeDialog from "./timeDialog";
import {
  Appointment,
  setDialog,
  setDoctorsAppointmentsSearchBody,
} from "../../../../app/slices/appointmentSlice";
import {
  MdArrowBackIos,
  MdArrowBackIosNew,
  MdModeEdit,
  MdNavigateNext,
  MdOutlineAddCircle,
  MdOutlineArrowForwardIos,
  MdOutlineClear,
  MdOutlineSearch,
} from "react-icons/md";
import AppointmentForm from "../../../appointment/form";
import { useTheme } from "@emotion/react";
import { ChangeAppointmentStatus } from "../../../appointment/component/ChangeAppointmentStatus";
import {
  FaArrowLeft,
  FaArrowRight,
  FaCircleInfo,
  FaComputer,
  FaUser,
} from "react-icons/fa6";
import { BiTrash } from "react-icons/bi";
import ConfirmationDialog from "../../../../helpers/ConfirmationDialog";
import { SelectDoctor } from "../../../../components/Selectors/SelectDoctor";
import { showNotification } from "../../../../app/slices/notificationSlice";
import { GiPlayerPrevious } from "react-icons/gi";

const Container = styled("Box")`
  display: flex;
  background-color: ${(props) =>
    props.isDraggingOver ? "#639ee2" : "inherit"};
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const WaitingListData = () => {
  const dispatch = useDispatch();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const _Appointments = useSelector(
    (state) => state.appointment.doctorsAppointments
  );
  const _Appointment = useSelector((state) => state.appointment.appointment);
  const _WaitingListTypes = useSelector(
    (state) => state.waitingList.waitingListData
  );
  const loading = useSelector((state) => state.waitingList.loading);
  const _DoctorsAppointmentsSearchBodyLoading = useSelector(
    (state) => state.appointment.loading
  );
  const _SearchBody = useSelector((state) => state.waitingList.searchBody);
  const _WaitingList = useSelector(
    (state) => state.waitingList.timeData.dialog
  );
  const _DoctorsAppointmentsSearchBody = useSelector(
    (state) => state.appointment.doctorsAppointmentsSearchBody
  );
  const _Role = useSelector((state) => state.user.userInfo.role);
  const _User = useSelector((state) => state.user.doctorList);

  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedToTime, setSelectedToTime] = useState("");
  const [selectionStart, setSelectionStart] = useState(null);
  const [selectionEnd, setSelectionEnd] = useState(null);
  const [selectedDentistId, setSelectedDentistId] = useState(null);
  const [starter, setStarter] = useState([]);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");
  const [searchDateWaitingList, setSearchDateWaitingList] = useState(
    _SearchBody.date
  );
  const [searchDateAppointment, setSearchDateAppointment] = useState(
    _DoctorsAppointmentsSearchBody.date
  );
  const [value, setValue] = useState(0);
  const [selectedCells, setSelectedCells] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(Appointment.deleteById(dialogData.id, _SearchBody)).then(() => {
      dispatch(
        Appointment.getAllDoctorsAppointment(_DoctorsAppointmentsSearchBody)
      );
      setConfirmOpen(false);
    });
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  useEffect(() => {
    setStarter(_WaitingListTypes?.data);
  }, [_WaitingListTypes.data]);

  useEffect(() => {
    dispatch(waitingList.getAll(_SearchBody));
  }, [_SearchBody, dispatch]);

  useEffect(() => {
    dispatch(
      Appointment.getAllDoctorsAppointment(_DoctorsAppointmentsSearchBody)
    );
  }, [_DoctorsAppointmentsSearchBody, dispatch]);

  const onDragEnd = ({ destination, source, draggableId, type }) => {
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = starter.columns[source.droppableId];
    const end = starter.columns[destination.droppableId];

    if (type === "column") {
      const newOrder = [...starter.columnOrder];
      newOrder.splice(source.index, 1);
      newOrder.splice(destination.index, 0, draggableId);
      setStarter({
        ...starter,
        columnOrder: newOrder,
      });
      return;
    }

    const startPatientIds = [...start.patient_id];
    const endPatientIds = [...end.patient_id];

    startPatientIds.splice(source.index, 1);
    endPatientIds.splice(destination.index, 0, draggableId);

    const waitingListObject = Object.values(starter.patients).find(
      (patient) => patient.patient_id === draggableId
    );

    const match = draggableId.match(/\d+/);
    const destinationColumnId = destination.droppableId.match(/\d+/);
    const sourceColumnId = source.droppableId.match(/\d+/);
    const isForward = destinationColumnId >= sourceColumnId ? true : false;

    switch (destination.droppableId) {
      case "column-1":
        return isForward
          ? dispatch(
            waitingList.update(
              parseInt(match[0], 10),
              {
                current_status: "waiting_treatment",
                date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
              },
              _SearchBody
            )
          )
          : waitingListObject.waiting_treatment
            ? dispatch(
              waitingList.update(
                parseInt(match[0], 10),
                {
                  current_status: "waiting_treatment",
                },
                _SearchBody
              )
            )
            : dispatch(
              waitingList.update(
                parseInt(match[0], 10),
                {
                  current_status: "waiting_treatment",
                  date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                },
                _SearchBody
              )
            );
      case "column-2":
        return isForward
          ? dispatch(
            waitingList.update(
              parseInt(match[0], 10),
              {
                current_status: "in_treatment",
                date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
              },
              _SearchBody
            )
          )
          : waitingListObject.in_treatment
            ? dispatch(
              waitingList.update(
                parseInt(match[0], 10),
                {
                  current_status: "in_treatment",
                },
                _SearchBody
              )
            )
            : dispatch(
              waitingList.update(
                parseInt(match[0], 10),
                {
                  current_status: "in_treatment",
                  date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                },
                _SearchBody
              )
            );
      case "column-3":
        return isForward
          ? dispatch(
            waitingList.update(
              parseInt(match[0], 10),
              {
                current_status: "waiting_xray",
                date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
              },
              _SearchBody
            )
          )
          : waitingListObject.waiting_xray
            ? dispatch(
              waitingList.update(
                parseInt(match[0], 10),
                {
                  current_status: "waiting_xray",
                },
                _SearchBody
              )
            )
            : dispatch(
              waitingList.update(
                parseInt(match[0], 10),
                {
                  current_status: "waiting_xray",
                  date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                },
                _SearchBody
              )
            );
      case "column-4":
        return isForward
          ? dispatch(
            waitingList.update(
              parseInt(match[0], 10),
              {
                current_status: "in_xray",
                date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
              },
              _SearchBody
            )
          )
          : waitingListObject.in_xray
            ? dispatch(
              waitingList.update(
                parseInt(match[0], 10),
                {
                  current_status: "in_xray",
                },
                _SearchBody
              )
            )
            : dispatch(
              waitingList.update(
                parseInt(match[0], 10),
                {
                  current_status: "in_xray",
                  date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                },
                _SearchBody
              )
            );
      case "column-5":
        return isForward
          ? dispatch(
            waitingList.update(
              parseInt(match[0], 10),
              {
                current_status: "leaving",
                date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
              },
              _SearchBody
            )
          )
          : waitingListObject.leaving
            ? dispatch(
              waitingList.update(
                parseInt(match[0], 10),
                {
                  current_status: "leaving",
                },
                _SearchBody
              )
            )
            : dispatch(
              waitingList.update(
                parseInt(match[0], 10),
                {
                  current_status: "leaving",
                  date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                },
                _SearchBody
              )
            );

      default:
        return null;
    }
  };

  const changeAppointmentStatus = (id, item) => {
    dispatch(
      Appointment.changeStatus(
        { appointment_id: id, status: item.name },
        _DoctorsAppointmentsSearchBody,
        true
      )
    );
  };

  const hours = Array.from({ length: 18 }, (_, i) => 12 + i / 2); // 12 PM to 9 PM in half-hour steps

  const handleCellClick = (dentistId, startHour, endHour) => {
    const startTime = dayjs(_SearchBody.date)
      .hour(Math.floor(startHour))
      .minute(startHour % 1 === 0 ? 0 : 30)
      .second(0)
      .toISOString();
    const endTime = dayjs(_SearchBody.date)
      .hour(Math.floor(endHour))
      .minute(endHour % 1 === 0 ? 0 : 30)
      .second(0)
      .toISOString();
    setSelectedUserId(dentistId);
    setSelectedTime(startTime);
    setSelectedToTime(endTime);
    dispatch(setDialog());
  };

  const handleMouseDown = (dentistId, hour) => {
    if (!isSmallScreen) {
      setSelectedDentistId(dentistId);
      setSelectionStart(hour);
      setSelectionEnd(hour);
    }
  };

  const handleMouseUp = () => {
    if (!isSmallScreen) {
      if (
        selectionStart !== null &&
        selectionEnd !== null &&
        selectedDentistId !== null
      ) {
        handleCellClick(selectedDentistId, selectionStart, selectionEnd + 0.5);
      }
      setSelectionStart(null);
      setSelectionEnd(null);
      setSelectedDentistId(null);
    }
  };

  const handleMouseEnter = (hour) => {
    if (!isSmallScreen && selectionStart !== null) {
      setSelectionEnd(hour);
    }
  };

  // Touch event handlers
  const handleTouchStart = (dentistId, hour) => {
    if (isSmallScreen) {
      setSelectedCells((prevSelectedCells) => {
        // Ensure only cells from one row are selected
        if (
          prevSelectedCells.length > 0 &&
          prevSelectedCells[0].dentistId !== dentistId
        ) {
          return [{ dentistId, hour }];
        }
        // Check if the cell is already selected
        const isAlreadySelected = prevSelectedCells.some(
          (cell) => cell.dentistId === dentistId && cell.hour === hour
        );
        if (isAlreadySelected) {
          // Deselect the cell and any higher hour cells
          return prevSelectedCells.filter(
            (cell) => cell.dentistId !== dentistId || cell.hour < hour
          );
        }
        // Ensure only consecutive time slots are selected
        if (
          prevSelectedCells.length > 0 &&
          Math.abs(
            prevSelectedCells[prevSelectedCells.length - 1].hour - hour
          ) !== 0.5
        ) {
          return prevSelectedCells;
        }
        return [...prevSelectedCells, { dentistId, hour }];
      });
    } else {
      setSelectedDentistId(dentistId);
      setSelectionStart(hour);
      setSelectionEnd(hour);
    }
  };

  const handleTouchEnd = () => {
    if (!isSmallScreen) {
      if (
        selectionStart !== null &&
        selectionEnd !== null &&
        selectedDentistId !== null
      ) {
        handleCellClick(selectedDentistId, selectionStart, selectionEnd + 0.5);
      }
      setSelectionStart(null);
      setSelectionEnd(null);
      setSelectedDentistId(null);
    }
  };

  const handleTouchMove = (event, hour) => {
    if (isSmallScreen) {
      event.preventDefault(); // Prevent scrolling
      if (selectionStart !== null) {
        setSelectionEnd(hour);
      }
    }
  };

  const handleSpeedDialClick = () => {
    if (selectedCells.length > 0) {
      const dentistId = selectedCells[0].dentistId;
      const startHour = Math.min(...selectedCells.map((cell) => cell.hour));
      const endHour = Math.max(...selectedCells.map((cell) => cell.hour)) + 0.5;
      handleCellClick(dentistId, startHour, endHour);
      setSelectedCells([]);
    }
  };

  const renderCells = (dentist) => {
    const cells = [];
    let skip = 0;

    hours.forEach((hour) => {
      if (skip > 0) {
        skip--;
        return;
      }

      const appointment = dentist.data.find((app) => {
        const appStartHour =
          dayjs(app.date).hour() + dayjs(app.date).minute() / 60;
        const appEndHour =
          dayjs(app.to_date).hour() + dayjs(app.to_date).minute() / 60;
        return appStartHour <= hour && appEndHour > hour;
      });

      const isRestTime = dentist.restTime.some((rest) => {
        const restStartHour =
          dayjs(rest.start_date).hour() + dayjs(rest.start_date).minute() / 60;
        const restEndHour =
          dayjs(rest.end_date).hour() + dayjs(rest.end_date).minute() / 60;
        return restStartHour <= hour && restEndHour > hour;
      });

      const isSelected =
        selectedDentistId === dentist.dentist_id &&
        selectionStart !== null &&
        selectionEnd !== null &&
        hour >= selectionStart &&
        hour <= selectionEnd;

      const isTouchSelected =
        isSmallScreen &&
        selectedCells.some(
          (cell) => cell.dentistId === dentist.dentist_id && cell.hour === hour
        );

      if (appointment) {
        const appStartHour =
          dayjs(appointment.date).hour() +
          dayjs(appointment.date).minute() / 60;
        const appEndHour =
          dayjs(appointment.to_date).hour() +
          dayjs(appointment.to_date).minute() / 60;
        const colSpan =
          (Math.min(appEndHour, 21) - Math.max(appStartHour, 12)) * 2;
        skip = colSpan - 1;

        cells.push(
          <TableCell
            key={hour}
            className="cell"
            colSpan={colSpan}
            sx={{
              border: "1px solid #ccc",
              backgroundColor:
                isSelected || isTouchSelected ? "#e0f7fa" : "inherit",
            }}
          >
            <Box className="appointment" sx={{ textAlign: "center" }}>
              <Box sx={{ m: 1, fontWeight: "bold" }}>
                {appointment.patient_name}
              </Box>
              <Box sx={{ m: 1 }}>{appointment.operation_type_name}</Box>
              <Box sx={{ m: 1 }}>
                <ChangeAppointmentStatus
                  name={appointment.status}
                  status={appointment.status}
                  onChangeStatus={(item) => {
                    changeAppointmentStatus(appointment.id, item);
                  }}
                />
              </Box>
              <Box>
                <Tooltip title="تعديل الموعد" followCursor placement="top">
                  <IconButton
                    variant="contained"
                    color="warning"
                    onClick={() =>
                      dispatch(Appointment.getById(appointment.id))
                    }
                  >
                    <MdModeEdit />
                  </IconButton>
                </Tooltip>
                {_Role === "dentist" ? null : (
                  <Tooltip title="حذف">
                    <IconButton
                      color="error"
                      onClick={() => handleDeleteClick(appointment)}
                    >
                      <BiTrash />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
              <Box>
                <Stack direction="row" justifyContent="center" spacing={2}>
                  {appointment.note ? (
                    <Tooltip
                      title={
                        <Typography variant="h4">{appointment.note}</Typography>
                      }
                      followCursor
                    >
                      <Typography align="center">
                        <FaCircleInfo size={20} />
                      </Typography>
                    </Tooltip>
                  ) : null}
                  {appointment.channel === 1 ? (
                    <FaComputer size={25} color="#C2EAFC" />
                  ) : (
                    <FaUser size={25} color="#81C784" />
                  )}
                </Stack>
              </Box>
            </Box>
          </TableCell>
        );
      } else if (isRestTime) {
        cells.push(
          <TableCell
            key={hour}
            className="cell rest-time"
            sx={{
              border: "1px solid #ccc",
              backgroundColor:
                isSelected || isTouchSelected ? "#e0f7fa" : "inherit",
            }}
          >
            <Box className="rest-time-label" sx={{ textAlign: "center" }}>
              غير متوفر
            </Box>
          </TableCell>
        );
      } else {
        cells.push(
          <TableCell
            key={hour}
            className="cell"
            onMouseDown={() => handleMouseDown(dentist.dentist_id, hour)}
            onMouseUp={handleMouseUp}
            onMouseEnter={() => handleMouseEnter(hour)}
            onTouchStart={() => handleTouchStart(dentist.dentist_id, hour)}
            onTouchEnd={handleTouchEnd}
            onTouchMove={(event) => handleTouchMove(event, hour)}
            sx={{
              border: "1px solid #ccc",
              backgroundColor:
                isSelected || isTouchSelected ? "#e0f7fa" : "inherit",
            }}
          />
        );
      }
    });

    return cells;
  };

  return (
    <Card sx={{ overflow: "auto", width: "100%" }}>
      {_WaitingList && <TimeDialog />}
      {_Appointment.dialog ? (
        <AppointmentForm
          doctorsAppointments={true}
          userId={selectedUserId}
          selectedTime={selectedTime}
          selectedToTime={selectedToTime}
        />
      ) : null}
      {(loading || _DoctorsAppointmentsSearchBodyLoading) && <LinearProgress />}
      <ConfirmationDialog
        open={openConfirm}
        title="تاكيد"
        message="هل انت متاكد من حذف"
        name={dialogData.patient_name}
        confirmText="حذف"
        cancelText="الغاء"
        onConfirm={() => handleConfirmDelete()}
        onCancel={handleCancelDelete}
      />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <Stack direction="row" spacing={2}>
              <IconButton
                variant="contained"
                onClick={() => {
                  dispatch(
                    setSearchBody({
                      date: dayjs(_SearchBody.date)
                        .subtract(1, "day")
                        .format("YYYY-MM-DD"),
                    })
                  );
                  dispatch(
                    setDoctorsAppointmentsSearchBody({
                      start_date: dayjs(_SearchBody.date)
                        .subtract(1, "day")
                        .format("YYYY-MM-DD 00:00:00"),
                      end_date: dayjs(_SearchBody.date)
                        .subtract(1, "day")
                        .format("YYYY-MM-DD 23:59:59"),
                      skip: 0,
                      take: 1000,
                    })
                  );
                }}
              >
                <MdOutlineArrowForwardIos size={25} />
              </IconButton>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="YYYY-MM-DD"
                  label="التاريخ"
                  value={_SearchBody.date}
                  onChange={(e, newValue) => {
                    setSearchDateAppointment(dayjs(e).format("YYYY-MM-DD"));
                    setSearchDateWaitingList(dayjs(e).format("YYYY-MM-DD"));
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      fullWidth
                      size="small"
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "يوم/شهر/سنة",
                        onKeyDown: (event) => {
                          if (event.key === "Enter") {
                            const isValidDate = dayjs(
                              searchDateWaitingList
                            ).isValid();
                            if (!isValidDate) {
                              dispatch(
                                showNotification({
                                  message: "صيغة التاريخ غير صحيحة",
                                  type: "error",
                                })
                              );
                              return;
                            }
                            dispatch(
                              setSearchBody({
                                date: dayjs(searchDateWaitingList).format(
                                  "YYYY-MM-DD"
                                ),
                              })
                            );
                            dispatch(
                              setDoctorsAppointmentsSearchBody({
                                start_date: dayjs(searchDateAppointment).format(
                                  "YYYY-MM-DD 00:00:00"
                                ),
                                end_date: dayjs(searchDateAppointment).format(
                                  "YYYY-MM-DD 23:59:59"
                                ),
                                skip: 0,
                                take: 1000,
                              })
                            );
                          }
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
              <IconButton
                variant="contained"
                onClick={() => {
                  dispatch(
                    setSearchBody({
                      date: dayjs(_SearchBody.date)
                        .add(1, "day")
                        .format("YYYY-MM-DD"),
                    })
                  );
                  dispatch(
                    setDoctorsAppointmentsSearchBody({
                      ..._DoctorsAppointmentsSearchBody,
                      start_date: dayjs(_SearchBody.date)
                        .add(1, "day")
                        .format("YYYY-MM-DD 00:00:00"),
                      end_date: dayjs(_SearchBody.date)
                        .add(1, "day")
                        .format("YYYY-MM-DD 23:59:59"),
                      skip: 0,
                      take: 1000,
                    })
                  );
                }}
              >
                <MdArrowBackIosNew size={25} />
              </IconButton>
            </Stack>
          </Grid>
          {value === 1 ? null : (
            <Grid item xs={12} md={3}>
              <SelectDoctor
                onChange={(e, newValue) => {
                  dispatch(
                    setDoctorsAppointmentsSearchBody({
                      ..._DoctorsAppointmentsSearchBody,
                      user_id: newValue ? newValue.id : null,
                    })
                  );
                }}
                value={
                  _User?.filter(
                    (x) => x.id === _DoctorsAppointmentsSearchBody.user_id
                  )[0] ?? null
                }
              />
            </Grid>
          )}
          <Grid item xs={3}>
            <Tooltip title="بحث">
              <IconButton
                onClick={() => {
                  dispatch(
                    setSearchBody({
                      ..._SearchBody,
                      date: dayjs(searchDateWaitingList).format("YYYY-MM-DD"),
                    })
                  );
                  dispatch(
                    setDoctorsAppointmentsSearchBody({
                      ..._DoctorsAppointmentsSearchBody,
                      start_date: dayjs(searchDateAppointment).format(
                        "YYYY-MM-DD 00:00:00"
                      ),
                      end_date: dayjs(searchDateAppointment).format(
                        "YYYY-MM-DD 23:59:59"
                      ),
                    })
                  );
                }}
              >
                <MdOutlineSearch />
              </IconButton>
            </Tooltip>
            <Tooltip title="الغاء الفلتر">
              <IconButton
                onClick={() => {
                  setSearchDateWaitingList(dayjs().format("YYYY-MM-DD"));
                  setSearchDateAppointment(dayjs().format("YYYY-MM-DD"));
                  dispatch(
                    setSearchBody({
                      date: dayjs().format("YYYY-MM-DD"),
                    })
                  );
                  dispatch(
                    setDoctorsAppointmentsSearchBody({
                      start_date: dayjs().format("YYYY-MM-DD 00:00:00"),
                      end_date: dayjs().format("YYYY-MM-DD 23:59:59"),
                      user_id: null,
                      skip: 0,
                      take: 1000,
                    })
                  );
                }}
              >
                <MdOutlineClear />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs"
                variant="fullWidth"
              >
                <Tab label="المواعيد" {...a11yProps(0)} />
                <Tab label="الانتظار" {...a11yProps(1)} />
              </Tabs>
            </Box>
          </Grid>
          <TabPanel value={value} index={0}>
            <Grid item xs={12}>
              <Card
                className={isSmallScreen ? "no-scroll" : ""}
                sx={{ overflow: "auto", width: "2500px" }}
              >
                <Table
                  sx={{ border: "1px solid #ccc", borderCollapse: "collapse" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="dentist-name-header"
                        sx={{ border: "1px solid #ccc" }}
                      ></TableCell>
                      {hours.map((hour) => (
                        <TableCell
                          key={hour}
                          className="hour-header"
                          sx={{ border: "1px solid #ccc", textAlign: "center" }}
                        >
                          {Math.floor(hour) % 12 || 12}:
                          {hour % 1 === 0 ? "00" : "30"} PM
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(_DoctorsAppointmentsSearchBody.user_id
                      ? _Appointments.filter(
                        (dentist) =>
                          dentist.dentist_id ===
                          _DoctorsAppointmentsSearchBody.user_id
                      )
                      : _Appointments
                    ).map((dentist) => (
                      <TableRow
                        key={dentist.dentist_id}
                        className="dentist-row"
                      >
                        <TableCell
                          className="dentist-name"
                          sx={{
                            border: "1px solid #ccc",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {dentist.dentist}
                        </TableCell>
                        {renderCells(dentist)}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Card>
            </Grid>
          </TabPanel>
          {isSmallScreen && selectedCells.length > 0 && (
            <SpeedDial
              ariaLabel="SpeedDial"
              sx={{ position: "fixed", bottom: 16, right: 16 }}
              icon={<MdOutlineAddCircle size={25} />}
            >
              <SpeedDialAction
                icon={<MdOutlineAddCircle size={25} />}
                tooltipTitle={<span style={{ whiteSpace: 'nowrap' }}>أضافة موعد</span>}
                title="أضافة موعد"
                tooltipOpen
                onClick={handleSpeedDialClick}
              />
            </SpeedDial>
          )}
          <TabPanel value={value} index={1}>
            <Grid item xs={12}>
              {!_WaitingListTypes.data.patients ? null : (
                <Box>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable
                      droppableId="all-column"
                      type="column"
                      direction="horizontal"
                      isDropDisabled
                    >
                      {(provided, snapshot) => (
                        <Container
                          isDraggingOver={snapshot.isDraggingOver}
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            borderRadius: 3,
                          }}
                        >
                          <Grid container spacing={2}>
                            {starter.columnOrder?.map((columnId, index) => {
                              const column = starter?.columns[columnId];
                              const patients = column.patient_id?.map(
                                (patient_id) => starter.patients[patient_id]
                              );
                              return (
                                <Grid key={column.id} item xs={6} md={2.4}>
                                  <Column
                                    index={index}
                                    key={column.id}
                                    column={column}
                                    patients={patients}
                                  />
                                </Grid>
                              );
                            })}
                            {provided.placeholder}
                          </Grid>
                        </Container>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Box>
              )}
            </Grid>
          </TabPanel>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default WaitingListData;
