import { forwardRef, useEffect, useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Autocomplete,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  setDialog,
  resetForm,
  Patient,
} from "../../../app/slices/patientSlice";
import { Controller, useForm } from "react-hook-form";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SelectCity } from "../../../components/Selectors/SelectCity";
import { SelectProvince } from "../../../components/Selectors/SelectProvince";
import dayjs from "dayjs";
import { SelectKnownUs } from "../../../components/Selectors/SelectKnownUs";
import { MdLocationOn } from "react-icons/md";
import {
  setDialog as cityDialog,
  setCityTable,
} from "../../../app/slices/provincesSlice";
import CityForm from "../../management/provinces/cities/form";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PatientForm = () => {
  const _Patient = useSelector((state) => state.patient.patient);
  const _SearchBody = useSelector((state) => state.patient.searchBody);
  const _Cities = useSelector((state) => state.provinces.cities);
  const _provinces = useSelector((state) => state.provinces.provinces);
  const _KnownUs = useSelector((state) => state.patient.knownUs);
  const _City = useSelector((state) => state.provinces.cities);

  const isAdd = _Patient.form.id ? false : true;
  const { control, reset, watch, handleSubmit, setValue } = useForm({
    defaultValues: _Patient.form,
  });
  const [isVisitedBefore, setIsVisitedBefore] = useState(null);
  const [reason, setReason] = useState("");
  const [allergy, setAllergy] = useState("");
  const [haveAllergy, setHaveAllergy] = useState(null);
  const [chronicDiseases, setChronicDiseases] = useState(null);
  const [haveMedicine, setHaveMedicine] = useState(null);
  const [medicine, setMedicine] = useState("");
  const [isBreastfeeding, setIsBreastFeeding] = useState(null);
  const [isDrinkAlcohol, setIsDrinkAlcohol] = useState(null);
  const [isPregnant, setIsPregnant] = useState(null);
  const [isSmoking, setIsSmoking] = useState(null);


  useEffect(() => {
    setIsVisitedBefore(dataConvert(_Patient.form.patient_history?.is_visited_before));
    setReason(dataConvert(_Patient.form.patient_history?.reason) ?? "");
    setAllergy(dataConvert(_Patient.form.patient_history?.allergy) ?? "");
    setHaveAllergy(dataConvert(_Patient.form.patient_history?.have_allergy));
    setChronicDiseases(dataConvert(_Patient.form.patient_history?.chronic_diseases));
    setHaveMedicine(dataConvert(_Patient.form.patient_history?.have_medicine));
    setMedicine(dataConvert(_Patient.form.patient_history?.medicine) ?? "");
    setIsBreastFeeding(dataConvert(_Patient.form.patient_history?.is_breastfeeding));
    setIsDrinkAlcohol(dataConvert(_Patient.form.patient_history?.is_drink_alcohol));
    setIsPregnant(dataConvert(_Patient.form.patient_history?.is_pregnant));
    setIsSmoking(dataConvert(_Patient.form.patient_history?.is_smoking));
  }, [_Patient.form])

  function dataConvert(value) {
    if (typeof value === 'string') {
      if (value === 'true') {
        return true;
      } else if (value === 'false' || value === '0') {
        return false;
      } else {
        return value;
      }
    } else if (typeof value === 'boolean') {
      return value;
    } else if (value === null) {
      return null;
    }
  }

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(
        Patient.create({
          first_name: data.first_name,
          full_name: data.full_name,
          gender: data.gender,
          how_know_us: data.how_know_us,
          address: data.address,
          city_id: data.city_id,
          birth_date: data.birth_date,
          mobile_1: data.mobile_1,
          mobile_2: data.mobile_2,
          note: data.note,
          province_id: data.province_id,
          is_visited_before: isVisitedBefore === true ? 1 : isVisitedBefore === false ? 0 : null,
          reason: reason ?? '',
          have_medicine: haveMedicine === true ? 1 : haveMedicine === false ? 0 : null,
          medicine: haveMedicine === false ? "" : medicine,
          have_allergy: haveAllergy === true ? 1 : haveAllergy === false ? 0 : null,
          allergy: haveAllergy === false ? "" : allergy,
          chronic_diseases: chronicDiseases ?? null,
          is_breastfeeding: isBreastfeeding === true ? 1 : isBreastfeeding === false ? 0 : null,
          is_drink_alcohol: isDrinkAlcohol === true ? 1 : isDrinkAlcohol === false ? 0 : null,
          is_pregnant: isPregnant === true ? 1 : isPregnant === false ? 0 : null,
          is_smoking: isSmoking === true ? 1 : isSmoking === false ? 0 : null,
        })
      );
    } else {
      dispatch(
        Patient.update(
          data.id,
          {
            first_name: data.first_name,
            full_name: data.full_name,
            gender: data.gender,
            how_know_us: data.how_know_us,
            address: data.address,
            city_id: data.city_id,
            birth_date: data.birth_date,
            mobile_1: data.mobile_1,
            mobile_2: data.mobile_2,
            note: data.note,
            province_id: data.province_id,
            is_visited_before: isVisitedBefore === true ? 1 : isVisitedBefore === false ? 0 : null,
            reason: reason ?? '',
            have_medicine: haveMedicine === true ? 1 : haveMedicine === false ? 0 : null,
            medicine: haveMedicine === false ? "" : medicine,
            have_allergy: haveAllergy === true ? 1 : haveAllergy === false ? 0 : null,
            allergy: haveAllergy === false ? "" : allergy,
            chronic_diseases: chronicDiseases ?? null,
            is_breastfeeding: isBreastfeeding === true ? 1 : isBreastfeeding === false ? 0 : null,
            is_drink_alcohol: isDrinkAlcohol === true ? 1 : isDrinkAlcohol === false ? 0 : null,
            is_pregnant: isPregnant === true ? 1 : isPregnant === false ? 0 : null,
            is_smoking: isSmoking === true ? 1 : isSmoking === false ? 0 : null,
          },
          _SearchBody
        )
      );
    }
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Patient.KnownUs());
  }, [dispatch]);

  useEffect(() => {
    reset({ ..._Patient.form });
  }, [reset, _Patient.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, [dispatch]);

  const handleDialog = () => {
    dispatch(setDialog());
  };

  const gender = [
    { id: "ذكر", name: `ذكر` },
    { id: "انثى", name: `انثى` },
  ];

  useEffect(() => {
    const selectedCities = _provinces.data?.filter((x) =>
      x.id === watch("province_id") ? x.city : null
    )[0];
    dispatch(setCityTable(selectedCities?.city));
  }, [dispatch, _provinces.data, watch]);

  return (
    <Dialog
      open={_Patient.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="lg"
    >
      {_City.dialog ? <CityForm provinceId={watch("province_id")} /> : null}
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : "تعديل "}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Controller
                name="first_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="الاسم الاول"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Controller
                name="full_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="الاسم الكامل"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Controller
                name="birth_date"
                control={control}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      inputFormat="YYYY-MM-DD"
                      label="تاريخ الميلاد"
                      value={field.value}
                      onChange={(e, newValue) => {
                        setValue("birth_date", dayjs(e).format("YYYY-MM-DD"));
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          fullWidth
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "يوم/شهر/سنة",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Controller
                name="gender"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    options={gender}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    size="small"
                    renderInput={(params) => (
                      <TextField required {...params} label="الجنس" />
                    )}
                    onChange={(event, value) => {
                      setValue("gender", value ? value.id : 0);
                    }}
                    value={
                      gender.filter((x) => x.id === field.value)[0]
                        ? gender.filter((x) => x.id === field.value)[0]
                        : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={4.5}>
              <Controller
                name="province_id"
                control={control}
                render={({ field }) => (
                  <SelectProvince
                    required
                    povinceid={field.value}
                    onChange={(e, newValue) => {
                      setValue("province_id", newValue ? newValue.id : null);
                      setValue("city_id", null);
                    }}
                    value={
                      _provinces.data?.filter((x) => x.id === field.value)[0]
                        ? _provinces.data?.filter(
                          (x) => x.id === field.value
                        )[0]
                        : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={4.5}>
              <Controller
                name="city_id"
                control={control}
                render={({ field }) => (
                  <SelectCity
                    {...field}
                    required
                    onChange={(e, newValue) => {
                      setValue("city_id", newValue ? newValue.id : null);
                    }}
                    value={
                      _Cities.data?.filter((x) => x.id === field.value)[0]
                        ? _Cities.data?.filter((x) => x.id === field.value)[0]
                        : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Controller
                name="add_city"
                control={control}
                render={({ field }) => (
                  <Button
                    disabled={watch("province_id") ? false : true}
                    variant="contained"
                    color="primary"
                    size="medium"
                    startIcon={<MdLocationOn />}
                    onClick={() => dispatch(cityDialog())}
                    fullWidth
                  >
                    أضافة مدينة جديدة
                  </Button>
                )}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Controller
                name="address"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="اقرب نقطة دالة"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Controller
                name="mobile_1"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="رقم الهاتف الاول"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Controller
                name="mobile_2"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="رقم الهاتف الثاني"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Controller
                name="how_know_us"
                control={control}
                render={({ field }) => (
                  <SelectKnownUs
                    onChange={(e, newValue) => {
                      setValue("how_know_us", newValue ? newValue : null);
                    }}
                    value={
                      _KnownUs?.filter((x) => x === field.value)[0]
                        ? _KnownUs?.filter((x) => x === field.value)[0]
                        : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <TextField
                    multiline
                    rows={3}
                    size="small"
                    label="الملاحظات"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <Grid container spacing={2} alignItems={"center"}>
                  <Grid item xs={3.5}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      <span>هل لديك زيارات سابقة لطبيب الاسنان اخر؟</span>
                    </FormLabel>
                  </Grid>
                  <Grid item xs={8.5}>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      onChange={(e) => {
                        setIsVisitedBefore(dataConvert(e.target.value));
                      }}
                      value={isVisitedBefore}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label={"نعم"}
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="كلا"
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <Grid container spacing={2} alignContent={"center"}>
                  <Grid item xs={3.5}>
                    <FormLabel>
                      <span>ما هو سبب قدومك للعيادة</span>
                    </FormLabel>
                  </Grid>
                  <Grid item xs={8.5}>
                    <TextField size="small" label="السبب" fullWidth value={reason} onChange={(e) => setReason(e.target.value)} />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <Grid container spacing={2} alignContent={"center"}>
                  <Grid item xs={3.5}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      <span>هل لديك ادوية معينة ؟</span>
                    </FormLabel>
                  </Grid>
                  <Grid item xs={3}>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      onChange={(e) => setHaveMedicine(dataConvert(e.target.value))}
                      value={haveMedicine}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label={"نعم"}
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="كلا"
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={5.5}>
                    <TextField
                      size="small"
                      label=""
                      fullWidth
                      disabled={
                        haveMedicine === false ? true : false
                      }
                      value={medicine}
                      onChange={(e) => setMedicine(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <Grid container spacing={2} alignContent={"center"}>
                  <Grid item xs={3.5}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      <span>هل لديك تحسس تجاه دواء معين او مادة معينة ؟</span>
                    </FormLabel>
                  </Grid>
                  <Grid item xs={3}>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      onChange={(e) => setHaveAllergy(dataConvert(e.target.value))}
                      value={haveAllergy}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label={"نعم"}
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="كلا"
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={5.5}>
                    <TextField
                      size="small"
                      label=""
                      fullWidth
                      disabled={
                        haveAllergy === false ? true : false
                      }
                      value={allergy}
                      onChange={(e) => setAllergy(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <Grid container spacing={2} alignContent={"center"}>
                  <Grid item xs={3.5}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      <span>هل لديك امراض مزمنة ؟</span>
                    </FormLabel>
                  </Grid>
                  <Grid item xs={8.5}>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      onChange={(e) => setChronicDiseases(dataConvert(e.target.value))}
                      value={chronicDiseases}
                    >
                      <FormControlLabel
                        value="neurological"
                        control={<Radio />}
                        label={"عصبية"}
                      />
                      <FormControlLabel
                        value="heart"
                        control={<Radio />}
                        label="قلبية"
                      />
                      <FormControlLabel
                        value="respiratory"
                        control={<Radio />}
                        label="تنفسية"
                      />
                      <FormControlLabel
                        value="digestive"
                        control={<Radio />}
                        label="هضمية"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="كلا"
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <Grid container spacing={2} alignContent={"center"}>
                  <Grid item xs={3.5}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      <span>هل تدخن ؟</span>
                    </FormLabel>
                  </Grid>
                  <Grid item xs={8.5}>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      onChange={(e) => setIsSmoking(dataConvert(e.target.value))}
                      value={isSmoking}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label={"نعم"}
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="كلا"
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <Grid container spacing={2} alignContent={"center"}>
                  <Grid item xs={3.5}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      <span>هل تتناول الكحول ؟</span>
                    </FormLabel>
                  </Grid>
                  <Grid item xs={8.5}>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      onChange={(e) => setIsDrinkAlcohol(dataConvert(e.target.value))}
                      value={isDrinkAlcohol}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label={"نعم"}
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="كلا"
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <Grid container spacing={2} alignContent={"center"}>
                  <Grid item xs={3.5}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      <span>هل انتِ حامل ؟</span>
                    </FormLabel>
                  </Grid>
                  <Grid item xs={8.5}>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      onChange={(e) => setIsPregnant(dataConvert(e.target.value))}
                      value={isPregnant}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label={"نعم"}
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="كلا"
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <Grid container spacing={2} alignContent={"center"}>
                  <Grid item xs={3.5}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      <span>هل انتِ مرضع ؟</span>
                    </FormLabel>
                  </Grid>
                  <Grid item xs={8.5}>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      onChange={(e) => setIsBreastFeeding(dataConvert(e.target.value))}
                      value={isBreastfeeding}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label={"نعم"}
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="كلا"
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default PatientForm;
