import { Card, CardHeader, Typography, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { setDialog } from "../../../../../app/slices/doctorExpensesSlice";
import { GiPayMoney } from "react-icons/gi";
import DoctorsExpensesForm from "../../form";

const DoctorsExpensesHeader = () => {
  const _DoctorsExpenses = useSelector(
    (state) => state.doctorExpense.doctorExpense.dialog
  );
  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            مصاريف الاطباء
          </Typography>
        }
        avatar={<GiPayMoney size={20} />}
        action={
          <Button
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<IoIosAddCircleOutline />}
            onClick={() => dispatch(setDialog())}
          >
            إضافة
          </Button>
        }
      />
      {_DoctorsExpenses && <DoctorsExpensesForm />}
    </Card>
  );
};

export default DoctorsExpensesHeader;
