import { Card, CardHeader, Typography } from "@mui/material";
import React from "react";
import { MdLocationOn } from "react-icons/md";

const AddressHeader = () => {
  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            المحافظات
          </Typography>
        }
        avatar={<MdLocationOn size={20} />}
      />
    </Card>
  );
};

export default AddressHeader;
