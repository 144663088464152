import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { arEG } from "@mui/material/locale";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import "./App.css";
import NotificationSnackbar from "../src/components/Notification";

// routing
import Routes from "./routes";

// defaultTheme

// project imports
import NavigationScroll from "./layout/NavigationScroll";
import themeTypography from "./themes/typography";
import { useSelector } from "react-redux";
import { useEffect } from "react";

// ==============================|| APP ||============================== //

const App = () => {
  const userInfo = useSelector((state) => state.user.userInfo);

  const border_shadow = {
    borderRadius: 10,
    boxShadow:
      "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px",
  };

  function isValidColor(colorString) {
    return /^#([0-9A-F]{3}){1,2}$/i.test(colorString);
  }

  const theme = createTheme(
    {
      components: {
        MuiTypography: {
          defaultProps: {
            fontFamily: "Cairo",
          },
        },
        // Name of the component
        MuiCard: {
          styleOverrides: {
            root: {
              borderRadius: border_shadow.borderRadius,
              boxShadow: border_shadow.boxShadow,
            },
          },
        },
        MuiDataGrid: {
          styleOverrides: {
            root: {
              borderRadius: border_shadow.borderRadius,
              boxShadow: border_shadow.boxShadow,
            },
          },
        },
        // MuiListItemText: {
        //   styleOverrides: {
        //     primary: {
        //       // color: 'b'
        //     },
        //     "&:hover": {
        //       backgroundColor: "blue",
        //       color: "green"
        //     }
        //   }
        // },
      },
      palette: {
        type: "light",
        primary: {
          main: isValidColor(userInfo.tenant.main_color)
            ? userInfo.tenant.main_color
            : "#092148",
          contrastText: "#ffffff",
          light: "#F8F8F8",
        },
        secondary: {
          main: isValidColor(userInfo.tenant.secondary_color)
            ? userInfo.tenant.secondary_color
            : "#092148",
          contrastText: "#ffffff",
        },
      },
      typography: themeTypography,
      direction: "rtl",
    },
    arEG
  );
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin],
  });

  useEffect(() => {
    const bodyChildren = document.querySelectorAll("body > div");
    bodyChildren.forEach((div) => {
      if (div.querySelector("img")) {
        div.style.display = "none";
      }
    });
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <NotificationSnackbar />
          <NavigationScroll>
            <Routes />
          </NavigationScroll>
        </ThemeProvider>
      </CacheProvider>
    </StyledEngineProvider>
  );
};

export default App;
