import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Tooltip,
  useMediaQuery,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiTrash, BiEdit } from "react-icons/bi";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationDialog from "../../../../../helpers/ConfirmationDialog";
import { MdOutlineClear } from "react-icons/md";
import { Clearance, setSearchBody } from "../../../../../app/slices/clearance";
import DateRangepicker from "../../../../../helpers/dateRangePicker";
import dayjs from "dayjs";
import ClearanceDetails from "../detailsDailog";
import { useTheme } from "@emotion/react";
import { HiMenu } from "react-icons/hi";

const ClearanceTable = () => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("lg"));

  const dispatch = useDispatch();

  const _Clearance = useSelector((state) => state.clearance.clearances);
  const _SearchBody = useSelector((state) => state.clearance.searchBody);
  const loading = useSelector((state) => state.clearance.loading);

  const [search, setSearch] = useState({
    search: "",
    start_date: dayjs().format("YYYY-MM-01"),
    end_date: dayjs().format("YYYY-MM-DD"),
  });
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");
  const [activeItem, setActiveItem] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);
  const handleMenuClick = (event, item) => {
    setOpenMenu(event.currentTarget);
    setActiveItem(item);
  };

  const handleClose = () => {
    setActiveItem(null);
    setOpenMenu(null);
  };

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(Clearance.deleteById(dialogData.id, _SearchBody));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const columns = [
    {
      field: "id",
      headerName: "رقم الوصل",
      flex: 0.7,
    },
    {
      field: "user_name",
      headerName: "اسم الطبيب",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "المبلغ IQD",
      flex: 1,
      renderCell: (params) => <>{params.row.amount?.toLocaleString()}</>,
    },
    {
      field: "percentage",
      headerName: "النسبة",
      flex: 0.7,
      renderCell: (params) => <>{params.row.percentage?.toLocaleString()}</>,
    },
    {
      field: "total",
      headerName: "المبلغ الكلي IQD",
      flex: 1,
      renderCell: (params) => <>{params.row.total?.toLocaleString()}</>,
    },
    {
      field: "expenses_sum_amount",
      headerName: "مبلغ المصاريف IQD",
      flex: 1,
      renderCell: (params) => (
        <>{params.row.expenses_sum_amount?.toLocaleString()}</>
      ),
    },
    {
      field: "payments_sum_amount",
      headerName: "مبلغ المدفوعات IQD",
      flex: 1,
      renderCell: (params) => (
        <>{params.row.payments_sum_amount?.toLocaleString()}</>
      ),
    },
    {
      field: "date",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) => (
        <>{dayjs(params.row.date).format("YYYY-MM-DD")}</>
      ),
    },
    // {
    //   field: "note",
    //   headerName: "الملاحظة",
    //   flex: 1,
    // },
    {
      field: "operations",
      headerName: "العمليات",
      flex: matchUpMd ? 1 : 0.7,
      renderCell: (params) => {
        return matchUpMd ? (
          <>
            <IconButton
              color="primary"
              onClick={() => dispatch(Clearance.getById(params.row.id))}
            >
              <BiEdit />
            </IconButton>

            <IconButton
              color="error"
              onClick={() => handleDeleteClick(params.row)}
            >
              <BiTrash />
            </IconButton>
          </>
        ) : (
          <IconButton
            onClick={(e) => handleMenuClick(e, params)}
            aria-controls={openMenu ? params.row.id : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? "true" : undefined}
          >
            <HiMenu />
          </IconButton>
        );
      },
    },
  ];

  const operationMenu = (params) => (
    <Menu
      sx={{ direction: "ltr" }}
      anchorEl={openMenu}
      keepMountede
      id="account-menu"
      open={activeItem === params}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            left: "50%",
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem
        onClick={() => {
          dispatch(Clearance.getById(params.row.id));
        }}
        color="success"
      >
        <ListItemIcon>
          <BiEdit size={20} color="#0094D0" />
        </ListItemIcon>
        تعديل
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleDeleteClick(params.row);
        }}
      >
        <ListItemIcon>
          <BiTrash size={20} color="#D32F2F" />
        </ListItemIcon>
        حذف
      </MenuItem>
    </Menu>
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(Clearance.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [dispatch, _SearchBody]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        search: search.search,
        start_date: search.start_date,
        end_date: search.end_date,
        skip: pageSize * currentPage,
        take: pageSize,
      })
    );
  }, [dispatch, search, pageSize, currentPage]);

  return (
    <Card>
      <CardContent>
        <Box>
          {<ClearanceDetails />}
          {activeItem && operationMenu(activeItem)}
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف الوصل المرقم"
            name={dialogData.id}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              size="small"
              onChange={(e) => {
                setSearch({
                  ...search,
                  search: e.target.value,
                });
              }}
              value={search.search}
              label="بحث عن الاسم "
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DateRangepicker
              startDate={search.start_date}
              endDate={search.end_date}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.startDate ||
                  updatedFilter.to !== searchDate.endDate
                ) {
                  setSearch({
                    ...search,
                    start_date: dayjs(searchDate[0].startDate).format(
                      "YYYY-MM-DD"
                    ),
                    end_date: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="الغاء الفلتر">
              <IconButton
                onClick={() => {
                  setSearch({
                    search: "",
                    city_id: null,
                  });
                  setPageSize(15);
                  setCurrentPage(0);
                }}
              >
                <MdOutlineClear />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              {_Clearance.data ? (
                <DataGrid
                  rows={_Clearance.data}
                  columns={columns}
                  pageSize={pageSize}
                  page={currentPage}
                  rowCount={_Clearance.total}
                  loading={loading}
                  rowHeight={50}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) => setCurrentPage(newPage)}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize);
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ClearanceTable;
