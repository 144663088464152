import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Toolbar,
  Divider,
  Typography,
  Menu,
  MenuItem,
  Stack,
  Avatar,
  Tooltip,
  ListItemIcon,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import LogoutIcon from '@mui/icons-material/Logout';

import { styled, useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";
import { setDrawer } from "../../../app/slices/appSlice";
import { User } from "../../../app/slices/userSlice";
import { useNavigate } from "react-router";
import { CgProfile } from 'react-icons/cg'

const Appbar = () => {
  const navigate = useNavigate();

  const drawerWidth = 210;
  const theme = useTheme();

  const leftDrawerOpened = useSelector((state) => state.app.is_drawer_opened);
  const dispatch = useDispatch();

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: drawerWidth,
    }),
  }));

  useEffect(() => {
    dispatch(User.userInfo())
  }, [dispatch])

  const userInfo = useSelector((state) => state.user.userInfo);

  const [open, setOpen] = useState(false);
  const anchorEl = useRef();
  return (
    <Box>
      <AppBar
        position="fixed"
        elevation={1}
        open={JSON.parse(localStorage.getItem("sideBarToggle"))}
        sx={{
          bgcolor: theme.palette.primary.main,
          minHeight: "65px",
          height: "25px",
        }}
      >
        <Toolbar >
          <IconButton
            onClick={() =>{ 
              dispatch(setDrawer());
            }}
            color="inherit"
            aria-label="open drawer"
            edge="start"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h2" component="div" sx={{ flexGrow: 1 }}>
          {userInfo.tenant.title}
          </Typography>
          {/* <ProfileMenu /> */}

          <Box>
            {userInfo && (
              <Stack direction='row' spacing={1} alignItems="center">
                <Tooltip title="الخيارات">
                  <IconButton
                    ref={anchorEl}
                    onClick={() => setOpen(true)}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                  >
                    <Avatar sx={{ width: 45, height: 45 }}>{'M'}</Avatar>
                  </IconButton>
                </Tooltip>
                <Stack direction='column' spacing={0} alignItems="center">
                  <Box>
                    <Typography>{userInfo.user_name}</Typography>
                    <Typography variant="caption">{userInfo.email}</Typography>
                  </Box>
                </Stack>
              </Stack>
            )}
            <Menu
              sx={{ direction: 'ltr' }}
              anchorEl={() => anchorEl.current}
              keepMounted
              id="account-menu"
              open={open}
              onClose={() => setOpen(false)}
              onClick={() => setOpen(false)}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    left: 33,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem
                onClick={() => {
                  navigate('/app/user/Profile')
                }}>
                <ListItemIcon>
                  <CgProfile size={25} />
                </ListItemIcon>
                الصفحة الشخصية
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => {
                  localStorage.removeItem('token')
                  localStorage.removeItem('role')
                  navigate('/login')
                }}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                تسجيل خروج
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Appbar;
