import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  payments(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach(key => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/reports/payments?${params}`);
  },
  spending(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach(key => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/reports/spending?${params}`);
  },
  operation(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach(key => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/reports/operation?${params}`);
  },
  xray(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach(key => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/reports/xray?${params}`);
  },
  deleted(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach(key => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/reports/deleted?${params}`);
  },
}
