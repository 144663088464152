import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Draggable } from "react-beautiful-dnd";
import {
  Box,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { BiTimeFive, BiTrash } from "react-icons/bi";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import {
  setTimesData,
  setTimesDialog,
  waitingList,
} from "../../../../app/slices/waitingListSlice";
import ConfirmationDialog from "../../../../helpers/ConfirmationDialog";
import { useSelector } from "react-redux";

const Container = styled("div")`
  border: 1px solid lightgrey;
  margin-bottom: 8px;
  border-radius: 2px;
  padding: 8px;
  background: ${(props) => (props.isDragging ? "lightgreen" : "white")};
`;

var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const Patient = ({ patient, index }) => {
  const dispatch = useDispatch();

  const _SearchBody = useSelector((state) => state.waitingList.searchBody);
  const _Role = useSelector((state) => state.user.userInfo.role);

  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");
  const [WaitingTimeDifference, setWaitingTimeDifference] = useState("");

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    const match = dialogData.patient_id.match(/\d+/);
    dispatch(waitingList.deleteById(parseInt(match[0], 10), _SearchBody)).then(
      () => {
        setConfirmOpen(false);
      }
    );
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  useEffect(() => {
    const calculateTimeDifference = () => {
      const timeDifference = dayjs(patient?.created_at).toNow();
      setWaitingTimeDifference(timeDifference);
    };

    calculateTimeDifference();

    const interval = setInterval(calculateTimeDifference, 10 * 1000); // 10 seconds

    return () => clearInterval(interval);
  }, [patient]);

  return (
    <Box>
      <ConfirmationDialog
        open={openConfirm}
        title="تاكيد"
        message="هل انت متاكد من حذف"
        name={dialogData.patient_name}
        confirmText="حذف"
        cancelText="الغاء"
        onConfirm={() => handleConfirmDelete()}
        onCancel={handleCancelDelete}
      />
      <Draggable draggableId={patient?.patient_id} index={index} type="patient">
        {(provided, snapshot) => (
          <Container
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
            isDragging={snapshot.isDragging}
          >
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography>
                {index + 1} - {patient?.patient_name}
              </Typography>
              <Typography variant="caption">
                {dayjs(patient?.created_at).format("YYYY-MM-DD hh:mm a")}
              </Typography>
              <Typography variant="caption">{WaitingTimeDifference}</Typography>
              <Divider sx={{ paddingX: 8 }} />
              <Stack direction="row">
                <Tooltip title="الاوقات">
                  <IconButton
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      dispatch(setTimesData(patient));
                      dispatch(setTimesDialog());
                    }}
                  >
                    <BiTimeFive />
                  </IconButton>
                </Tooltip>
                {_Role === "super_admin" && (
                  <Tooltip title="حذف">
                    <IconButton
                      variant="contained"
                      color="error"
                      onClick={() => handleDeleteClick(patient)}
                    >
                      <BiTrash />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            </Stack>
          </Container>
        )}
      </Draggable>
    </Box>
  );
};

export default Patient;
