import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getAll() {
        return api.get(`/api/v1/provinces`);
    },
    getById(id) {
        return api.get(`/api/v1/city/${id}`);
    },
    update(id, data) {
        return api.put(`/api/v1/city/${id}`, data);
    },
    create(data) {
        return api.post(`/api/v1/city`, data);
    },
    delete(id) {
        return api.delete(`/api/v1/city/${id}`);
    },
}