import {
  Grid,
  IconButton,
  LinearProgress,
  Card,
  CardContent,
  Tooltip,
  Typography,
  Divider,
  Chip,
} from "@mui/material";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Reports, setSearchBody } from "../../../../../app/slices/reportsSlice";
import DateRangepicker from "../../../../../helpers/dateRangePicker";
import { MdOutlineClear } from "react-icons/md";

const DeletedTable = () => {
  const [search, setSearch] = useState({
    start_date: dayjs().format("YYYY-MM-01"),
    end_date: dayjs().format("YYYY-MM-DD"),
  });
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);

  const dispatch = useDispatch();

  const _ReportsOperation = useSelector(
    (state) => state.reports.deleted.operation
  );
  const _ReportsPayment = useSelector((state) => state.reports.deleted.payment);
  const _ReportsPatient = useSelector((state) => state.reports.deleted.patient);
  const loading = useSelector((state) => state.reports.loading);
  const _SearchBody = useSelector((state) => state.reports.searchBody);
  const reportTypes = useSelector((state) => state.reports.reportTypes);
  const userInfo = useSelector((state) => state.user.userInfo);

  const columnsOperation = [
    {
      field: "id",
      headerName: "رقم الجلسة",
      flex: 1,
    },
    {
      field: "operation_type_name",
      headerName: "نوع الجلسة",
      flex: 1,
    },
    {
      field: "patient_name",
      headerName: "اسم المراجع",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "المبلغ (IQD)",
      flex: 1,
      renderCell: (params) => parseInt(params.row.amount)?.toLocaleString(),
    },
    {
      field: "paid",
      headerName: "المبلغ المدفوع (IQD)",
      flex: 1,
      renderCell: (params) => parseInt(params.row.paid)?.toLocaleString(),
    },
    {
      field: "user",
      headerName: "اسم المستخدم",
      flex: 1,
    },
    {
      field: "date",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) => dayjs(params.row.date).format("YYYY-MM-DD"),
    },
    {
      field: "deleted_by_name",
      headerName: "حذف بواسطة",
      flex: 1,
    },
    {
      field: "deleted_at",
      headerName: "تاريخ الحذف",
      flex: 1,
      renderCell: (params) => dayjs(params.row.deleted_at).format("YYYY-MM-DD"),
    },
  ];

  const columnsPayment = [
    {
      field: "id",
      headerName: "رقم العملية",
      flex: 1,
    },
    {
      field: "patient_name",
      headerName: "اسم المراجع",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "المبلغ (IQD)",
      flex: 1,
      renderCell: (params) => parseInt(params.row.amount)?.toLocaleString(),
    },
    {
      field: "date",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) => dayjs(params.row.date).format("YYYY-MM-DD"),
    },
    {
      field: "deleted_at",
      headerName: "تاريخ الحذف",
      flex: 1,
      renderCell: (params) => dayjs(params.row.deleted_at).format("YYYY-MM-DD"),
    },
    {
      field: "deleted_by_name",
      headerName: "حذف بواسطة",
      flex: 1,
    },
  ];

  const columnsPatient = [
    {
      field: "full_name",
      headerName: "اسم المراجع",
      flex: 1,
    },
    {
      field: "birth_date",
      headerName: "تاريخ الميلاد",
      flex: 1,
      renderCell: (params) => dayjs(params.row.birth_date).format("YYYY-MM-DD"),
    },
    {
      field: "age",
      headerName: "العمر",
      flex: 1,
    },
    {
      field: "address",
      headerName: "العنوان",
      flex: 1,
    },
    {
      field: "deleted_by_name",
      headerName: "حذف بواسطة",
      flex: 1,
    },
    {
      field: "deleted_at",
      headerName: "تاريخ الحذف",
      flex: 1,
      renderCell: (params) => dayjs(params.row.deleted_at).format("YYYY-MM-DD"),
    },
  ];
  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(Reports.deleted(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [dispatch, _SearchBody]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        start_date: search.start_date,
        end_date: search.end_date,
      })
    );
  }, [dispatch, search, reportTypes]);

  const customToolbarOperation = () => (
    <GridToolbarContainer>
      <GridToolbarExport
        csvOptions={{
          fileName: `${userInfo.tenant.title} | تقرير الجلسات المحذوفة`,
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  );

  const customToolbarPayment = () => (
    <GridToolbarContainer>
      <GridToolbarExport
        csvOptions={{
          fileName: `${userInfo.tenant.title} | تقرير المدفوعات المحذوفة`,
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  );

  const customToolbarPatient = () => (
    <GridToolbarContainer>
      <GridToolbarExport
        csvOptions={{
          fileName: `${userInfo.tenant.title} | تقرير المراجعين المحذوفين`,
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  );

  return (
    <Card>
      <CardContent>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} md={4}>
            <DateRangepicker
              startDate={search.start_date}
              endDate={search.end_date}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.startDate ||
                  updatedFilter.to !== searchDate.endDate
                ) {
                  setSearch({
                    ...search,
                    start_date: dayjs(searchDate[0].startDate).format(
                      "YYYY-MM-DD"
                    ),
                    end_date: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="الغاء الفلتر">
              <IconButton
                onClick={() => {
                  setSearch({
                    search: "",
                    start_date: dayjs().format("YYYY-MM-01"),
                    end_date: dayjs().format("YYYY-MM-DD"),
                  });
                  // setPageSize(15)
                  // setCurrentPage(0)
                }}
              >
                <MdOutlineClear />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 3 }}>
            <Divider>
              <Chip label="تقرير الجلسات المحذوفة" color="primary" />
            </Divider>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              <DataGrid
                rows={_ReportsOperation.data}
                columns={columnsOperation}
                rowCount={_ReportsOperation.operation_count}
                loading={loading}
                rowHeight={40}
                paginationMode="client"
                rowsPerPageOptions={[15, 25, 50, 100]}
                pageSize={pageSize}
                page={currentPage}
                pagination
                onPageChange={(newPage) => {
                  setCurrentPage(newPage);
                }}
                onPageSizeChange={(newPageSize) => {
                  setPageSize(newPageSize);
                }}
                components={{
                  LoadingOverlay: LinearProgress,
                  Toolbar: customToolbarOperation,
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 3 }}>
            <Divider>
              <Chip label="تقرير المدفوعات المحذوفة" color="primary" />
            </Divider>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              <DataGrid
                rows={_ReportsPayment.data}
                columns={columnsPayment}
                rowCount={_ReportsPayment.payment_count}
                loading={loading}
                rowHeight={40}
                paginationMode="client"
                rowsPerPageOptions={[15, 25, 50, 100]}
                pageSize={pageSize}
                page={currentPage}
                pagination
                onPageChange={(newPage) => {
                  setCurrentPage(newPage);
                }}
                onPageSizeChange={(newPageSize) => {
                  setPageSize(newPageSize);
                }}
                components={{
                  LoadingOverlay: LinearProgress,
                  Toolbar: customToolbarPayment,
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 3 }}>
            <Divider>
              <Chip label="تقرير المراجعين المحذوفين" color="primary" />
            </Divider>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              <DataGrid
                rows={_ReportsPatient.data}
                columns={columnsPatient}
                rowCount={_ReportsPatient.patient_count}
                loading={loading}
                rowHeight={40}
                paginationMode="client"
                rowsPerPageOptions={[15, 25, 50, 100]}
                pageSize={pageSize}
                page={currentPage}
                pagination
                onPageChange={(newPage) => {
                  setCurrentPage(newPage);
                }}
                onPageSizeChange={(newPageSize) => {
                  setPageSize(newPageSize);
                }}
                components={{
                  LoadingOverlay: LinearProgress,
                  Toolbar: customToolbarPatient,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DeletedTable;
