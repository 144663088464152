import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
const restTimeApi = factory.get("restTime");
const initialState = {
  loading: false,
  searchBody: {},
  restTime: {
    dialog: false,
    form: {
      id: null,
      user_id: null,
      start_date: null,
      end_date: null,
      note: "",
    },
  },
  restTimes: {
    data: [],
    total: 0,
  },
};

export const restTimeSlice = createSlice({
  name: "restTime",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.restTime.dialog = !state.restTime.dialog;
    },
    setDataTable: (state, { payload }) => {
      state.restTimes.data = payload.data.data;
      state.restTimes.total = payload.data.total;
    },
    setById: (state, { payload }) => {
      state.restTime.form = payload;
    },
    setLoading: (state, action) => {
      state.loading = !state.loading;
    },
    resetForm: (state, action) => {
      state.restTime.form = initialState.restTime.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
  },
});

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
} = restTimeSlice.actions;

export default restTimeSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("restTime"));
    const res = await restTimeApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("restTime"));
  } catch (err) {
    dispatch(setLoading("restTime"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await restTimeApi.delete(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("restTime"));
    const res = await restTimeApi.getById(id);
    dispatch(setById(res.data.data[0]));

    dispatch(setDialog());
    dispatch(setLoading("restTime"));
  } catch (err) {
    dispatch(setLoading("restTime"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data) => async (dispatch) => {
  try {
    await restTimeApi.create(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة  بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await restTimeApi.update(id, data);
    dispatch(
      showNotification({
        message: "تم التعديل  بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const RestTimeActions = {
  getAll,
  deleteById,
  getById,
  create,
  update,
};
