import { Grid } from "@mui/material";
import React from "react";
import TreatmentPlanHeader from "./home/header";
import TreatmentPlanTable from "./home/table";

const TreatmentPlan = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TreatmentPlanHeader />
      </Grid>
      <Grid item xs={12}>
        <TreatmentPlanTable />
      </Grid>
    </Grid>
  );
};

export default TreatmentPlan;
