import { Grid } from "@mui/material";
import React from "react";
import RestTimeHeader from "./home/header";
import RestTimeTable from "./home/table";

const RestTime = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <RestTimeHeader />
      </Grid>
      <Grid item xs={12}>
        <RestTimeTable />
      </Grid>
    </Grid>
  );
};

export default RestTime;
