import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  LinearProgress,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooth } from "../../../../app/slices/toothSlice";
import { Controller, useForm } from "react-hook-form";
import TeethChart from "../../../../components/teeth";
import {
  TreatmentPlan,
  handleToothToggle,
  resetForm,
  resetTeethGrid,
} from "../../../../app/slices/treatmentPlanSlice";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useParams } from "react-router";
import { setPatientId } from "../../../../app/slices/operationSlice";
import { useTheme } from "@emotion/react";

const TreatmentPlanForm = () => {
  const { patient_id } = useParams();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [isAllTeeth, setIsAllTeeth] = useState(false);

  const _Tooth = useSelector((state) => state.tooth.teethGrid);
  const _TreatmentPlan = useSelector(
    (state) => state.treatmentPlan.treatmentPlan
  );
  const _HandleOperationType = useSelector(
    (state) => state.treatmentPlan.handleOperationTypes
  );
  const _SearchBody = useSelector((state) => state.treatmentPlan.searchBody);
  const loading = useSelector((state) => state.treatmentPlan.loading);

  const isAdd = _TreatmentPlan.form?.id ? false : true;
  const { control, handleSubmit, setValue, reset } = useForm({
    defaultValues: _TreatmentPlan.form,
  });

  useEffect(() => {
    dispatch(setPatientId(patient_id));
  }, []);

  useEffect(() => {
    if (patient_id !== "add") {
      dispatch(TreatmentPlan.getAllOperationTypes()).then((x) => {
        dispatch(
          TreatmentPlan.getAll({
            patient_id: patient_id,
            skip: 0,
            take: 1,
          })
        );
      });
    } else {
      dispatch(TreatmentPlan.getAllOperationTypes());
      dispatch(resetForm());
    }
  }, [patient_id]);

  useEffect(() => {
    dispatch(Tooth.getAll());
  }, []);

  useEffect(() => {
    reset({ ..._TreatmentPlan.form });
  }, [reset, _TreatmentPlan.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetTeethGrid());
    };
  }, [dispatch]);

  useEffect(() => {
    setValue(
      "operations",
      _HandleOperationType.filter((instance) => instance.teeth.length > 0)
    );
  }, [_HandleOperationType]);

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(
        TreatmentPlan.create(
          {
            patient_id: patient_id,
            date: data.date,
            note: data.note,
            is_need_opg: data.is_need_opg,
            is_need_cbc: data.is_need_cbc,
            is_need_pa: data.is_need_pa,
            is_need_bitewing: data.is_need_bitewing,
            operations: data.operations,
          },
          _SearchBody
        )
      ).then((x) => {
        // dispatch(TreatmentPlan.getAllOperationTypes());
        // dispatch(resetForm());
      });
    } else {
      dispatch(
        TreatmentPlan.update(
          data.id,
          {
            patient_id: data.patient_id,
            date: data.date,
            note: data.note,
            is_need_opg: data.is_need_opg,
            is_need_cbc: data.is_need_cbc,
            is_need_pa: data.is_need_pa,
            is_need_bitewing: data.is_need_bitewing,
            operations: data.operations,
          },
          _SearchBody
        )
      ).then((x) => {
        // dispatch(TreatmentPlan.getAllOperationTypes());
        // dispatch(resetForm());
      });
    }
  };

  return (
    <Card>
      {loading && <LinearProgress />}
      <Box sx={{ width: "100%", padding: 2, marginBottom: 10 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={3}>
              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      inputFormat="YYYY-MM-DD"
                      label="التاريخ"
                      value={field.value}
                      onChange={(e, newValue) => {
                        setValue("date", dayjs(e).format("YYYY-MM-DD"));
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          size="small"
                          fullWidth
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "يوم/شهر/سنة",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Button
                variant="contained"
                onClick={() => {
                  dispatch(TreatmentPlan.getAllOperationTypes());
                  dispatch(resetForm());
                }}
              >
                تفريغ الحقول
              </Button>
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <TextField
                    multiline
                    rows={3}
                    size="small"
                    label="ملاحظات"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={3} md={3}>
              <Controller
                name="is_need_opg"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value}
                        onChange={(e) =>
                          setValue("is_need_opg", e.target.checked)
                        }
                      />
                    }
                    label="OPG"
                  />
                )}
              />
            </Grid>
            <Grid item xs={3} md={3}>
              <Controller
                name="is_need_cbc"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value}
                        onChange={(e) =>
                          setValue("is_need_cbc", e.target.checked)
                        }
                      />
                    }
                    label="CBCT"
                  />
                )}
              />
            </Grid>
            <Grid item xs={3} md={3}>
              <Controller
                name="is_need_bitewing"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value}
                        onChange={(e) =>
                          setValue("is_need_bitewing", e.target.checked)
                        }
                      />
                    }
                    label="Bitewing"
                  />
                )}
              />
            </Grid>
            <Grid item xs={3} md={3}>
              <Controller
                name="is_need_pa"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value}
                        onChange={(e) =>
                          setValue("is_need_pa", e.target.checked)
                        }
                      />
                    }
                    label="P.A"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <div style={{ overflow: "auto" }}>
              {_HandleOperationType.map((instance, index) =>
                instance.is_treatment_plan ? (
                  instance.is_all_teeth ? (
                    <Grid item xs={4} md={4} key={index} padding={5}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              instance.teeth.length === 32 ? true : false
                            }
                            onChange={(e) => {
                              const newValue = e.target.checked;
                              setIsAllTeeth((prevIsAllTeeth) => ({
                                ...prevIsAllTeeth,
                                [instance.id]: newValue,
                              }));

                              const newToothIds = newValue
                                ? instance.teeth
                                : [];

                              dispatch(
                                handleToothToggle({
                                  instanceId: instance.id,
                                  isAllTeethChecked: newValue,
                                  isAllTeeth: true,
                                  teeth: newToothIds,
                                  allTeeth: _Tooth.map((tooth) => tooth.id),
                                })
                              );
                            }}
                          />
                        }
                        label={instance.operation_type_name}
                      />
                      <Divider sx={{ marginTop: 2 }} />
                    </Grid>
                  ) : (
                    <Grid item xs={12} key={index} padding={3}>
                      <Stack
                        direction={isSmallScreen ? "column" : "row"}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography sx={{ width: "15vh", padding: 2 }}>
                          {instance.operation_type_name}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <TeethChart
                            key={instance.id}
                            _ToothIds={instance.teeth}
                            _DoneToothIds={instance.done_teeth}
                            onToothToggle={(toothId) => {
                              setIsAllTeeth((prevIsAllTeeth) => ({
                                ...prevIsAllTeeth,
                                [instance.id]: false,
                              }));
                              dispatch(
                                handleToothToggle({
                                  toothId: toothId,
                                  instanceId: instance.id,
                                  isAllTeeth: false,
                                })
                              );
                            }}
                          />
                        </Box>
                      </Stack>
                      <Divider sx={{ marginTop: 2 }} />
                    </Grid>
                  )
                ) : null
              )}
            </div>
            <Grid item xs={12} md={12}></Grid>
            <Grid item xs={12} md={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Card>
  );
};

export default TreatmentPlanForm;
