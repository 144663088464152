import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TreatmentPlanForm from "./treatmentPlan";
import { SelectPatient } from "../../../components/Selectors/SelectPatient";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Patient } from "../../../app/slices/patientSlice";
import Operation from "./operation";
import { setPatientId } from "../../../app/slices/operationSlice";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { IoIosAddCircleOutline } from "react-icons/io";
import { setDialog } from "../../../app/slices/appointmentSlice";
import AppointmentForm from "../../appointment/form";
import PatientXrayTable from "./xRay";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TreatmentPlanTabs = () => {
  const dispatch = useDispatch();
  const { patient_id } = useParams();

  const _Appointment = useSelector(
    (state) => state.appointment.appointment.dialog
  );
  const _Patient = useSelector((state) => state.patient.patient.form);
  const _PatientId = useSelector(
    (state) => state.operation.patientId ?? patient_id
  );

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(Patient.getByIdForData(patient_id));
  }, [dispatch, patient_id]);

  function calculateYearsFromDate(dateString) {
    const date = new Date(dateString);
    const currentDate = new Date();
    const diffInMilliseconds = currentDate - date;
    const yearsDifference =
      new Date(diffInMilliseconds).getUTCFullYear() - 1970;
    return yearsDifference;
  }

  function translateChronicDiseases(data) {
    let translatedDisease = "";

    switch (data) {
      case "neurological":
        translatedDisease = "عصبية";
        break;
      case "heart":
        translatedDisease = "قلبية";
        break;
      case "respiratory":
        translatedDisease = "تنفسية";
        break;
      case "digestive":
        translatedDisease = "هضمية";
        break;
      case "0":
        translatedDisease = "كلا";
        break;
      case null:
        translatedDisease = "لا يوجد";
        break;
      case undefined:
        translatedDisease = "لا يوجد";
        break;
      default:
        translatedDisease = "undefined";
        break;
    }

    return translatedDisease;
  }

  function translateHistory(data) {
    let newData = "";

    switch (data) {
      case true:
        newData = "نعم";
        break;
      case false:
        newData = "كلا";
        break;
      case null:
        newData = "لا يوجد";
        break;
      case undefined:
        newData = "لا يوجد";
        break;
      default:
        newData = "undefined";
        break;
    }

    return newData;
  }

  return (
    <Box>
      {_Appointment && (
        <AppointmentForm patientId={patient_id} noLoading={true} />
      )}
      <Card sx={{ padding: 2 }}>
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={3}>
            <Typography>
              <strong>اسم المراجع</strong>: {_Patient.full_name}
            </Typography>
          </Grid>
          <Grid item xs={3} md={6}>
            <Typography>
              <strong>رقم الهاتف</strong>: {_Patient.mobile_1}
              {_Patient.mobile_2 && `- ${_Patient.mobile_2}`}
            </Typography>
          </Grid>
          <Grid item xs={3} display={"flex"} justifyContent={"end"}>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<IoIosAddCircleOutline />}
              onClick={() => dispatch(setDialog())}
            >
              إضافة موعد
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography>
              <strong>العنوان</strong>:{" "}
              {`${_Patient.province_name} - ${_Patient.city}`}
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Typography>
              <strong>العمر</strong>:{" "}
              {calculateYearsFromDate(_Patient.birth_date)} سنة
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography>
              <strong>زيارات سابقة</strong>:{" "}
              {translateHistory(_Patient.patient_history?.is_visited_before)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>
              <strong>سبب الزيارة</strong>:{" "}
              {_Patient.patient_history?.reason ? _Patient.patient_history?.reason : "لا يوجد"}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>
              <strong>الادوية</strong>:{" "}
              {translateHistory(_Patient.patient_history?.have_medicine)}
              <span style={{ margin: '0 10px' }}> {_Patient.patient_history?.have_medicine ? `( ${_Patient.patient_history?.medicine} )` : null}</span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>
              <strong>الحساسية</strong>:{" "}
              {translateHistory(_Patient.patient_history?.have_allergy)}
              <span style={{ margin: '0 10px' }}> {_Patient.patient_history?.have_allergy ? `( ${_Patient.patient_history?.allergy} )` : null}</span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>
              <strong>الامراض المزمنة</strong>:{" "}
              {translateChronicDiseases(_Patient.patient_history?.chronic_diseases)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>
              <strong>التدخين</strong>:{" "}
              {translateHistory(_Patient.patient_history?.is_smoking)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>
              <strong>الكحول</strong>:{" "}
              {translateHistory(_Patient.patient_history?.is_drink_alcohol)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>
              <strong>هل المريضة حامل</strong>:{" "}
              {translateHistory(_Patient.patient_history?.is_pregnant)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>
              <strong>هل المريضة مرضع</strong>:{" "}
              {translateHistory(_Patient.patient_history?.is_breastfeeding)}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Card sx={{ mt: 2 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs"
            variant="fullWidth"
          >
            <Tab label="خطة العلاج" {...a11yProps(0)} />
            <Tab label="الجلسات" {...a11yProps(1)} />
            <Tab label="الاشعة" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <TreatmentPlanForm patientId={_PatientId} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Operation />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <PatientXrayTable />
        </TabPanel>
      </Card>
    </Box>
  );
};

export default TreatmentPlanTabs;
