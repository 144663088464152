import { forwardRef, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Box,
  FormControlLabel,
  Divider,
  Checkbox,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Stack,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  TreatmentPlan,
  handleToothToggle,
  setDialog,
} from "../../../../app/slices/treatmentPlanSlice";
import TeethChart from "../../../../components/teeth";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TreatmentPlanDetails = () => {
  const dispatch = useDispatch();
  const _HandleOperationType = useSelector(
    (state) => state.treatmentPlan.handleOperationTypes
  );
  const _TreatmentPlan = useSelector(
    (state) => state.treatmentPlan.treatmentPlan
  );
  const _Patient = useSelector((state) => state.patient.patients.data);

  const handleDialog = () => {
    dispatch(setDialog(null));
  };

  useEffect(() => {
    if (_TreatmentPlan.patient_id) {
      dispatch(TreatmentPlan.getAllOperationTypes()).then((x) => {
        dispatch(
          TreatmentPlan.getAll({
            patient_id: _TreatmentPlan.patient_id,
            skip: 0,
            take: 1,
          })
        );
      });
    }
  }, [_TreatmentPlan.patient_id]);

  return (
    <Dialog
      open={_TreatmentPlan.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="lg"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {"معلومات خطة العلاج"}
        </Typography>
        {_HandleOperationType ? (
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography>
                <strong>اسم المراجع</strong>: {_TreatmentPlan.form.patient_name}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography>
                <strong>التاريخ</strong>: {_TreatmentPlan.form.date}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography>
                <strong>الملاحظات</strong>: {_TreatmentPlan.form.note}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={3} md={3}>
              <FormControlLabel
                control={<Checkbox checked={_TreatmentPlan.form.is_need_opg} />}
                label="OPG"
              />
            </Grid>
            <Grid item xs={3} md={3}>
              <FormControlLabel
                control={<Checkbox checked={_TreatmentPlan.form.is_need_cbc} />}
                label="CBC"
              />
            </Grid>
            <Grid item xs={3} md={3}>
              <FormControlLabel
                control={
                  <Checkbox checked={_TreatmentPlan.form.is_need_bitewing} />
                }
                label="Bitewing"
              />
            </Grid>
            <Grid item xs={3} md={3}>
              <FormControlLabel
                control={<Checkbox checked={_TreatmentPlan.form.is_need_pa} />}
                label="P.A"
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            {_TreatmentPlan.form.treatment_plan_operation
              .filter((instance) => instance.teeth.length > 0)
              ?.map((instance, index) =>
                instance.teeth.length >= 32 ? (
                  <Grid item xs={4} md={4} key={index}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={instance.teeth.length === 32 ? true : false}
                        />
                      }
                      label={
                        <Stack direction={"column"} alignItems={"center"}>
                          <Typography>
                            {instance.operation_type_name}
                          </Typography>
                          <Typography color={"green"}>
                            {instance.price?.toLocaleString()} IQD
                          </Typography>
                        </Stack>
                      }
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12} key={index}>
                    <Table>
                      {/* <TableHead>
                        <TableRow>
                          <TableCell>اسم الحالة</TableCell>
                          <TableCell>الاسنان</TableCell>
                        </TableRow>
                      </TableHead> */}
                      <TableBody>
                        <TableRow>
                          <TableCell sx={{ width: { md: "15vh", xs: 90 } }}>
                            <Stack direction={"column"} alignItems={"center"}>
                              <Typography>
                                {instance.operation_type_name}
                              </Typography>
                              <Typography color={"green"}>
                                {instance.price?.toLocaleString()} IQD
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <TeethChart
                                key={instance.id}
                                _ToothIds={instance.teeth}
                                disabled={true}
                                onToothToggle={(toothId) => {
                                  dispatch(
                                    handleToothToggle({
                                      toothId: toothId,
                                      instanceId: instance.id,
                                      isAllTeeth: false,
                                    })
                                  );
                                }}
                              />
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                )
              )}
            <Stack direction={"column"} alignItems={"center"} padding={2}>
              <Typography>المجموع الكلي:</Typography>
              <Stack direction={"row"} spacing={0.75} alignItems={"center"}>
                <Typography color={"green"}>
                  {_TreatmentPlan.form.treatment_plan_operation
                    ?.reduce((accumulator, price) => {
                      return accumulator + price.price;
                    }, 0)
                    ?.toLocaleString()}
                </Typography>
                <Typography color={"green"}>IQD</Typography>
              </Stack>
            </Stack>
          </Grid>
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

export default TreatmentPlanDetails;
