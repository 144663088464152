import {
  Grid,
  LinearProgress,
  Card,
  CardContent,
  Stack,
  Typography,
  DialogContent,
  Dialog,
  Slide,
  Divider,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Xray, setSearchBody } from "../../../../app/slices/xraySlice";
import { useParams } from "react-router";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PatientXrayTable = () => {
  const { patient_id } = useParams();

  const _Xray = useSelector((state) => state.xray.xrayData);
  const _SearchBody = useSelector((state) => state.xray.searchBody);
  const loading = useSelector((state) => state.xray.loading);

  const [search, setSearch] = useState({
    xray_type_id: null,
    start_date: dayjs().format("YYYY-MM-DD"),
    end_date: dayjs().format("YYYY-MM-DD"),
  });
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const dispatch = useDispatch();
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [dialogData, setDialogData] = useState("");

  const handleCellClick = (params) => {
    if (params.field === "attachment") {
      if (params.row.attachment) {
        setDialogData(params.row.attachment);
        setOpenImageDialog(true);
      }
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "رقم الاشعة",
      flex: 0.7,
    },
    {
      field: "patient_name",
      headerName: "اسم المراجع",
      flex: 1,
    },
    {
      field: "xray_type_name",
      headerName: "نوع الاشعة",
      flex: 0.7,
    },
    {
      field: "user_name",
      headerName: "اسم الطبيب",
      flex: 1,
    },
    {
      field: "date",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="column">
          <Typography>{dayjs(params.row.date).format("YYYY-MM-DD")}</Typography>
          <Typography variant="caption">
            {dayjs(params.row.date).format("hh:mm: a")}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "attachment",
      headerName: "الصورة",
      width: 160,
      renderCell: (params) => (
        <Box component="img" src={params.row.attachment} sx={{ width: 150 }} />
      ),
    },
  ];
  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(Xray.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [dispatch, _SearchBody]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        patient_id: patient_id,
        xray_type_id: search.xray_type_id ?? "",
        start_date: dayjs().format("2023-01-01"),
        end_date: dayjs().format("YYYY-MM-DD"),
        skip: pageSize * currentPage,
        take: pageSize,
      })
    );
  }, [dispatch, search, pageSize, currentPage, patient_id]);

  return (
    <Card>
      <Dialog
        open={openImageDialog}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        dir="rtl"
        onClose={() => {
          setOpenImageDialog(false);
        }}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
      >
        <DialogContent>
          <Typography
            mb={3}
            variant="h4"
            color={"purple"}
            gutterBottom
            component="div"
          >
            صورة الاشعة
          </Typography>
          <Divider />
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box
              component="img"
              src={dialogData}
              sx={{ width: "100%", padding: 2 }}
            />
          </Box>
        </DialogContent>
      </Dialog>
      <CardContent>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12}>
            <Box sx={{ height: "65vh", width: "100%" }}>
              <DataGrid
                sx={{
                  ".MuiDataGrid-cell:focus": {
                    outline: "none",
                  },
                  "& .MuiDataGrid-cell[data-field='attachment']:hover": {
                    cursor: "pointer",
                  },
                }}
                rows={_Xray.data}
                columns={columns}
                pageSize={pageSize}
                page={currentPage}
                rowCount={_Xray.total}
                loading={loading}
                rowHeight={100}
                pagination
                paginationMode="server"
                selectionModel={[]}
                onPageChange={(newPage) => setCurrentPage(newPage)}
                rowsPerPageOptions={[15, 25, 50, 100]}
                onPageSizeChange={(newPageSize) => {
                  setPageSize(newPageSize);
                }}
                components={{
                  LoadingOverlay: LinearProgress,
                }}
                onCellClick={(params) => {
                  handleCellClick(params);
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PatientXrayTable;
