import { Card, CardHeader, Typography, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { setOperationDialog } from "../../../../app/slices/operationSlice";
import { GiOfficeChair } from "react-icons/gi";
import OperationForm from "../../form";

const OperationHeader = () => {
  const _Operation = useSelector((state) => state.operation.operation);
  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            الجلسات
          </Typography>
        }
        avatar={<GiOfficeChair size={20} />}
        // action={
        //   <Button
        //     variant="contained"
        //     color="primary"
        //     size="medium"
        //     startIcon={<IoIosAddCircleOutline />}
        //     onClick={() => dispatch(setOperationDialog())}
        //   >
        //     إضافة
        //   </Button>
        // }
      />
      {_Operation.dialog ? <OperationForm /> : null}
    </Card>
  );
};

export default OperationHeader;
