import { forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  InputAdornment,
  Box,
  Stack,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { setDialog, resetForm, Xray } from "../../../app/slices/xraySlice";
import { Controller, useForm } from "react-hook-form";
import { SelectPatient } from "../../../components/Selectors/SelectPatient";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { SelectXrayType } from "../../../components/Selectors/SelectXray";
import { SelectDoctor } from "../../../components/Selectors/SelectDoctor";
import { NumericFormat } from "react-number-format";
import FilePondInput from "../../../helpers/FilePondInput";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const XrayForm = () => {
  const _Xray = useSelector((state) => state.xray.xray);
  const _SearchBody = useSelector((state) => state.xray.searchBody);
  const _XrayTypeData = useSelector((state) => state.xray.xrayTypeData);
  const _Patient = useSelector((state) => state.patient.patients.data);
  const _User = useSelector((state) => state.user.doctorList);

  const isAdd = _Xray.form.id ? false : true;
  const { control, reset, watch, handleSubmit, setValue } = useForm({
    defaultValues: _Xray.form,
  });
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    const formData = new FormData();
    if (!isAdd) formData.append("_method", "PUT");
    formData.append("patient_id", data.patient_id);
    formData.append("user_id", data.user_id);
    formData.append("date", data.date);
    formData.append("price", data.price);
    formData.append("note", data.note ?? "");
    formData.append("xray_type_id", data.xray_type_id);
    if (data.attachment && data.attachment[0].name)
      formData.append("attachment", data.attachment[0]);
    if (isAdd) {
      dispatch(Xray.create(formData, _SearchBody));
    } else {
      dispatch(Xray.update(data.id, formData, _SearchBody));
    }
  };
  useEffect(() => {
    reset({ ..._Xray.form });
  }, [reset, _Xray.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, [dispatch]);

  const handleDialog = () => {
    dispatch(setDialog());
  };

  const NumericFormatCustom = forwardRef(function NumericFormatCustom(
    props,
    ref
  ) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
  });

  return (
    <Dialog
      open={_Xray.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : "تعديل "}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="xray_type_id"
                control={control}
                render={({ field }) => (
                  <SelectXrayType
                    {...field}
                    required
                    onChange={(e, newValue) => {
                      setValue("xray_type_id", newValue ? newValue.id : null);
                      setValue("price", newValue ? newValue.price : null);
                    }}
                    value={
                      _XrayTypeData?.filter((x) => x.id === field.value)[0] ??
                      null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="patient_id"
                control={control}
                render={({ field }) => (
                  <SelectPatient
                    {...field}
                    required
                    onChange={(e, newValue) => {
                      setValue("patient_id", newValue ? newValue.id : null);
                    }}
                    value={
                      _Patient?.filter((x) => x.id === field.value)[0] ?? null
                    }
                    search={watch("patient_name")}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="user_id"
                control={control}
                render={({ field }) => (
                  <SelectDoctor
                    {...field}
                    required
                    onChange={(e, newValue) => {
                      setValue("user_id", newValue ? newValue.id : null);
                    }}
                    value={
                      _User?.filter((x) => x.id === field.value)[0] ?? null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      inputFormat="YYYY-MM-DD"
                      label="التاريخ"
                      value={field.value}
                      onChange={(e, newValue) => {
                        setValue("date", dayjs(e).format("YYYY-MM-DD"));
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          fullWidth
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "يوم/شهر/سنة",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="price"
                control={control}
                render={({ field }) => (
                  <TextField
                    multiline
                    rows={3}
                    size="small"
                    label="السعر"
                    fullWidth
                    {...field}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      startAdornment: (
                        <InputAdornment position="start">IQD</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <TextField
                    multiline
                    rows={3}
                    size="small"
                    label="الملاحظة"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="attachment"
                control={control}
                render={({ field }) => (
                  <Box>
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ justifyContent: "center", paddingBottom: 2 }}
                    >
                      {!isAdd && (
                        <Box
                          component="img"
                          src={field.value}
                          sx={{ width: 300 }}
                        />
                      )}
                    </Stack>
                    <FilePondInput field={field} />
                  </Box>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default XrayForm;
