import dayjs from "dayjs";
import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
const patientApi = factory.get("patient");
const initialState = {
  loading: false,
  searchBody: {},
  patient: {
    dialog: false,
    form: {
      id: "",
      first_name: "",
      full_name: "",
      birth_date: dayjs().format("YYYY-MM-DD"),
      address: "",
      gender: null,
      note: "",
      city_id: null,
      city: "",
      province_id: null,
      province_name: "",
      how_know_us: "",
      mobile_1: "",
      mobile_2: "",
      patient_history: {}
    },
  },
  patients: {
    data: [],
    total: 0,
  },
  sectionsList: {
    data: [],
    total: 0,
  },
  knownUs: [],
};

export const patientSlice = createSlice({
  name: "patient",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.patient.dialog = !state.patient.dialog;
    },
    setDataTable: (state, { payload }) => {
      state.patients.data = payload.data.data;
      state.patients.total = payload.data.total;
    },
    setDatalist: (state, { payload }) => {
      state.sectionsList.data = payload.data.data;
      state.sectionsList.total = payload.data.total;
    },
    setKnownUsData: (state, { payload }) => {
      state.knownUs = payload.data.data;
    },
    setById: (state, { payload }) => {
      state.patient.form = payload
    },
    setLoading: (state, action) => {
      state.loading = !state.loading;
    },
    resetForm: (state, action) => {
      state.patient.form = initialState.patient.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
  },
});

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setDatalist,
  setKnownUsData,
} = patientSlice.actions;

export default patientSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("patient"));
    const res = await patientApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("patient"));
  } catch (err) {
    dispatch(setLoading("patient"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getAllList = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("patient"));
    const res = await patientApi.getAllList(params);
    dispatch(setDatalist(res));
    dispatch(setLoading("patient"));
  } catch (err) {
    dispatch(setLoading("patient"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await patientApi.delete(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("patient"));
    const res = await patientApi.getById(id);
    dispatch(setById(res.data.data[0]));

    dispatch(setDialog());
    dispatch(setLoading("patient"));
  } catch (err) {
    dispatch(setLoading("patient"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getByIdForData = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("patient"));
    const res = await patientApi.getById(id);
    dispatch(setById(res.data.data[0]));
    dispatch(setLoading("patient"));
  } catch (err) {
    dispatch(setLoading("patient"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create = (data) => async (dispatch) => {
  try {
    await patientApi.create(data);
    dispatch(
      showNotification({
        message: "تمت الاضافة  بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll());
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const update = (id, data, _SearchBody) => async (dispatch) => {
  try {
    await patientApi.update(id, data);
    dispatch(
      showNotification({
        message: "تم التعديل  بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const KnownUs = () => async (dispatch) => {
  try {
    const res = await patientApi.KnownUs();
    dispatch(setKnownUsData(res));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const Patient = {
  getAll,
  deleteById,
  getById,
  create,
  update,
  getAllList,
  KnownUs,
  getByIdForData,
};
