import {
  Grid,
  IconButton,
  LinearProgress,
  Card,
  CardContent,
  TextField,
  Tooltip,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { BiTrash } from "react-icons/bi";
import ConfirmationDialog from "../../../../../helpers/ConfirmationDialog";
import {
  Payment,
  setSearchBody,
} from "../../../../../app/slices/paymentsSlice";
import { SelectOperationType } from "../../../../../components/Selectors/SelectOperationType";
import DateRangepicker from "../../../../../helpers/dateRangePicker";
import { MdOutlineClear } from "react-icons/md";
import { useTheme } from "@emotion/react";

const PaymentsTable = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("lg"));

  const [search, setSearch] = useState({
    name_number: "",
    start_date: dayjs().format("YYYY-MM-01 00:00:00"),
    end_date: dayjs().format("YYYY-MM-DD 23:59:59"),
    operation_type_id: null,
  });
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");

  const _Payment = useSelector((state) => state.payment.payments);
  const loading = useSelector((state) => state.payment.loading);
  const _SearchBody = useSelector((state) => state.payment.searchBody);
  const _OperationPayment = useSelector(
    (state) => state.payment.operationPayment
  );
  const _OperationType = useSelector(
    (state) => state.operation.operations.data
  );

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(Payment.deleteById(dialogData.id, _SearchBody));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const columns = [
    {
      field: "id",
      headerName: "رقم الحركة",
      flex: 0.7,
    },
    {
      field: "operation_id",
      headerName: "رقم العملية",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "المبلغ (IQD)",
      flex: 0.8,
      renderCell: (params) => (
        <>{parseInt(params.row.amount)?.toLocaleString()}</>
      ),
    },
    {
      field: "patient_name",
      headerName: "معلومات المراجع",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="column" spacing={0.5}>
          <Typography variant="h5">{params.row.patient_name}</Typography>
          <Typography variant="caption">
            {params.row.patient_mobile_1}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "operation_operation_type_name",
      headerName: "اسم الجلسة",
      flex: 1,
    },
    {
      field: "created_by_name",
      headerName: "بواسطة",
      flex: 1,
    },
    {
      field: "description",
      headerName: "الوصف",
      flex: 1,
    },
    {
      field: "date",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) => dayjs(params.row.date).format("YYYY-MM-DD"),
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: matchUpMd ? 1 : 0.7,
      renderCell: (params) => {
        return (
          <IconButton
            color="error"
            onClick={() => handleDeleteClick(params.row)}
          >
            <BiTrash />
          </IconButton>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(Payment.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [dispatch, _SearchBody]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        name_number: search.name_number,
        start_date: search.start_date,
        end_date: search.end_date,
        operation_type_id: search.operation_type_id ?? "",
        skip: currentPage * pageSize,
        take: pageSize,
      })
    );
  }, [dispatch, search, currentPage, pageSize, _OperationPayment.operation_id]);

  return (
    <Card>
      <CardContent>
        <Box>
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف حركة الدفع المرقمة:"
            name={dialogData.id}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} md={3}>
            <TextField
              size="small"
              value={search.name_number}
              onChange={(e) => {
                setSearch({
                  ...search,
                  name_number: e.target.value,
                });
              }}
              label="اسم او رقم المراجع"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SelectOperationType
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  operation_type_id: newValue ? newValue.id : null,
                });
              }}
              value={
                _OperationType?.filter(
                  (x) => x.id === search.operation_type_id
                )[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DateRangepicker
              startDate={search.start_date}
              endDate={search.end_date}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.startDate ||
                  updatedFilter.to !== searchDate.endDate
                ) {
                  setSearch({
                    ...search,
                    start_date: dayjs(searchDate[0].startDate).format(
                      "YYYY-MM-DD 00:00:00"
                    ),
                    end_date: dayjs(searchDate[0].endDate).format(
                      "YYYY-MM-DD 23:59:59"
                    ),
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="الغاء الفلتر">
              <IconButton
                onClick={() => {
                  setSearch({
                    name_number: "",
                    start_date: dayjs().format("YYYY-MM-01 00:00:00"),
                    end_date: dayjs().format("YYYY-MM-DD 23:59:59"),
                    operation_type_id: null,
                  });
                  setPageSize(15);
                  setCurrentPage(0);
                }}
              >
                <MdOutlineClear />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              {_Payment.data ? (
                <DataGrid
                  rows={_Payment.data}
                  columns={columns}
                  pageSize={pageSize}
                  page={currentPage}
                  rowCount={_Payment.total}
                  loading={loading}
                  rowHeight={55}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) => {
                    setCurrentPage(newPage);
                  }}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize);
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PaymentsTable;
