import {
    createSlice
} from "@reduxjs/toolkit";
// import {
//     factory
// } from "../../api/apiFactory";
// import {
//     showNotification
// } from "./notificationSlice";
// const appApi = factory.get("app");

const initialState = {
    is_drawer_opened: true,
    positions: {
        loading: false,
        data: [],
    },
    countries: {
        loading: false,
        data: [],
    },
}

export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setDrawer: (state, action) => {
            state.is_drawer_opened = !state.is_drawer_opened
            localStorage.setItem('sideBarToggle', state.is_drawer_opened)
        },
        setCountriesAndPostion: (state, action) => {
            state[action.payload.key].data = action.payload.data
        },
    }
})

export const {
    setLoading,
    setDrawer,
    setCountriesAndPostion
} = appSlice.actions

export default appSlice.reducer;

export const getAllCountrys = () => async (dispatch) => {
    try {
        // dispatch(setLoading('countries'));
        // const res = await appApi.allCountrys()
        // dispatch(setCountriesAndPostion({
        //     key: 'countries',
        //     data: res.data.data
        // }));
        // dispatch(setLoading('countries'));
    } catch (err) {
        // dispatch(setLoading('countries'));
        throw new Error(err);
    }
};

