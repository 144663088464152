import { Grid } from "@mui/material";
import React from "react";
import WaitingListHeader from "./home/header";
import WaitingListData from "./home/table";

const WaitingList = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <WaitingListHeader />
      </Grid>
      <Grid item xs={12}>
        <WaitingListData />
      </Grid>
    </Grid>
  );
};

export default WaitingList;
