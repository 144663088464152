import { Card, CardHeader, Typography, Button, Stack } from "@mui/material";
import React from "react";
import { setDialog } from "../../../../../../app/slices/provincesSlice";
import { useDispatch } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import { MdLocationOn } from "react-icons/md";
import { useSelector } from "react-redux";
import CityForm from "../../form";

const CityHeader = () => {
  const _SelectedProvince = useSelector((state) => state.provinces.selectedProvince);
  const _City = useSelector((state) => state.provinces.cities);
  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader
        title={
          <Stack direction='row' spacing={1}>
            <Typography variant="h4" >
              مدن محافظة
            </Typography>
            <Typography variant="h4" color={'purple'}>
              {_SelectedProvince.name}
            </Typography>
          </Stack>
        }
        avatar={<MdLocationOn size={20} />}
        action={
          <Button
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<IoIosAddCircleOutline />}
            onClick={() => dispatch(setDialog())}
          >
            أضافة مدينة جديدة
          </Button>
        }
      />
      {_City.dialog ? <CityForm /> : null}
    </Card>
  );
};

export default CityHeader;
