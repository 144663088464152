import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getById(id) {
    return api.get(`/api/v1/dental-implement/${id}`);
  },
  update(id, data) {
    return api.put(`/api/v1/dental-implement/${id}`, data);
  },
  create(data) {
    return api.post(`/api/v1/dental-implement`, data);
  },
  delete(id) {
    return api.delete(`/api/v1/dental-implement/${id}`);
  },
};
