import { Card, CardHeader, Typography, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { setDialog } from "../../../../app/slices/offersSlice";
import { FaImage } from "react-icons/fa";
import OfferForm from "../../form";

const OfferHeader = () => {
  const dispatch = useDispatch();
  const _Offer = useSelector((state) => state.offer.offer.dialog);

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            العروض
          </Typography>
        }
        avatar={<FaImage size={20} />}
        action={
          <Button
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<IoIosAddCircleOutline />}
            onClick={() => dispatch(setDialog())}
          >
            إضافة
          </Button>
        }
      />
      {_Offer && <OfferForm />}
    </Card>
  );
};

export default OfferHeader;
