import { Grid } from "@mui/material";
import React from "react";
import XrayHeader from "./home/header";
import XrayTable from "./home/table";

const Xray = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <XrayHeader />
      </Grid>
      <Grid item xs={12}>
        <XrayTable />
      </Grid>
    </Grid>
  );
};

export default Xray;
