import { forwardRef, useEffect, useState } from "react";
import {
  Card,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { setTimesDialog } from "../../../../app/slices/waitingListSlice";
import { FaPeopleGroup, FaUserDoctor, FaXRay } from "react-icons/fa6";
import { GiEntryDoor } from "react-icons/gi";
import dayjs from "dayjs";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TimeDialog = (props) => {
  const dispatch = useDispatch();

  const _WaitingList = useSelector((state) => state.waitingList.timeData);

  const [waitingTreatment, setWaitingTreatment] = useState("");
  const [inTreatment, setInTreatment] = useState("");
  const [waitingXray, setWaitingXray] = useState("");
  const [inXray, setInXray] = useState("");
  const [leaving, setLeaving] = useState("");

  const handleDialog = () => {
    dispatch(setTimesDialog());
  };

  useEffect(() => {
    const calculateTimeDifference = () => {
      const timeDifferenceWaitingTreatment = _WaitingList.data
        ?.waiting_treatment
        ? dayjs(_WaitingList.data?.waiting_treatment).toNow()
        : null;
      const timeDifferenceTnTreatment = _WaitingList.data?.in_treatment
        ? dayjs(_WaitingList.data?.in_treatment).toNow()
        : null;
      const timeDifferenceWaitingXray = _WaitingList.data?.waiting_xray
        ? dayjs(_WaitingList.data?.waiting_xray).toNow()
        : null;
      const timeDifferenceInXray = _WaitingList.data?.in_xray
        ? dayjs(_WaitingList.data?.in_xray).toNow()
        : null;
      const timeDifferenceLeaving = _WaitingList.data?.leaving
        ? dayjs(_WaitingList.data?.leaving).toNow()
        : null;
      setWaitingTreatment(timeDifferenceWaitingTreatment);
      setInTreatment(timeDifferenceTnTreatment);
      setWaitingXray(timeDifferenceWaitingXray);
      setInXray(timeDifferenceInXray);
      setLeaving(timeDifferenceLeaving);
    };

    calculateTimeDifference();

    const interval = setInterval(calculateTimeDifference, 10 * 1000); // 10 seconds

    return () => clearInterval(interval);
  }, [_WaitingList]);

  return (
    <Dialog
      open={_WaitingList.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          الاوقات
        </Typography>
        <Divider />
        <Grid
          container
          spacing={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop={2}
        >
          <Grid item xs={6} md={5}>
            <Card sx={{ padding: 2, backgroundColor: "#bdbdbd" }}>
              <Stack spacing={2} justifyContent="center" alignItems="center">
                <FaPeopleGroup size={30} />
                <Typography variant="h4">انتظار الفحص</Typography>
                <Typography>
                  {_WaitingList.data.waiting_treatment
                    ? dayjs(_WaitingList.data.waiting_treatment).format(
                        "YYYY-MM-DD / hh:mm a"
                      )
                    : "لا يوجد"}
                </Typography>
                <Typography>{waitingTreatment ?? "--"}</Typography>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={6} md={5}>
            <Card sx={{ padding: 2, backgroundColor: "#81c784" }}>
              <Stack
                spacing={2}
                justifyContent="center"
                alignItems="center"
                height={"100%"}
              >
                <FaUserDoctor size={30} />
                <Typography variant="h4">داخل غرفة الفحص</Typography>
                <Typography>
                  {_WaitingList.data.in_treatment
                    ? dayjs(_WaitingList.data.in_treatment).format(
                        "YYYY-MM-DD / hh:mm a"
                      )
                    : "لا يوجد"}
                </Typography>
                <Typography>{inTreatment ?? "--"}</Typography>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={6} md={5}>
            <Card sx={{ padding: 2, backgroundColor: "#bdbdbd" }}>
              <Stack spacing={2} justifyContent="center" alignItems="center">
                <FaPeopleGroup size={30} />
                <Typography variant="h4">انتظار الاشعة</Typography>
                <Typography>
                  {_WaitingList.data.waiting_xray
                    ? dayjs(_WaitingList.data.waiting_xray).format(
                        "YYYY-MM-DD / hh:mm a"
                      )
                    : "لا يوجد"}
                </Typography>
                <Typography>{waitingXray ?? "--"}</Typography>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={6} md={5}>
            <Card sx={{ padding: 2, backgroundColor: "#81c784" }}>
              <Stack spacing={2} justifyContent="center" alignItems="center">
                <FaXRay size={30} />
                <Typography variant="h4">داخل غرفة الاشعة</Typography>
                <Typography>
                  {_WaitingList.data.in_xray
                    ? dayjs(_WaitingList.data.in_xray).format(
                        "YYYY-MM-DD / hh:mm a"
                      )
                    : "لا يوجد"}
                </Typography>
                <Typography>{inXray ?? "--"}</Typography>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={6} md={5} height={"100%"}>
            <Card sx={{ padding: 2, backgroundColor: "#c2eafc" }}>
              <Stack spacing={2} justifyContent="center" alignItems="center">
                <GiEntryDoor size={30} />
                <Typography variant="h4">المغادرة</Typography>
                <Typography>
                  {_WaitingList.data.leaving
                    ? dayjs(_WaitingList.data.leaving).format(
                        "YYYY-MM-DD / hh:mm a"
                      )
                    : "لا يوجد"}
                </Typography>
                <Typography>{leaving ?? "--"}</Typography>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default TimeDialog;
