import { Grid } from "@mui/material";
import React from "react";
import OperationTypeHeader from "./home/header";
import OperationTypeTable from "./home/table";

const OperationType = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <OperationTypeHeader />
      </Grid>
      <Grid item xs={12}>
        <OperationTypeTable />
      </Grid>
    </Grid>
  );
};

export default OperationType;
