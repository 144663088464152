import { useState } from "react";
import { Box, useMediaQuery, Drawer, Stack, Skeleton } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { BrowserView, MobileView } from "react-device-detect";
import PerfectScrollbar from "react-perfect-scrollbar";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { setDrawer } from "../../../app/slices/appSlice";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AiFillDollarCircle,
  AiOutlineHome,
  AiOutlineUsergroupAdd,
} from "react-icons/ai";
import {
  MdAirlineSeatFlatAngled,
  MdChair,
  MdLocationOn,
  MdOutlineMeetingRoom,
} from "react-icons/md";
import { FaCashRegister, FaImage, FaWarehouse, FaXRay } from "react-icons/fa";
import { BsGear } from "react-icons/bs";
import Logo from "../../../assets/Logo";
import { CiUser } from "react-icons/ci";
import { TbReport } from "react-icons/tb";
import { GiExpense, GiOfficeChair, GiPayMoney } from "react-icons/gi";
import { BsListCheck } from "react-icons/bs";

const SideBar = () => {
  const _User = useSelector((state) => state.user.userInfo);
  const _Role = _User.role;
  const userInfoLoading = useSelector((state) => state.user.userInfoLoading);
  //uses
  const theme = useTheme();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //constants
  const drawerWidth = 220;

  //states
  const [open, setOpen] = useState(false);
  const [selectedID, setSelectedID] = useState(null);

  //selectors and media queries
  const drawerOpen = useSelector((state) => state.app.is_drawer_opened);
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));

  //functions
  const links = [
    {
      id: 1,
      name: "الرئيسية",
      icon: <AiOutlineHome size={18} />,
      path: "/app",
      sub: [],
      roles: ["super_admin"],
    },
    {
      id: 2,
      name: " المستخدمين",
      icon: <CiUser size={18} />,
      path: "/app/users",
      sub: [],
      roles: ["super_admin"],
    },
    {
      id: 3,
      name: "المراجعين",
      icon: <AiOutlineUsergroupAdd size={18} />,
      path: "/app/patients",
      sub: [],
      roles: [
        "super_admin",
        "secretary",
        _User.tenant?.id == 2 ? "" : "dentist",
      ],
    },
    {
      id: 3,
      name: "المواعيد والانتظار",
      icon: <BsListCheck size={18} />,
      path: "/app/waiting_list",
      sub: [],
      roles: ["super_admin", "secretary", "dentist"],
    },
    {
      id: 5,
      name: "انواع الخدمات",
      icon: <MdAirlineSeatFlatAngled size={18} />,
      path: "/app/case_type",
      sub: [],
      roles: ["super_admin", "secretary"],
    },
    // {
    //   id: 2,
    //   name: "خطة العلاج",
    //   icon: <FaTeethOpen size={18} />,
    //   path: "/app/medical_profile",
    //   sub: [],
    //   roles: ["super_admin"],
    // },
    // {
    //   id: 4,
    //   name: "الاقسام",
    //   icon: <AiOutlineApartment size={18} />,
    //   path: "/app/sections",
    //   sub: [],
    //   roles: ["super_admin"],
    // },
    {
      id: 6,
      name: "الجلسات",
      icon: <GiOfficeChair size={18} />,
      path: "/app/operation",
      sub: [],
      roles: ["super_admin", "dentist", "secretary"],
    },
    // {
    //   id: 7,
    //   name: "المواعيد",
    //   icon: <MdOutlineMeetingRoom size={18} />,
    //   path: "/app/all_appointments",
    //   sub: [],
    //   roles: ["super_admin", "dentist", "secretary"],
    // },
    {
      id: 7,
      name: "الاشعة",
      icon: <FaXRay size={18} />,
      path: "/app/xray",
      sub: [],
      roles: ["super_admin", "secretary"],
    },
    {
      id: 11,
      name: "العروض",
      icon: <FaImage size={18} />,
      path: "/app/offers",
      sub: [],
      roles: ["super_admin", "secretary"],
    },
    {
      id: 9,
      name: "المخزن",
      icon: <FaWarehouse size={18} />,
      path: "/app/stock",
      roles: ["super_admin", "secretary"],
      sub: [],
    },
    {
      id: 9,
      name: "اوقات الاستراحة",
      icon: <MdChair size={18} />,
      path: "/app/rest_time",
      roles: ["super_admin"],
      sub: [],
    },
    {
      id: 11,
      name: "التقارير",
      icon: <TbReport size={18} />,
      path: "/app/reports",
      sub: [],
      roles: ["super_admin", "secretary"],
    },
    {
      id: 12,
      name: "الحسابات",
      icon: <BsGear size={18} />,
      path: "/app/finance",
      roles: ["super_admin", "secretary"],
      sub: [
        {
          name: "المدفوعات",
          icon: <AiFillDollarCircle />,
          path: "/app/finance/payments",
          roles: ["super_admin", "secretary"],
        },
        {
          name: "مصاريف الاطباء",
          icon: <GiPayMoney />,
          path: "/app/finance/doctors_expenses",
          roles: ["super_admin", "secretary"],
        },
        {
          name: "حسابات الاطباء",
          icon: <FaCashRegister />,
          path: "/app/finance/clearance",
          roles: ["super_admin"],
        },
        {
          name: "المصاريف",
          icon: <GiExpense size={18} />,
          path: "/app/finance/expense",
          roles: ["super_admin", "secretary"],
        },
      ],
    },
    {
      id: 12,
      name: "الادراة",
      icon: <BsGear size={18} />,
      path: "/app/management",
      roles: ["super_admin", "secretary"],
      sub: [
        {
          name: "المحافظات والمدن",
          icon: <MdLocationOn />,
          path: "/app/management/provinces",
          roles: ["super_admin", "secretary"],
        },
      ],
    },
  ];
  const drawerSubLinks = (link, index) => {
    return link.sub.length > 0 ? (
      <Collapse in={open && index === selectedID} timeout="auto" unmountOnExit>
        <List dense={true} component="div" disablePadding>
          {link.sub.map(
            (item, index) =>
              item.roles.indexOf(_Role) > -1 && (
                <ListItemButton
                  key={index}
                  sx={{
                    pl: 4,
                    marginTop: 0.5,
                    backgroundColor:
                      item.path === pathname
                        ? theme.palette.primary.light
                        : "transparent",
                    color:
                      item.path === pathname
                        ? theme.palette.primary.main
                        : null,
                    borderRadius: "8px",
                  }}
                  onClick={() => {
                    navigate(item.path);
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "30px",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.name} />
                </ListItemButton>
              )
          )}
        </List>
      </Collapse>
    ) : null;
  };
  const drawerLinks = (
    <div>
      {/* <Toolbar /> */}
      <Box display="flex" justifyContent="center" margin={1}>
        {/* <img src={logo_light} width={50} height={50} /> */}
      </Box>
      {/* <Divider /> */}
      <List
        dense={false}
        sx={{
          "& .MuiListItem-root:hover": {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.main,
            borderRadius: "8px",
          },
          "& .Mui-selected": {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.main,
            borderRadius: "8px",
            fontWeight: "bold",
          },
        }}
      >
        {links.map(
          (item, index) =>
            item.roles.indexOf(_Role) > -1 && (
              <div key={index}>
                <ListItem key={index} disablePadding>
                  <ListItemButton
                    key={index}
                    // selected={index === selectedID}
                    selected={item.path === "/app" + pathname.split("/app")[1]}
                    onClick={() => {
                      if (item.sub.length === 0) navigate(item.path);
                      if (index === selectedID) {
                        setOpen(!open);
                      } else {
                        setOpen(true);
                        setSelectedID(index);
                      }
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        // color: "white",
                        minWidth: "30px",
                        // color: item.path === location.pathname ? "primary.main" : "",
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        fontSize: "0.8rem",
                      }}
                      disableTypography={true}
                      primary={item.name}
                    />
                    {item.sub.length > 0 ? (
                      open && index === selectedID ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )
                    ) : (
                      ""
                    )}
                  </ListItemButton>
                </ListItem>
                {drawerSubLinks(item, index)}
              </div>
            )
        )}
      </List>
    </div>
  );
  const drawerViewAndScroll = (
    <>
      {/* sx={{ display: { xs: "block", md: "none" } }} */}
      <Box>
        <Box
          sx={{
            display: "flex",
            mx: "auto",
            justifyContent: "center",
            height: "80px",
          }}
        >
          <Logo style={{ height: 130 }} />
        </Box>
      </Box>
      <Box>
        {userInfoLoading ? (
          <Stack direction="column" spacing={1} sx={{ marginTop: 3 }}>
            <Stack direction="row" spacing={1} justifyContent="start">
              <Skeleton
                variant="circular"
                animation="wave"
                sx={{ marginBottom: 1 }}
                height={35}
                width={35}
              />
              <Skeleton
                variant="rounded"
                animation="wave"
                sx={{ marginBottom: 1 }}
                height={35}
                width={160}
              />
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="start">
              <Skeleton
                variant="circular"
                animation="wave"
                sx={{ marginBottom: 1 }}
                height={35}
                width={35}
              />
              <Skeleton
                variant="rounded"
                animation="wave"
                sx={{ marginBottom: 1 }}
                height={35}
                width={160}
              />
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="start">
              <Skeleton
                variant="circular"
                animation="wave"
                sx={{ marginBottom: 1 }}
                height={35}
                width={35}
              />
              <Skeleton
                variant="rounded"
                animation="wave"
                sx={{ marginBottom: 1 }}
                height={35}
                width={160}
              />
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="start">
              <Skeleton
                variant="circular"
                animation="wave"
                sx={{ marginBottom: 1 }}
                height={35}
                width={35}
              />
              <Skeleton
                variant="rounded"
                animation="wave"
                sx={{ marginBottom: 1 }}
                height={35}
                width={160}
              />
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="start">
              <Skeleton
                variant="circular"
                animation="wave"
                sx={{ marginBottom: 1 }}
                height={35}
                width={35}
              />
              <Skeleton
                variant="rounded"
                animation="wave"
                sx={{ marginBottom: 1 }}
                height={35}
                width={160}
              />
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="start">
              <Skeleton
                variant="circular"
                animation="wave"
                sx={{ marginBottom: 1 }}
                height={35}
                width={35}
              />
              <Skeleton
                variant="rounded"
                animation="wave"
                sx={{ marginBottom: 1 }}
                height={35}
                width={160}
              />
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="start">
              <Skeleton
                variant="circular"
                animation="wave"
                sx={{ marginBottom: 1 }}
                height={35}
                width={35}
              />
              <Skeleton
                variant="rounded"
                animation="wave"
                sx={{ marginBottom: 1 }}
                height={35}
                width={160}
              />
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="start">
              <Skeleton
                variant="circular"
                animation="wave"
                sx={{ marginBottom: 1 }}
                height={35}
                width={35}
              />
              <Skeleton
                variant="rounded"
                animation="wave"
                sx={{ marginBottom: 1 }}
                height={35}
                width={160}
              />
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="start">
              <Skeleton
                variant="circular"
                animation="wave"
                sx={{ marginBottom: 1 }}
                height={35}
                width={35}
              />
              <Skeleton
                variant="rounded"
                animation="wave"
                sx={{ marginBottom: 1 }}
                height={35}
                width={160}
              />
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="start">
              <Skeleton
                variant="circular"
                animation="wave"
                sx={{ marginBottom: 1 }}
                height={35}
                width={35}
              />
              <Skeleton
                variant="rounded"
                animation="wave"
                sx={{ marginBottom: 1 }}
                height={35}
                width={160}
              />
            </Stack>
          </Stack>
        ) : (
          <Box>
            <BrowserView>
              <PerfectScrollbar
                component="div"
                style={{
                  height: !matchUpMd
                    ? "calc(100vh - 56px)"
                    : "calc(100vh - 88px)",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                {drawerLinks}

                {/* <MenuList />
                          <MenuCard /> */}
              </PerfectScrollbar>
            </BrowserView>
            <MobileView>
              <Box sx={{ px: 2 }}>{drawerLinks}</Box>
            </MobileView>
          </Box>
        )}
      </Box>
    </>
  );

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : "auto" }}
      aria-label="sidebar"
    >
      <Drawer
        // container={container}
        dir="rtl"
        variant={matchUpMd ? "persistent" : "temporary"}
        anchor="left"
        open={JSON.parse(localStorage.getItem("sideBarToggle"))}
        onClose={() => dispatch(setDrawer())}
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            // background: theme.palette.primary.main,
            // background: "gray",
            // color: "white",
            borderRight: "none",

            // [theme.breakpoints.up("md")]: {
            //   top: "88px",
            // },
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawerViewAndScroll}
      </Drawer>
    </Box>
  );
};

export default SideBar;
