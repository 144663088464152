import React, { forwardRef } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import {
  DentalImplant,
  resetForm,
  setById,
  setDialog,
} from "../../../../../app/slices/dentalImplantSlice";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DentalImplantForm({ data = {}, print }) {
  const _DentalImplant = useSelector(
    (state) => state.dentalImplant.dentalImplant
  );
  const _OperationId = useSelector((state) => state.dentalImplant.operationId);

  const isAdd = _DentalImplant.form?.id ? false : true;
  const { control, handleSubmit, setValue, reset } = useForm({
    defaultValues: _DentalImplant.form,
  });
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    data.operation_id = _OperationId;
    dispatch(DentalImplant.create(data));
  };

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, [dispatch]);

  useEffect(() => {
    reset({ ..._DentalImplant.form });
  }, [reset, _DentalImplant.form]);

  const handleDialog = () => {
    dispatch(setDialog());
  };

  const handleChangeTreatmentProtocol = (event) => {
    setValue("treatment_protocol", event.target.value);
  };

  const handleChangeLoadingProtocol = (event) => {
    setValue("loading_protocol", event.target.value);
  };

  const handleChangeBoneDensity = (event) => {
    setValue("bone_density", event.target.value);
  };

  const handleChangeDiCoveredBy = (event) => {
    setValue("di_covered_by", event.target.value);
  };

  const handleChangeMembrane = (event) => {
    setValue("membrane", event.target.value);
  };

  return (
    <Dialog
      open={_DentalImplant.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      // dir="ltr"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent className="inputLTR">
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "Add" : "Update"}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="di_system"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="DI System"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="di_no"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="No. of DI"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="shade"
                control={control}
                render={({ field }) => (
                  <TextField size="small" label="Shade" fullWidth {...field} />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="treatment_protocol"
                control={control}
                render={({ field }) => (
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      Treatment Protocol
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      value={field.value}
                      onChange={handleChangeTreatmentProtocol}
                    >
                      <FormControlLabel
                        value="type1"
                        control={<Radio />}
                        label="Type 1"
                      />
                      <FormControlLabel
                        value="type2"
                        control={<Radio />}
                        label="Type 2"
                      />
                      <FormControlLabel
                        value="type3"
                        control={<Radio />}
                        label="Type 3"
                      />
                      <FormControlLabel
                        value="type4"
                        control={<Radio />}
                        label="Type 4"
                      />
                      
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="loading_protocol"
                control={control}
                render={({ field }) => (
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      Loading Protocol
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      value={field.value}
                      onChange={handleChangeLoadingProtocol}
                    >
                      <FormControlLabel
                        value="Immediate functional"
                        control={<Radio />}
                        label="Immediate functional"
                      />
                      <FormControlLabel
                        value="Conversational"
                        control={<Radio />}
                        label="Conversational"
                      />
                      <FormControlLabel
                        value="Delayed"
                        control={<Radio />}
                        label="Delayed"
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="bone_density"
                control={control}
                render={({ field }) => (
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      Bone Density
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      value={field.value}
                      onChange={handleChangeBoneDensity}
                    >
                     <FormControlLabel
                        value="d1"
                        control={<Radio />}
                        label="D1"
                      />
                      <FormControlLabel
                        value="d2"
                        control={<Radio />}
                        label="D2"
                      />
                      <FormControlLabel
                        value="d3"
                        control={<Radio />}
                        label="D3"
                      />
                      <FormControlLabel
                        value="d4"
                        control={<Radio />}
                        label="D4"
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="di_covered_by"
                control={control}
                render={({ field }) => (
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      DI Cover By
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      value={field.value}
                      onChange={handleChangeDiCoveredBy}
                    >
                      <FormControlLabel
                        value="coverScrew"
                        control={<Radio />}
                        label="Cover Screw"
                      />
                      <FormControlLabel
                        value="hab"
                        control={<Radio />}
                        label="HAB"
                      />
                      <FormControlLabel
                        value="abt"
                        control={<Radio />}
                        label="ABT"
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="membrane"
                control={control}
                render={({ field }) => (
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      Membrane
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      value={field.value}
                      onChange={handleChangeMembrane}
                    >
                      <FormControlLabel
                        value="boneGraft"
                        control={<Radio />}
                        label="Bone Graft"
                      />
                      <FormControlLabel
                        value="sinusLift"
                        control={<Radio />}
                        label="Sinus Lift"
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <TextField
                    multiline
                    rows={3}
                    size="small"
                    label="Surgical Notes"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="follow_up"
                control={control}
                render={({ field }) => (
                  <TextField
                    multiline
                    rows={4}
                    size="small"
                    label="Follow Up"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="stage2"
                control={control}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      inputFormat="YYYY-MM-DD"
                      label="Second Stage"
                      value={field.value ?? null}
                      onChange={(e) => {
                        setValue(
                          "stage2",
                          e ? dayjs(e).format("YYYY-MM-D") : null
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          fullWidth
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "يوم/شهر/سنة",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="stage3"
                control={control}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      inputFormat="YYYY-MM-DD"
                      label="Third Stage"
                      value={field.value ?? null}
                      onChange={(e) => {
                        setValue(
                          "stage3",
                          e ? dayjs(e).format("YYYY-MM-DD") : null
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          fullWidth
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "يوم/شهر/سنة",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="stage4"
                control={control}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      inputFormat="YYYY-MM-DD"
                      label="Fourth Stage"
                      value={field.value ?? null}
                      onChange={(e) => {
                        setValue(
                          "stage4",
                          e ? dayjs(e).format("YYYY-MM-DD") : null
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          fullWidth
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "يوم/شهر/سنة",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ marginLeft: 5 }}
              >
                Save
              </Button>
              <Button
                onClick={() => {
                  // reset();
                  dispatch(resetForm());
                  dispatch(
                    setById({
                      id: null,
                      operation_id: null,
                      di_system: "",
                      di_no: "",
                      treatment_protocol: "",
                      bone_density: "",
                      di_covered_by: "",
                      membrane: "",
                      note: "",
                      follow_up: "",
                      stage2: null,
                      stage3: null,
                      stage4: null,
                    })
                  );
                }}
                variant="contained"
                color="secondary"
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}
