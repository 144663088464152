import { Dialog, DialogContent, Grid, Slide } from "@mui/material";
import React, { forwardRef } from "react";
import ExpenseHeader from "./home/header";
import ExpenseTabel from "./home/table";
import { useDispatch, useSelector } from "react-redux";
import { setOpenDialog } from "../../../../app/slices/expenseTypesSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ExpenseTypes = () => {
  const _ExpenseTypes = useSelector((state) => state.expenseTypes);

  const dispatch = useDispatch();

  const handlDialog = () => {
    dispatch(setOpenDialog());
  };

  return (
    <Dialog
      open={_ExpenseTypes.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handlDialog();
      }}
      maxWidth='md'
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ExpenseHeader />
          </Grid>
          <Grid item xs={12}>
            <ExpenseTabel />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ExpenseTypes;
