import { Button, Card, CardHeader, Typography } from "@mui/material";
import React from "react";
import { FaTeethOpen } from "react-icons/fa";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router";

const TreatmentPlanHeader = () => {
  const navigate = useNavigate();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            خطة العلاج
          </Typography>
        }
        avatar={<FaTeethOpen size={20} />}
        // action={
        //   <Button
        //     variant="contained"
        //     color="primary"
        //     size="medium"
        //     startIcon={<IoIosAddCircleOutline />}
        //     onClick={() => navigate("/app/treatment_plan/add")}
        //   >
        //     إضافة خطة علاج جديدة
        //   </Button>
        // }
      />
    </Card>
  );
};

export default TreatmentPlanHeader;
