import {
  Grid,
  IconButton,
  LinearProgress,
  Card,
  CardContent,
  Tooltip,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiEdit } from "react-icons/bi";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setPatientId,
  setSearchBody,
} from "../../../../app/slices/operationSlice";
import dayjs from "dayjs";
import { MdOutlineClear } from "react-icons/md";
import { AiFillEye } from "react-icons/ai";
import DateRangepicker from "../../../../helpers/dateRangePicker";
import {
  TreatmentPlan,
  setDialog,
} from "../../../../app/slices/treatmentPlanSlice";
import { useNavigate } from "react-router";
import TreatmentPlanDetails from "../detailsDialog";
import { Tooth } from "../../../../app/slices/toothSlice";

const TreatmentPlanTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const _TreatmentPlans = useSelector(
    (state) => state.treatmentPlan.treatmentPlans
  );
  const _TreatmentPlan = useSelector(
    (state) => state.treatmentPlan.treatmentPlan.dialog
  );
  const _SearchBody = useSelector((state) => state.treatmentPlan.searchBody);
  const loading = useSelector((state) => state.treatmentPlan.loading);

  const [search, setSearch] = useState({
    search: "",
    start_date: dayjs().format("YYYY-MM-01 00:00:00"),
    end_date: dayjs().format("YYYY-MM-DD 23:59:59"),
    operation_type_id: null,
  });
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(TreatmentPlan.getAllData(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [dispatch, _SearchBody]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        skip: pageSize * currentPage,
        take: pageSize,
      })
    );
  }, [dispatch, search, pageSize, currentPage]);

  useEffect(() => {
    dispatch(Tooth.getAll());
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "رقم خطة العلاج",
      flex: 0.5,
    },
    {
      field: "patient_name",
      headerName: "اسم المراجع",
      flex: 1,
    },
    {
      field: "date",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) =>
        dayjs(params.row.date).format("YYYY-MM-DD / hh:mm a"),
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              color="warning"
              onClick={() => dispatch(setDialog(params.row.patient_id))}
            >
              <AiFillEye />
            </IconButton>
            <IconButton
              color="primary"
              onClick={() => {
                navigate("/app/medical_profile/" + params.row.patient_id);
                dispatch(setPatientId(params.row.patient_id));
              }}
            >
              <BiEdit />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <Card>
      <CardContent>
        {_TreatmentPlan && <TreatmentPlanDetails />}
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={2}>
            <TextField
              size="small"
              value={search.search}
              onChange={(e) => {
                setSearch({
                  ...search,
                  search: e.target.value,
                });
              }}
              label="اسم او رقم المراجع"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <DateRangepicker
              startDate={search.start_date}
              endDate={search.end_date}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.startDate ||
                  updatedFilter.to !== searchDate.endDate
                ) {
                  setSearch({
                    ...search,
                    start_date: dayjs(searchDate[0].startDate).format(
                      "YYYY-MM-DD 00:00:00"
                    ),
                    end_date: dayjs(searchDate[0].endDate).format(
                      "YYYY-MM-DD 23:59:59"
                    ),
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="الغاء الفلتر">
              <IconButton
                onClick={() => {
                  setSearch({
                    search: "",
                    start_date: dayjs().format("YYYY-MM-01 00:00:00"),
                    end_date: dayjs().format("YYYY-MM-DD 23:59:59"),
                    // patient_id: null,
                    operation_type_id: null,
                  });
                  setPageSize(15);
                  setCurrentPage(0);
                }}
              >
                <MdOutlineClear />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              {_TreatmentPlans.data ? (
                <DataGrid
                  rows={_TreatmentPlans.data}
                  columns={columns}
                  pageSize={pageSize}
                  page={currentPage}
                  rowCount={_TreatmentPlans.total}
                  loading={loading}
                  rowHeight={55}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) => setCurrentPage(newPage)}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize);
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TreatmentPlanTable;
